import { makeStyles } from '@material-ui/styles';

const AVATAR_SIZE = 46;

const useStyles = makeStyles(theme => ({
  feedbackItem: {
    backgroundColor: theme.palette.primary.c100,
    padding: theme.spacing(5, 7),
    display: 'flex',
    flexDirection: 'column',
  },
  resolved: {
    backgroundColor: 'white',
  },
  feedbackTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
  },
  avatarContainer: {
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    marginRight: theme.spacing(4),
    cursor: 'pointer',
  },
  pointerCapture: {
    height: AVATAR_SIZE,
    width: AVATAR_SIZE,
    position: 'absolute',
  },
  checkboxContainer: {
    alignSelf: 'flex-end',
  },
  checkbox: {
    '&.checked': {
      color: theme.palette.success.main,
    },
  },
  tooltipOverlay: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'absolute',
    pointerEvents: 'none',
    width: `calc(100% - ${2 * theme.spacing(7)}px)`,
    backgroundColor: 'white',
    padding: theme.spacing(4, 0, 4, 6),
    boxShadow: theme.customShadows.strongBottom,
    opacity: 0,
    transform: 'translateY(-20px)',
    transition: 'opacity 0.2s ease-in-out, transform 0.2s ease-in-out',
  },
  show: {
    opacity: 1,
    transform: 'translateY(0)',
    pointerEvents: 'auto',
  },
  closeIcon: {
    fontSize: '2.5em',
    color: theme.palette.grey.c300,
  },
}));

export default useStyles;
