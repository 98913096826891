import React from 'react';
import { Grid } from '@material-ui/core';
import strings from '../../../i18n/localization';
import Avatar, { avatarCount } from '../../../components/Avatar/Avatar';
import useStyles from './AvatarDialog.styles';
import FullScreenDialog from '../../../components/FullScreenDialog/FullScreenDialog';

const AvatarDialog = ({ open = true, onAvatarSelected, onCloseButton }) => {
  const classes = useStyles();

  const onAvatarClick = avatar => {
    if (onAvatarSelected) {
      onAvatarSelected(avatar);
    }
  };

  return (
    <FullScreenDialog open={open} title={strings.userProfile.chooseAvatar} onCloseButton={onCloseButton}>
      <Grid container spacing={1} className={classes.container}>
        {[...Array(avatarCount).keys()].map((key, i) => (
          <Grid component="button" item md={2} sm={3} xs={4} key={key} className={classes.button}>
            <Avatar
              name={`Avatar${i + 1}`}
              onClick={() => onAvatarClick(`Avatar${i + 1}`)}
              data-cy={`select-Avatar${i + 1}`}
            />
          </Grid>
        ))}
      </Grid>
    </FullScreenDialog>
  );
};

export default AvatarDialog;
