import React from 'react';
import DemoInfoBox from '../../components/DemoInfoBox/DemoInfoBox';
import PageTitle from '../../components/PageTitle/PageTitle';
import { useCategoryLabels } from '../../context/selectors/categories';
import strings from '../../i18n/localization';
import routes from '../../routes';

import useStyles from './ChooseCategory.styles';
import CategoryTile from '../../components/Categories/CategoryTile';
import CategoryIcon from './Icons/CategoryIcons';
import { AVAILABLE_CATEGORIES, IS_DEMO, RANDOM_CATEGORY, PLAY_SIX_IN_ROW } from '../../constants';

const ChooseCategory = () => {
  const classes = useStyles();
  const categoryLabels = useCategoryLabels();

  return (
    <>
      {IS_DEMO ? <DemoInfoBox /> : null}
      <PageTitle title={strings.category.whereToImprove} />
      <div className={classes.grid}>
        {AVAILABLE_CATEGORIES.map(category => (
          <CategoryTile
            key={category}
            className={classes.tile}
            title={categoryLabels[category]}
            icon={<CategoryIcon name={category} size="S" />}
            category={category}
            to={IS_DEMO ? routes.OVERVIEW : `${routes.QUESTIONS}/${category}`}
            data-cy={category}
            disabled={IS_DEMO}
          />
        ))}
        <CategoryTile
          className={classes.fullRowTile}
          textColor="textSecondary"
          title={strings.category[RANDOM_CATEGORY]}
          to={`${routes.QUESTIONS}/${RANDOM_CATEGORY}`}
          data-cy="show-random"
        />
        <CategoryTile
          className={classes.fullRowTile}
          textColor="textSecondary"
          title={strings.category[PLAY_SIX_IN_ROW]}
          to={`${routes.PLAY_SIX_START}`}
          data-cy="play-six-in-row"
          disabled={IS_DEMO}
        />
      </div>
    </>
  );
};

export default ChooseCategory;
