import React, { useState } from 'react';
import Card from '@material-ui/core/Card';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import CancelSharpIcon from '@material-ui/icons/CancelSharp';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { Divider, Typography } from '@material-ui/core';
import useStyles from './TenantWidget.styles';
import { useAdminTenantBoard } from '../../context/AdminTenantBoardContext/AdminTenantBoardContext';
import { DeleteTenantPopup } from './DeleteTenant/DeleteTenantPopup';
import { DisableTenantPopup } from './DisableTenant/DisableTenantPopup';
import { DeleteIconForAdmins } from './DeleteTenant/DeleteIconForAdmins';
import InvitedTenantAdmins from './TenantAdmins/InvitedTenantAdmins';
import OnboardedTenantAdmins from './TenantAdmins/OnboardedTenantAdmins';

const TenantBoardWidget = ({ tenant }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);
  const { deleteTenant, disableTenant, enableTenant } = useAdminTenantBoard(tenant.tenantName);
  const [showConfirmationText, setShowConfirmationText] = useState(false);
  const [showDeleteConfirmationText, setShowDeleteConfirmationText] = useState(false);

  const showConfirmation = () => {
    setShowConfirmationText(true);
  };

  const hideConfirmation = () => {
    setShowConfirmationText(false);
  };

  const showDeleteConfirmation = () => {
    setShowDeleteConfirmationText(true);
  };

  const hideDeleteConfirmation = () => {
    setShowDeleteConfirmationText(false);
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  function ShowPopup() {
    if (showConfirmationText) {
      return (
        <>
          <DisableTenantPopup
            showConfirmationText={showConfirmationText}
            disableFunction={disableTenant}
            hideDisableConfirmation={hideConfirmation}
            tenant={tenant}
          />
        </>
      );
    }
    if (showDeleteConfirmationText) {
      return (
        <>
          <DeleteTenantPopup
            showConfirmationText={showDeleteConfirmationText}
            deleteFunction={deleteTenant}
            hideDeleteConfirmation={hideDeleteConfirmation}
            tenant={tenant}
          />
        </>
      );
    }
  }
  function ShowTenantInfoConditionally({ tenantName, enabled, creationTime, userCount }) {
    return (
      <Typography className={classes.content}>
        {enabled ? (
          <Tooltip title={tenantName}>
            <span style={{ flex: 2 }} className={classes.tenantTitle}>
              <CheckCircleSharpIcon className={classes.enable} /> {tenantName}
            </span>
          </Tooltip>
        ) : (
          <Tooltip title={tenantName}>
            <span style={{ flex: 2 }} className={classes.tenantDisabled}>
              <CancelSharpIcon className={classes.disableRed} /> {tenantName}
            </span>
          </Tooltip>
        )}
        <span style={{ flex: 1 }}>
          {' '}
          <b>Created: </b> {new Date(creationTime).toLocaleDateString('en-GB')}{' '}
        </span>
        <span style={{ flex: 1 }}>
          <b>Users:</b> {userCount}
        </span>

        <span>
          {enabled ? (
            <Tooltip title="Disable">
              <Button style={{ flex: 1 }} onClick={showConfirmation}>
                <CancelSharpIcon className={classes.disable} />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title="Enable">
              <Button style={{ flex: 1 }} onClick={enableTenant}>
                <CheckCircleSharpIcon className={classes.enable} />
              </Button>
            </Tooltip>
          )}
          <DeleteIconForAdmins showDeleteConfirmation={showDeleteConfirmation} tenantEnabled={enabled} />
        </span>
      </Typography>
    );
  }

  return (
    <>
      <Card variant="outlined" key={tenant.firstName}>
        <CardContent className={classes.cardStyle}>
          {showDeleteConfirmationText || showConfirmationText ? (
            <ShowPopup />
          ) : (
            <ShowTenantInfoConditionally
              tenantName={tenant.tenantName}
              enabled={tenant.enabled}
              creationTime={tenant.creationTime}
              userCount={tenant.userCount}
            />
          )}
        </CardContent>
        <CardActions className={classes.expander}>
          <IconButton
            className={clsx(classes.expand, classes.expander, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </IconButton>
        </CardActions>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <CardContent className={classes.tenantInfo}>
            <Typography className={classes.infoContent}>
              {' '}
              <span>
                <b>Deactivation Date: </b>
                {!tenant.enabled ? (
                  <span>{new Date(tenant.deactivationTime).toLocaleDateString('en-GB')}</span>
                ) : (
                  <span>None</span>
                )}
              </span>
              <span>
                <b>Contract id:</b>
                {tenant.contractId ? <span>{tenant.contractId}</span> : null}
              </span>
            </Typography>
          </CardContent>

          <Divider className={classes.divider} />
          {tenant.invitedAdmins.length ? <InvitedTenantAdmins invitedTenantAdmins={tenant} /> : null}
          <Divider className={classes.divider} />
          {tenant.onboardedAdmins.length ? <OnboardedTenantAdmins onboardedTenantAdmins={tenant} /> : null}
          <Divider className={classes.divider} />
        </Collapse>
      </Card>
    </>
  );
};

export default TenantBoardWidget;
