import React, { useEffect } from 'react';
import confetti from 'canvas-confetti';
import { useAwards } from '../../context/AwardContext/AwardContext';
import Typography from '../../components/Typography/Typography';
import strings from '../../i18n/localization';
import AwardBadge from '../../components/AwardBadge/AwardBadge';
import { useAllAwards } from '../../hooks/awards';
import { getAwardId, getLevel } from '../../service/awards';
import useStyles from './Awards.styles';

const useObtainedAwardsAsLookupMap = awards => {
  const awardMap = {};
  awards.forEach(award => {
    awardMap[award.id] = award;
  });
  return awardMap;
};

const confettiBlast = () => {
  confetti({
    particleCount: 75,
    spread: 100,
    origin: { y: 0.6 },
  });
};

const Awards = () => {
  const classes = useStyles();
  const { awards, loadAwards } = useAwards();
  const allAwards = useAllAwards();
  const awardMap = useObtainedAwardsAsLookupMap(awards);
  useEffect(() => {
    loadAwards();
    confettiBlast();
  }, [loadAwards]);

  return (
    <>
      <Typography variant="h2" gutterBottom="m">
        {strings.awards.title}
      </Typography>
      <div className={classes.awardWall} data-cy="award-wall">
        {allAwards.map(([bronze, silver, gold]) => {
          const obtainedAward = awardMap[gold] || awardMap[silver] || awardMap[bronze];
          const id = getAwardId(obtainedAward?.id || '') || getAwardId(bronze);
          return (
            <div
              key={id}
              className={classes.award}
              data-cy={obtainedAward?.obtainedOn !== undefined ? 'award-item-active' : 'award-item-inactive'}
            >
              <AwardBadge
                clickable
                id={id}
                level={getLevel(obtainedAward?.id)}
                obtained={obtainedAward?.obtainedOn !== undefined}
              />
            </div>
          );
        })}
      </div>
    </>
  );
};

export default Awards;
