import React, { useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Fab } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons';
import { SearchProvider } from '../../context/SearchEngine/SearchContext';
import QuestionList from './QuestionList/QuestionList';
import FilterForm from './FilterForm/FilterForm';
import PageTitle from '../../components/PageTitle/PageTitle';
import strings from '../../i18n/localization';
import useStyles from './AdminQuestions.styles';
import AdminQuestionDialog from '../../components/AdminQuestionDialog/AdminQuestionDialog';
import usePopupControl, { popupKeys } from '../../hooks/popup/usePopupControl';
import AdminQuestionListContext, {
  QuestionListGuard,
  useQuestionList,
} from '../../context/AdminQuestionListContext/AdminQuestionListContext';
import AdminQuestionContext from '../../context/AdminQuestionContext/AdminQuestionContext';
import ROUTES from '../../routes';

const AdminQuestions = () => {
  const classes = useStyles();
  const history = useHistory();

  const { questions } = useQuestionList();
  const questionsMapped = useMemo(() => questions.map(question => ({ id: question.questionId, ...question })), [
    questions,
  ]);
  const { popupIsOpen, openPopup, closePopup } = usePopupControl(popupKeys.ADMIN_CREATE);

  const openAddQuestionDialog = () => {
    openPopup();
  };

  const switchViewAfterCreate = ({ success, response }) => {
    if (success) {
      history.push(`${ROUTES.ADMIN_QUESTIONS}/${response.data.questionId}`);
    }
  };

  return (
    <>
      <AdminQuestionContext>
        <AdminQuestionDialog
          isCreate
          open={popupIsOpen}
          onCloseButton={closePopup}
          afterMutation={switchViewAfterCreate}
        />
      </AdminQuestionContext>
      <SearchProvider items={questionsMapped} transformerNamespace="questions">
        <div className={classes.filterPadding}>
          <PageTitle title={strings.adminQuestions.list} />
          <FilterForm />
        </div>
        <QuestionList />
      </SearchProvider>
      <div className={classes.fabContainer}>
        <Fab
          classes={{
            root: classes.fab,
          }}
          onClick={openAddQuestionDialog}
          data-cy="add-question"
        >
          <AddIcon />
        </Fab>
      </div>
    </>
  );
};

const AdminQuestionsFetcher = () => {
  const { loadQuestions } = useQuestionList();
  useEffect(() => {
    loadQuestions();
  }, [loadQuestions]);
  return (
    <QuestionListGuard>
      <AdminQuestions />
    </QuestionListGuard>
  );
};

const AdminQuestionsContextWrapper = () => (
  <AdminQuestionListContext>
    <AdminQuestionsFetcher />
  </AdminQuestionListContext>
);

export default AdminQuestionsContextWrapper;
