import React from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { DELETE, ENDPOINTS, GET, POST } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';

const initialContext = {
  tenantBoard: {
    data: null,
    pending: true,
    error: null,
  },
};

const LOAD_TENANT_BOARD_DONE = 'LOAD_TENANT_BOARD_DONE';
const LOAD_TENANT_BOARD_PENDING = 'LOAD_TENANT_BOARD_PENDING';
const LOAD_TENANT_BOARD_ERROR = 'LOAD_TENANT_BOARD_ERROR';

const DELETE_TENANT_ACTION = 'DELETE_TENANT_ACTION';
const DELETE_TENANT_PENDING_ACTION = 'DELETE_TENANT_PENDING_ACTION';
const DELETE_TENANT_ERROR_ACTION = 'DELETE_TENANT_ERROR_ACTION';

const DISABLE_TENANT_ACTION = 'DISABLE_TENANT_ACTION';
const DISABLE_TENANT_PENDING_ACTION = 'DISABLE_TENANT_PENDING_ACTION';
const DISABLE_TENANT_ERROR_ACTION = 'DISABLE_TENANT_ERROR_ACTION';

const ENABLE_TENANT_ACTION = 'ENABLE_TENANT_ACTION';
const ENABLE_TENANT_PENDING_ACTION = 'ENABLE_TENANT_PENDING_ACTION';
const ENABLE_TENANT_ERROR_ACTION = 'ENABLE_TENANT_ERROR_ACTION';

const loadTenantBoardDoneAction = createAction(LOAD_TENANT_BOARD_DONE);
const loadTenantBoardPendingAction = createAction(LOAD_TENANT_BOARD_PENDING);
const loadTenantBoardErrorAction = createAction(LOAD_TENANT_BOARD_ERROR);

const deleteTenantAction = createAction(DELETE_TENANT_ACTION);
const deleteTenantPendingAction = createAction(DELETE_TENANT_PENDING_ACTION);
const deleteTenantErrorAction = createAction(DELETE_TENANT_ERROR_ACTION);

const disableTenantAction = createAction(DISABLE_TENANT_ACTION);
const disableTenantPendingAction = createAction(DISABLE_TENANT_PENDING_ACTION);
const disableTenantErrorAction = createAction(DISABLE_TENANT_ERROR_ACTION);

const enableTenantAction = createAction(ENABLE_TENANT_ACTION);
const enableTenantPendingAction = createAction(ENABLE_TENANT_PENDING_ACTION);
const enableTenantErrorAction = createAction(ENABLE_TENANT_ERROR_ACTION);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case LOAD_TENANT_BOARD_DONE:
      draft.tenantBoard = succeed(action.payload);
      break;
    case LOAD_TENANT_BOARD_PENDING:
      draft.tenantBoard.pending = true;
      break;
    case LOAD_TENANT_BOARD_ERROR:
      draft.tenantBoard = fail(action.payload);
      break;
    case DELETE_TENANT_ACTION:
      draft.tenantBoard = succeed(action.payload);
      break;
    case DELETE_TENANT_PENDING_ACTION:
      draft.tenantBoard.pending = true;
      break;
    case DELETE_TENANT_ERROR_ACTION:
      draft.tenantBoard = fail(action.payload);
      break;
    case DISABLE_TENANT_ACTION:
      draft.tenantBoard = succeed(action.payload);
      break;
    case DISABLE_TENANT_PENDING_ACTION:
      draft.tenantBoard.pending = true;
      break;
    case DISABLE_TENANT_ERROR_ACTION:
      draft.tenantBoard = fail(action.payload);
      break;
    case ENABLE_TENANT_ACTION:
      draft.tenantBoard = succeed(action.payload);
      break;
    case ENABLE_TENANT_PENDING_ACTION:
      draft.tenantBoard.pending = true;
      break;
    case ENABLE_TENANT_ERROR_ACTION:
      draft.tenantBoard = fail(action.payload);
      break;
    default:
      break;
  }
});

const [AdminTenantBoardContextProvider, useAdminTenantBoardContext] = createContext(reducer, initialContext);

const AdminTenantBoardContext = props => <AdminTenantBoardContextProvider {...props} />;
const coordinator = promiseCoordinator();

const useAdminTenantBoard = id => {
  const { tenantBoard, dispatch } = useAdminTenantBoardContext();

  const fetch = useFetch(coordinator);

  const loadAdminTenantBoard = useDataLoad(
    fetch,
    {
      method: GET,
      url: ENDPOINTS.LOAD_TENANT_BOARD,
    },
    dispatch,
    loadTenantBoardDoneAction,
    loadTenantBoardErrorAction,
    loadTenantBoardPendingAction,
  );

  const deleteTenant = useDataLoad(
    fetch,
    {
      method: DELETE,
      url: `${ENDPOINTS.DELETE_TENANT}/${id}`,
    },
    dispatch,
    deleteTenantAction,
    deleteTenantErrorAction,
    deleteTenantPendingAction,
  );

  const disableTenant = useDataLoad(
    fetch,
    {
      method: POST,
      url: `${ENDPOINTS.DISABLE_TENANT}/${id}`,
    },
    dispatch,
    disableTenantAction,
    disableTenantErrorAction,
    disableTenantPendingAction,
  );

  const enableTenant = useDataLoad(
    fetch,
    {
      method: POST,
      url: `${ENDPOINTS.ENABLE_TENANT}/${id}`,
    },
    dispatch,
    enableTenantAction,
    enableTenantErrorAction,
    enableTenantPendingAction,
  );

  return {
    tenantBoard,
    loadAdminTenantBoard,
    deleteTenant,
    disableTenant,
    enableTenant,
  };
};

export { AdminTenantBoardContext, useAdminTenantBoard };
