import React from 'react';
import { Grid, Container } from '@material-ui/core';

import PageTitle from '../../components/PageTitle/PageTitle';
import strings from '../../i18n/localization';
import { AdminTenantBoardContext } from '../../context/AdminTenantBoardContext/AdminTenantBoardContext';
import TenantBoard from './TenantBoard';

const TenantDashboard = () => (
  <Container>
    <PageTitle title={strings.menu.tenantBoard} data-cy="tenant-board-title" />
    <Grid item xs={12}>
      <AdminTenantBoardContext>
        <TenantBoard />
      </AdminTenantBoardContext>
    </Grid>
  </Container>
);

export default TenantDashboard;
