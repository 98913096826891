const muiCssBaseline = {
  MuiCssBaseline: {
    '@global': {
      // Prefer using link as the current font files have a rendering issue on Mac in Safari for the letter "A"
      // '@font-face': [muli400, muli700, muli900],
      a: {
        textDecoration: 'none',
      },
      button: {
        cursor: 'pointer',
        border: 0,
        background: 'none',
        padding: 0,
      },
      fieldset: {
        margin: 0,
        padding: 0,
      },
    },
  },
};

export default muiCssBaseline;
