import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    backgroundColor: theme.palette.primary.c600,
    borderRadius: 0,
  },
  icon: {
    margin: 'auto',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  text: {
    textAlign: 'center',
  },
  disabledOverlay: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    opacity: 0.9,
    backgroundColor: theme.palette.grey.c500,
  },
}));

export default useStyles;
