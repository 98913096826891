import { ENDPOINTS, POST } from '../constants';
import { useQuestion } from '../../../context/QuestionContext/QuestionContext';
import { useUser } from '../../../context/UserContext/UserContext';
import promiseCoordinator from '../../../service/promiseCoordinator';
import useAxios from '../../useAxios';

const coordinator = promiseCoordinator();

const useFeedbackSubmit = () => {
  const {
    user: { language },
  } = useUser();

  const { question } = useQuestion();

  const config = {
    method: POST,
    url: `${ENDPOINTS.QUESTIONS}/${question.questionId}/feedback`,
    headers: { language },
  };

  const [{ data: submissionData, pending, error }, submitFeedback] = useAxios(coordinator, config);

  return { pending, error, submitFeedback, data: submissionData };
};

export default useFeedbackSubmit;
