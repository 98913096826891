import React, { useState, useEffect } from 'react';
import queryString from 'qs';
import { useLocation, useHistory } from 'react-router-dom';
import InputField from '../../../components/FormElements/InputField/InputField';
import useSearchContext from '../../../context/SearchEngine/SearchContext';
import Dropdown from '../../../components/Dropdown/Dropdown';
import { useCategoryLabels } from '../../../context/selectors/categories';
import strings from '../../../i18n/localization';
import Typography from '../../../components/Typography/Typography';
import Switch from './Switch/Switch';
import useStyles from './FilterForm.styles';

const CATEGORY_ALL = '0';

const FilterForm = () => {
  const classes = useStyles();
  const { applyFilter } = useSearchContext();
  const [questionId, setQuestionId] = useState('');

  const { replace } = useHistory();
  const { search, pathname } = useLocation();
  const params = queryString.parse(search, { ignoreQueryPrefix: true });
  const lastCat = params.category;
  const [category, setCategory] = useState(lastCat || '');

  const lastFeedback = params.feedback === 'true';
  const [onlyWithPendingFeedbacks, setOnlyWithPendingFeedbacks] = useState(lastFeedback);

  useEffect(() => {
    applyFilter({
      questionId,
      categoryId: category === CATEGORY_ALL ? '' : category,
      hasPendingFeedback: onlyWithPendingFeedbacks ? true : '',
    });
  }, [questionId, category, onlyWithPendingFeedbacks, applyFilter]);

  useEffect(() => {
    replace(`${pathname}?${queryString.stringify({ category, feedback: onlyWithPendingFeedbacks })}`);
  }, [replace, pathname, category, onlyWithPendingFeedbacks]);

  const noCategory = { [CATEGORY_ALL]: strings.category.all };
  const categoryLabels = useCategoryLabels();
  const categories = { ...noCategory, ...categoryLabels };

  return (
    <>
      <InputField
        placeholder={strings.adminQuestions.filterById}
        onChange={e => setQuestionId(e.target.value)}
        value={questionId}
        data-cy="filter-by-id"
      />
      <Typography extraBold variant="body3">
        {strings.adminQuestions.filterByCategory}
      </Typography>
      <Dropdown
        items={categories}
        onItemSelected={setCategory}
        className={classes.dropdown}
        selected={category}
        data-cy="filter-by-category"
      />
      <Switch
        checked={onlyWithPendingFeedbacks}
        text={strings.adminQuestions.filterByFeedback}
        onChange={e => setOnlyWithPendingFeedbacks(e.target.checked)}
        data-cy="filter-with-pending-feedback"
      />
    </>
  );
};

export default FilterForm;
