import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputField: {
    marginBottom: theme.spacing(3),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    ...theme.typography.body2,
    marginTop: theme.spacing(3),
    padding: theme.spacing(4, 5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.default,
    minHeight: 120,
    resize: 'none',
  },
  error: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
