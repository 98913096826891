import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  tab: {
    height: 20,
    padding: 0,
    lineHeight: 1,
    margin: theme.spacing(0, 3, 0, 0),
  },
  selectedTab: {
    fontWeight: 700,
  },
  errorIndicator: {
    backgroundColor: theme.palette.error.light,
    borderRadius: 10,
    width: 20,
    height: 20,
    position: 'absolute',
    right: 4,
    top: -7,
    display: 'flex',
    justifyContent: 'center',
  },
  languageBar: {
    display: 'flex',
  },
  language: {
    position: 'relative',
    marginRight: theme.spacing(3),
  },
}));

export default useStyles;
