import React from 'react';
import { IconButton, useTheme } from '@material-ui/core';
import Typography from '../Typography/Typography';
import { ReactComponent as Cutout } from './assets/footer_cutout.svg';
import useStyles from './CutoutButton.styles';

const CutoutButton = ({ children, disabled = false, ...rest }) => {
  const theme = useTheme();
  const classes = useStyles({ color: disabled ? theme.palette.grey.c500 : theme.palette.primary.main });

  return (
    <IconButton variant="contained" color="primary" className={classes.button} disabled={disabled} {...rest}>
      <div className={classes.content}>
        <div className={classes.footerPadding} />
        <div className={classes.iconContainer}>
          <Cutout className={classes.icon} />
        </div>
        <div className={classes.footerPadding} />
      </div>
      <Typography color="textSecondary" className={classes.text}>
        {children}
      </Typography>
    </IconButton>
  );
};

export default CutoutButton;
