import React, { useEffect } from 'react';
import classnames from 'classnames';

import strings from '../../../../i18n/localization';
import { useQuestionSubmission } from '../../../../context/QuestionContext/QuestionContext';
import { useScores } from '../../../../context/ScoreContext/ScoreContext';
import { useScoresSum } from '../../../../context/selectors/scores';
import Typography from '../../../Typography/Typography';
import TopHalfCircle from '../TopHalfCircle/TopHalfCircle';
import useStyles from './ScoreHeader.styles';

const ScoreHeader = () => {
  const classes = useStyles();
  const scoresSum = useScoresSum();

  const { submission } = useQuestionSubmission();
  const { loadScores } = useScores();

  useEffect(() => {
    if (submission.correct) {
      loadScores();
    }
  }, [submission, loadScores]);

  return (
    <TopHalfCircle>
      <Typography noWrap variant="body3" className={classnames(classes.centralText, classes.message)}>
        {strings.score.header}
      </Typography>
      <Typography variant="h4" noWrap className={classnames(classes.centralText, classes.score)}>
        {scoresSum}
      </Typography>
    </TopHalfCircle>
  );
};

export default ScoreHeader;
