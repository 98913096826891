import React from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import { ENDPOINTS } from '../../hooks/api/constants';
import createAction from '../../service/createAction';
import promiseCoordinator from '../../service/promiseCoordinator';
import { fail, succeed } from '../../service/reducer';
import useFetch from '../../service/useFetch';
import useDataLoad from '../../service/useDataLoad';

const initialContext = {
  quitSubmission: {
    data: null,
    pending: false,
    error: null,
  },
  status: {
    isAboutToQuit: false,
    hasFailedGame: false,
    hasWonGame: false,
    hasRunOutOfTime: false,
  },
  lastQuestionSubmission: null,
};

const QUIT_SIX_IN_A_ROW_DONE = 'QUIT_SIX_IN_A_ROW_DONE';
const QUIT_SIX_IN_A_ROW_ERROR = 'QUIT_SIX_IN_A_ROW_ERROR';
const QUIT_SIX_IN_A_ROW_PENDING = 'QUIT_SIX_IN_A_ROW_PENDING';
const SHOW_QUIT_CONFIRMATION = 'SHOW_QUIT_CONFIRMATION';
const HIDE_QUIT_CONFIRMATION = 'HIDE_QUIT_CONFIRMATION';
const FAIL_SIX_IN_A_ROW = 'FAIL_SIX_IN_A_ROW';
const WIN_SIX_IN_A_ROW = 'WIN_SIX_IN_A_ROW';
const TIME_OUT_SIX_IN_A_ROW = 'TIME_OUT_SIX_IN_A_ROW';
const STORE_LAST_QUESTION_SUBMISSION = 'STORE_LAST_QUESTION_SUBMISSION';

const quitSixInARowDoneAction = createAction(QUIT_SIX_IN_A_ROW_DONE);
const quitSixInARowErrorAction = createAction(QUIT_SIX_IN_A_ROW_ERROR);
const quitSixInARowPendingAction = createAction(QUIT_SIX_IN_A_ROW_PENDING);
const showQuitConfirmationAction = createAction(SHOW_QUIT_CONFIRMATION);
const hideQuitConfirmationAction = createAction(HIDE_QUIT_CONFIRMATION);
const failSixInARowAction = createAction(FAIL_SIX_IN_A_ROW);
const winSixInARowAction = createAction(WIN_SIX_IN_A_ROW);
const timeoutSixInARowAction = createAction(TIME_OUT_SIX_IN_A_ROW);
const storeLastQuestionSubmissionAction = createAction(STORE_LAST_QUESTION_SUBMISSION);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case QUIT_SIX_IN_A_ROW_DONE:
      draft.quitSubmission = succeed({});
      break;
    case QUIT_SIX_IN_A_ROW_ERROR:
      draft.quitSubmission = fail(action.payload);
      break;
    case QUIT_SIX_IN_A_ROW_PENDING:
      draft.quitSubmission.pending = true;
      break;
    case SHOW_QUIT_CONFIRMATION:
      draft.status.isAboutToQuit = true;
      break;
    case HIDE_QUIT_CONFIRMATION:
      draft.status.isAboutToQuit = false;
      break;
    case FAIL_SIX_IN_A_ROW:
      draft.status.hasFailedGame = true;
      break;
    case WIN_SIX_IN_A_ROW:
      draft.status.hasWonGame = true;
      break;
    case TIME_OUT_SIX_IN_A_ROW:
      draft.status.hasRunOutOfTime = true;
      break;
    case STORE_LAST_QUESTION_SUBMISSION:
      draft.lastQuestionSubmission = action.payload;
      break;
    default:
      break;
  }
});

const [SixInARowContextProvider, useSixInARowContext] = createContext(reducer, initialContext);

const SixInARowContext = props => <SixInARowContextProvider {...props} />;
const sixInARowCoordinator = promiseCoordinator();

const useSixInARow = () => {
  const { quitSubmission, status, lastQuestionSubmission, dispatch } = useSixInARowContext();
  const fetch = useFetch(sixInARowCoordinator);

  const quitSixInARow = useDataLoad(
    fetch,
    {
      method: 'POST',
      url: ENDPOINTS.SIX_IN_A_ROW_QUIT,
    },
    dispatch,
    quitSixInARowDoneAction,
    quitSixInARowErrorAction,
    quitSixInARowPendingAction,
  );

  return {
    quitSubmission,
    quitSixInARow,
    status,
    lastQuestionSubmission,
    showQuitConfirmation: () => dispatch(showQuitConfirmationAction()),
    hideQuitConfirmation: () => dispatch(hideQuitConfirmationAction()),
    failSixInARow: () => dispatch(failSixInARowAction()),
    winSixInARow: () => dispatch(winSixInARowAction()),
    timeoutSixInARow: () => dispatch(timeoutSixInARowAction()),
    storeLastQuestionSubmission: payload => dispatch(storeLastQuestionSubmissionAction(payload)),
  };
};

export default SixInARowContext;
export { useSixInARow };
