import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AppLayout from './components/AppLayout/AppLayout';
import AuthWrapper from './components/AuthWrapper/AuthWrapper';
import FetchInitialData from './components/FetchInitialData/FetchInitialData';

import { ROLES } from './constants';
import routes, { dashboard } from './routes';
import { useAuthenticationContext } from './context/AuthenticationContext/AuthenticationContext';
import { useUser } from './context/UserContext/UserContext';

import AdminDashboard from './pages/AdminDashboard/AdminDashboard';
import AdminQuestions from './pages/AdminQuestions/AdminQuestions';
import AdminQuestionDetail from './pages/AdminQuestionDetail/AdminQuestionDetail';
import ChooseCategory from './pages/ChooseCategory/ChooseCategory';
import InviteUser from './pages/InviteUser/InviteUser';
import Learn from './pages/Learn/Learn';
import Question from './pages/Question/Question';
import RankingTableDialog from './pages/UserDashboard/RankingTableDialog/RankingTableDialog';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import UserProfile from './pages/UserProfile/UserProfile';
import Awards from './pages/Awards/Awards';
import SixInARow from './pages/Play/SixInARow/Page';
import SixInARowGettingStarted from './pages/Play/SixInARow/GettingStarted';
import TenantDisabled from './pages/TenantDisabled/TenantDisabled';
import TenantDashboard from './pages/TenantBoard/TenantDashboard';

const AppGuard = () => {
  const {
    data: { idToken },
  } = useAuthenticationContext();

  if (idToken === null) {
    return <Redirect to={routes.LANDING_PAGE} />;
  }
  return null;
};

const AuthRoute = ({ path, renderComponent: Component, allowedRole = [] }) => {
  const {
    user: { roles, onboarded, enabled },
  } = useUser();
  return (
    <Route
      path={path}
      render={() => {
        if (!enabled) {
          return <Redirect to={routes.TENANT_DISABLED} />;
        }
        // redirect user to onboarding page if he wasn't onboardet yet.
        if (!onboarded) {
          return <Redirect to={routes.ONBOARDING} />;
        }

        // redirect user to dashboard if he is missing permissions
        if (!roles.some(actualUserRole => allowedRole.includes(actualUserRole))) {
          return <Redirect to={routes.OVERVIEW} />;
        }

        return <Component />;
      }}
    />
  );
};

const OnboardingProfileRoute = ({ path, renderComponent: Component }) => {
  const {
    user: { onboarded },
  } = useUser();
  return (
    <Route
      path={path}
      render={() => {
        // redirect user to dashboard if he was already onboarded
        if (onboarded) {
          return <Redirect to={routes.OVERVIEW} />;
        }

        return <Component />;
      }}
    />
  );
};

const AppRootRoute = () => {
  const {
    user: { roles, onboarded },
  } = useUser();
  if (roles.includes(ROLES.TENANT_ADMIN) && onboarded) {
    window.location.href = dashboard.HOME;
    return null;
  }
  if (roles.includes(ROLES.TENANT_MANAGER) && onboarded) {
    window.location.href = routes.ADMIN_TENANT_BOARD;
    return null;
  }
  return <Route render={() => <Redirect to={routes.OVERVIEW} />} />;
};

const AppRoute = () => (
  <Route>
    <AuthWrapper>
      <FetchInitialData>
        <AppGuard />
        <AppLayout>
          <Switch>
            <AuthRoute
              path={routes.ADMIN_DASHBOARD}
              renderComponent={AdminDashboard}
              allowedRole={[ROLES.SECURED_ADMIN]}
            />
            <AuthRoute
              path={routes.ADMIN_TENANT_BOARD}
              renderComponent={TenantDashboard}
              allowedRole={[ROLES.SECURED_ADMIN, ROLES.TENANT_MANAGER]}
            />
            <AuthRoute path={routes.INVITE} renderComponent={InviteUser} allowedRole={[ROLES.TENANT_ADMIN]} />
            <AuthRoute path={routes.OVERVIEW} renderComponent={UserDashboard} allowedRole={[ROLES.QUIZEE]} />
            <AuthRoute path={routes.CATEGORIES} renderComponent={ChooseCategory} allowedRole={[ROLES.QUIZEE]} />
            <AuthRoute path={`${routes.QUESTIONS}/:category`} renderComponent={Question} allowedRole={[ROLES.QUIZEE]} />
            <AuthRoute path={routes.LEARN} renderComponent={Learn} allowedRole={[ROLES.QUIZEE]} />
            <AuthRoute path={routes.PROFILE} renderComponent={UserProfile} allowedRole={[ROLES.QUIZEE]} />
            <AuthRoute path={routes.AWARDS} renderComponent={Awards} allowedRole={[ROLES.QUIZEE]} />
            <Route path={routes.TENANT_DISABLED} exact component={TenantDisabled} />
            <AuthRoute
              path={routes.PLAY_SIX_START}
              renderComponent={SixInARowGettingStarted}
              allowedRole={[ROLES.QUIZEE]}
            />
            <AuthRoute path={routes.PLAY_SIX} renderComponent={SixInARow} allowedRole={[ROLES.QUIZEE]} />
            {/* Route is only used on local dev environment to prevent endless redirects. On DEV / PROD this path will be
           used by KMU Dashboard */}
            <AuthRoute
              path={dashboard.HOME}
              renderComponent={() => <p>Local KMU Dashboard</p>}
              allowedRole={[ROLES.TENANT_ADMIN]}
            />

            <AuthRoute
              path={routes.ADMIN_QUESTIONS}
              renderComponent={AdminQuestions}
              allowedRole={[ROLES.SECURED_ADMIN]}
              exact
            />
            <AuthRoute
              path={`${routes.ADMIN_QUESTIONS}/:questionId`}
              renderComponent={AdminQuestionDetail}
              allowedRole={[ROLES.SECURED_ADMIN]}
              exact
            />

            <OnboardingProfileRoute path={routes.ONBOARDING} renderComponent={UserProfile} />
            <AppRootRoute />
          </Switch>
        </AppLayout>
        <RankingTableDialog />
      </FetchInitialData>
    </AuthWrapper>
  </Route>
);

export default AppRoute;
