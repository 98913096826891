import React from 'react';

import Avatar from '../../../components/Avatar/Avatar';

const RenderAvatarTick = ({ x, y, payload }) => {
  const size = 50;
  const charSize = 15;
  return <Avatar name={payload.value} x={x - size / 2} y={y - size / 2 + charSize} size="M" />;
};

export default RenderAvatarTick;
