import { useCallback, useRef } from 'react';
import axios from 'axios';
import { API_URL, GET } from '../hooks/api/constants';
import { useIdToken } from '../context/AuthenticationContext/AuthenticationContext';

const getConfig = (idToken, config = {}) => {
  if (!idToken) {
    // eslint-disable-next-line no-console
    console.error('please provide an id token');
  }

  return {
    method: config.method || GET,
    baseURL: API_URL,
    url: config.url,
    headers: {
      Authorization: `Bearer ${idToken}`,
      ...config.headers,
    },
    params: config.params,
    data: config.data,
  };
};

const useFetch = (coordinator, config) => {
  const { idToken } = useIdToken();
  // use ref here because we don't care about changing idTokens
  // while it must use the new token for subsequent renders it must not trigger a reload
  const headerRef = useRef();
  headerRef.current = {
    token: idToken,
  };

  const load = useCallback(
    async customConfig => {
      const response = await axios(
        getConfig(headerRef.current.token, {
          ...config,
          ...customConfig,
        }),
      );
      return response;
    },
    [config],
  );
  return load;
};

export default useFetch;
