import React from 'react';
import classnames from 'classnames';

import strings from '../../../i18n/localization';
import useStyles from './OrdinalNumberTick.styles';

const OrdinalNumberTick = ({ x, y, payload, activeUser, fillList, index }) => {
  const classes = useStyles();
  const isActiveUser = activeUser === payload.value;
  return (
    <text
      x={x}
      y={y}
      dy={30}
      textAnchor="middle"
      className={classnames(classes.textNode, { [classes.activeUser]: isActiveUser })}
      fill={fillList[index]}
    >
      {payload.value}
      {strings.ranking.ordinalGenericSuffix}
    </text>
  );
};

export default OrdinalNumberTick;
