import { useMemo } from 'react';
import { useTheme } from '@material-ui/core';

import strings from '../../i18n/localization';

const useCategoryLabels = () => {
  const currentLanguage = strings.getLanguage();

  return useMemo(
    () => ({
      dat: strings.category.dat,
      web: strings.category.web,
      mob: strings.category.mob,
      ema: strings.category.ema,
      pwd: strings.category.pwd,
      inc: strings.category.inc,
    }),
    // strings will automatically update based on selected language
    // therefore upon language change the labels must be re-calculated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );
};

const useCategoryColors = () => {
  const theme = useTheme();
  return useMemo(
    () => ({
      dat: theme.palette.dat.main,
      web: theme.palette.web.main,
      mob: theme.palette.mob.main,
      ema: theme.palette.ema.main,
      pwd: theme.palette.pwd.main,
      inc: theme.palette.inc.main,
    }),
    [
      theme.palette.dat.main,
      theme.palette.ema.main,
      theme.palette.inc.main,
      theme.palette.mob.main,
      theme.palette.pwd.main,
      theme.palette.web.main,
    ],
  );
};

export { useCategoryLabels, useCategoryColors };
