import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core';

import Themes from './themes';
import App from './App';
import enforceHttps from './enforceHttps';
import MessageContext from './context/MessageContext/MessageContext';

// Make sure to always load using https on production
if (process.env.NODE_ENV === 'production') {
  enforceHttps();
}

const rootElement = document.getElementById('root');
ReactDOM.render(
  <ThemeProvider theme={Themes.default}>
    <MessageContext>
      <CssBaseline />
      <App />
    </MessageContext>
  </ThemeProvider>,
  rootElement,
);
