import React from 'react';
import { Controller } from 'react-hook-form';
import { RegistrationInputField } from './RegistrationInputField';

export const ControlledRegistrationField = ({
  fieldName,
  error,
  helperText,
  label,
  placeholder,
  defaultValue,
  disabled,
  onChange,
  autoFocus,
  control,
}) => (
  <Controller
    as={<RegistrationInputField />}
    name={fieldName}
    fieldName={fieldName}
    placeholder={placeholder}
    helperText={helperText}
    label={label}
    error={error}
    control={control}
    required
    disabled={disabled}
    defaultValue={defaultValue}
    fullWidth
    autoFocus={autoFocus}
    onChange={([event]) => {
      onChange(event);
      return event.target.value;
    }}
  />
);
