import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    transition: 'background-color 0.2s ease-in-out',
  },
  isDragActive: {
    backgroundColor: theme.palette.primary.c100,
  },
  uploadButton: {
    margin: `${theme.spacing(5)}px auto 0`,
  },
}));

export default useStyles;
