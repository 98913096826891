import React from 'react';
import { Cached as FlipIcon } from '@material-ui/icons';
import isEmpty from 'lodash/isEmpty';
import strings from '../../../i18n/localization';
import { QUESTIONS } from '../../../pages/Play/SixInARow/config';
import { useQuestion, useQuestionSubmission } from '../../../context/QuestionContext/QuestionContext';
import { useCategoryLabels, useCategoryColors } from '../../../context/selectors/categories';
import Button from '../../Button/Button';
import Card from '../../Card/Card';
import CardFooter from '../../CardFooter/CardFooter';
import Typography from '../../Typography/Typography';
import AnswerContainer from './AnswerContainer/AnswerContainer';
import QuestionStepper from './QuestionStepper/QuestionStepper';
import useStyles from './QuestionCard.styles';

export default function QuestionCard({ question, showFeedback, flipCard }) {
  const classes = useStyles();
  const { submission, pending, submitQuestion } = useQuestionSubmission();
  const categoryLabels = useCategoryLabels();
  const categoryColors = useCategoryColors();
  const { isSixInARowMode } = useQuestion();

  const confirmQuestion = e => {
    e.preventDefault();
    submitQuestion();
  };

  const categoryColor = categoryColors[question.category];
  const isCurrentlyAnsweringQuestions = isEmpty(submission);

  return (
    <Card>
      <div className={classes.content}>
        <div className={classes.header}>
          {isSixInARowMode ? <QuestionStepper steps={QUESTIONS} data-cy="questions-steps" /> : null}
          <Typography variant="h5" bold style={{ color: categoryColor }}>
            {categoryLabels[question.category]}
          </Typography>
          <Typography variant="h4" markdown>
            {question.questionText}
          </Typography>
          <Typography style={{ backgroundColor: categoryColor }} classes={{ root: classes.badge }} component="span">
            {question.difficulty}
          </Typography>
        </div>

        <AnswerContainer question={question} />
      </div>
      <CardFooter onFeedbackClick={showFeedback}>
        {isCurrentlyAnsweringQuestions ? (
          <Button
            size="large"
            variant="contained"
            className={classes.startButton}
            onClick={confirmQuestion}
            disabled={pending}
            data-cy="show-solution"
          >
            {strings.card.confirm}
          </Button>
        ) : (
          <Button onClick={flipCard} classes={{ label: classes.flipCardButton }}>
            <FlipIcon />
            <Typography component="span" variant="h5">
              {strings.card.seeExplanation}
            </Typography>
          </Button>
        )}
      </CardFooter>
    </Card>
  );
}
