import React from 'react';
import { CircularProgress } from '@material-ui/core';
import classnames from 'classnames';

import useStyles from './Loader.styles';

const Loader = ({ isFullScreen, ...rest }) => {
  const classes = useStyles();

  return (
    <div
      className={classnames(classes.loaderContainer, {
        [classes.fullscreen]: isFullScreen,
      })}
      {...rest}
    >
      <CircularProgress />
    </div>
  );
};

export default Loader;
