import React from 'react';
import { Grid } from '@material-ui/core';
import strings from '../../../i18n/localization';
import Button from '../../../components/Button/Button';
import PageTitle from '../../../components/PageTitle/PageTitle';
import routes from '../../../routes';
import { SECONDS, QUESTIONS } from './config';
import useStyles from './GettingStarted.styles';

const GettingStartedPage = () => {
  const classes = useStyles();

  return (
    <>
      <PageTitle title={strings.sixInARow.gettingStarted.title} />
      <Grid container spacing={4} direction="column" alignItems="stretch">
        <Grid item>
          {strings.formatString(
            strings.sixInARow.gettingStarted.description,
            <b>{strings.formatString(strings.sixInARow.gettingStarted.time, SECONDS)}</b>,
            <b>{strings.formatString(strings.sixInARow.gettingStarted.questions, QUESTIONS)}</b>,
          )}
        </Grid>
        <Grid item>{strings.sixInARow.gettingStarted.gameProgression}</Grid>
        <Grid item>{strings.sixInARow.gettingStarted.cancelCondition}</Grid>
        <Grid item>
          <b>{strings.sixInARow.gettingStarted.goodLuck}</b>
        </Grid>
      </Grid>
      <Grid container direction="row" justify="center" alignItems="flex-end" className={classes.goButtonContainer}>
        <Grid item xs={6} sm={4}>
          <Button
            variant="contained"
            color="primary"
            className={classes.goButton}
            to={routes.PLAY_SIX}
            data-cy="btn-start-six-in-a-row"
          >
            {strings.sixInARow.gettingStarted.go}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default GettingStartedPage;
