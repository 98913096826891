import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  bar: {
    fill: theme.palette.primary.main,
  },
  chartsContainer: {
    margin: `${theme.spacing(6)}px auto`,
    maxWidth: theme.fixedWidth.maxWidth - 150,
  },
  arrowPaddingRight: {
    paddingBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    display: 'initial',
  },
}));

export default useStyles;
