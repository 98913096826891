import React from 'react';
import { Switch as MaterialSwitch } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import useStyles from './Switch.styles';
import Typography from '../../../../components/Typography/Typography';

const Switch = ({ checked, text, ...props }) => {
  const classes = useStyles();

  return (
    <div className={classes.switch}>
      <MaterialSwitch
        checked={checked}
        focusVisibleClassName={classes.focusVisible}
        disableRipple
        icon={<IndeterminateCheckBoxIcon className={classes.icon} />}
        checkedIcon={<CheckBoxIcon className={classes.icon} />}
        classes={{
          root: classes.root,
          switchBase: classes.switchBase,
          thumb: classes.thumb,
          track: classes.track,
          checked: classes.checked,
        }}
        {...props}
      />
      <Typography extraBold variant="body3">
        {text}
      </Typography>
    </div>
  );
};

export default Switch;
