import React, { useEffect } from 'react';
import QuestionCardLayout from '../../../components/QuestionCardLayout/QuestionCardLayout';
import { useQuestion, useQuestionSubmission } from '../../../context/QuestionContext/QuestionContext';
import { useScores } from '../../../context/ScoreContext/ScoreContext';
import { useSixInARow } from '../../../context/SixInARow/SixInARowContext';
import { GAME_STATE } from './config';
import { ENDPOINTS } from '../../../hooks/api/constants';

const Question = () => {
  const { loadQuestion, pending } = useQuestion();
  const { failSixInARow, winSixInARow, storeLastQuestionSubmission } = useSixInARow();
  const { submission } = useQuestionSubmission();
  const { loadScores } = useScores();

  useEffect(() => {
    if (submission.state === GAME_STATE.FAILED) {
      failSixInARow();
    } else if (submission.state === GAME_STATE.DONE) {
      winSixInARow();
    } else if (submission.state === GAME_STATE.IN_PROGRESS && !pending) {
      loadQuestion({ url: ENDPOINTS.SIX_IN_A_ROW_QUESTIONS });
    }
  }, [submission, loadQuestion, failSixInARow, winSixInARow, pending]);

  useEffect(() => {
    if (submission.correct) {
      loadScores();
    }
  }, [submission, loadScores]);

  useEffect(() => {
    if (submission && submission.pointsEarned) {
      storeLastQuestionSubmission(submission);
    }
  }, [submission, storeLastQuestionSubmission]);

  return <QuestionCardLayout />;
};

export default Question;
