import React, { useEffect, useRef } from 'react';
import { Container, useTheme } from '@material-ui/core';
import classnames from 'classnames';
import ReactionIcon from '../../../pages/Question/FeedbackOverlay/ReactionIcon/ReactionIcon';
import Typography from '../../Typography/Typography';
import strings from '../../../i18n/localization';
import { useAnimateScore } from './AnimateScore';
import useStyles from './ScoreBadge.styles';

const ScoreBadge = props => {
  const theme = useTheme();
  const { submission = { pointsEarned: 1, score: 1 }, subtitle = '', color = theme.palette.success.main } = props;
  const classes = useStyles({ color });

  const { animateScore } = useAnimateScore();
  const domElement = useRef(null);

  useEffect(() => {
    if (submission.pointsEarned > 0) {
      animateScore(
        domElement.current,
        {
          pointsEarned: submission.pointsEarned,
          score: submission.score,
        },
        classes,
      );
    }
  }, [submission, classes, animateScore]);

  return (
    <Container ref={domElement} className={classes.root}>
      <div className={classes.iconContainer}>
        <ReactionIcon icon="positive" size="S" className={classes.icon} />
      </div>
      <div className={classes.scoreCircle}>
        <div className={classnames(classes.pointsEarnedCircle, classes.pointsEarnedCircleBackground)}>
          <Typography color="textSecondary" className={classnames(classes.pointsEarned, 'earned')} />
        </div>
        <div className="scoreInnerTag">
          <Typography color="textPrimaryLight" className={classes.scoreText}>
            {strings.feedback.score}
          </Typography>
          <Typography variant="h1" color="textPrimary" className="overall" />
        </div>
      </div>
      <div className="subtitle">{subtitle}</div>
    </Container>
  );
};

export default ScoreBadge;
