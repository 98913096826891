import React from 'react';
import classnames from 'classnames';

import useStyles from './Answers.styles';
import strings from '../../../i18n/localization';
import Typography from '../../../components/Typography/Typography';

const Answers = ({ question, language }) => {
  const classes = useStyles();
  const { answers } = question;
  return (
    <>
      {answers.map((answer, idx) => (
        <div key={answer.id || `idx-${idx}`} className={classes.questionContainer}>
          <Typography variant="h5">
            {strings.admin.questionDetail.answer} {idx + 1} –{' '}
            <Typography
              component="span"
              className={classnames({
                [classes.wrongAnswer]: !answer.isCorrect,
                [classes.rightAnswer]: answer.isCorrect,
              })}
            >
              {answer.isCorrect ? strings.admin.questionDetail.correctAnswer : strings.admin.questionDetail.wrongAnswer}
            </Typography>
          </Typography>
          <Typography>{answer[`text${language}`]}</Typography>
        </div>
      ))}
    </>
  );
};

export default Answers;
