import { ENDPOINTS, PUT } from '../constants';
import useAxios from '../../useAxios';
import promiseCoordinator from '../../../service/promiseCoordinator';

const coordinator = promiseCoordinator();

const useResetCache = () => {
  const config = {
    method: PUT,
    url: ENDPOINTS.ADMIN_RESET_CACHE,
  };

  const [{ pending, error }, resetCache] = useAxios(coordinator, config);

  return { pending, error, resetCache };
};

export default useResetCache;
