import React from 'react';
import Avatar from '../../../components/Avatar/Avatar';
import useStyles from './AvatarGallery.styles';

const AvatarGallery = ({ users, maxAvatars = 10 }) => {
  const classes = useStyles();

  return (
    <div className={classes.avatarContainer}>
      {users.slice(0, maxAvatars).map(entry => (
        <Avatar name={entry.avatar} key={entry.nickName} size="S" className={classes.avatar} />
      ))}
    </div>
  );
};

export default AvatarGallery;
