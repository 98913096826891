import React from 'react';
import { Container, Dialog, Grid, Slide } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import routes from '../../../../../routes';
import ReactionIcon from '../../../../Question/FeedbackOverlay/ReactionIcon/ReactionIcon';
import Button from '../../../../../components/Button/Button';
import Typography from '../../../../../components/Typography/Typography';
import strings from '../../../../../i18n/localization';
import useStyles from './FailOverlay.styles';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const FailOverlay = ({ isOpen, onClose, showSolution = true, isFullScreen = false }) => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Dialog
      open={isOpen}
      fullScreen
      classes={{
        root: isFullScreen ? classes.rootFullScreen : classes.root,
        paper: isFullScreen ? '' : `${classes.shape}`,
      }}
      onClose={onClose}
      TransitionComponent={Transition}
      BackdropProps={{
        classes: {
          root: `${isFullScreen ? '' : classes.shape} ${classes.root}`,
        },
      }}
      data-cy="six-in-a-row-failed-overlay"
    >
      <Container className={classes.container}>
        <div className={classes.iconContainer}>
          <ReactionIcon icon="negative" size="L" className={classes.icon} />
          <Typography variant="h1" color="error" data-cy="title-negative">
            {strings.feedback.ohno}
          </Typography>
          <Typography variant="h3" color="error" className={classes.description}>
            {strings.sixInARow.gameOver}
          </Typography>
        </div>

        <div className={classes.spacer} />

        <Grid container spacing={5} className={classes.buttonContainer} data-cy="btn-action-container">
          {showSolution ? (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={onClose}
                className={classes.button}
                color="secondary"
                data-cy="btn-show-solution"
              >
                {strings.feedback.showSolution}
              </Button>
            </Grid>
          ) : null}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={() => history.push(routes.PLAY_SIX_START)}
              className={classes.button}
              color="secondary"
              data-cy="btn-try-again"
            >
              {strings.sixInARow.tryAgain}
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};

export default FailOverlay;
