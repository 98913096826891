const PRIMARY = '#5C56CE';
const PRIMARY_100 = '#F2F1FD';
const PRIMARY_200 = '#E6E5F2';
const PRIMARY_400 = '#D3D0FF';
const PRIMARY_600 = '#AEAAFF';
const PRIMARY_900 = '#2E2C4E';

const SUCCESS = '#15CF9E';
const SUCCESS_LIGHT = '#48E3BB';
const SUCCESS_EXTRA_LIGHT = '#D1F8EE';
const ERROR = '#F45050';
const ERROR_LIGHT = '#FFAB91';
const ERROR_EXTRA_LIGHT = '#FEEEEE';

const DATA = '#A6D6ED';
const WEB = '#D6CE87';
const MOBILE = '#47E1B9';
const EMAIL = '#8EBEF5';
const PASSWORD = '#A8D692';
const INCIDENT = '#B3DBE0';

const TEXT_PRIMARY = '#3D3D3E';
const TEXT_PRIMARY_LIGHT = '#7E8087';
const WHITE = '#FFFFFF';

const GREY_100 = '#F1F0F4';
const GREY_300 = '#D8D6DC';
const GREY_500 = '#C7C9D1';
const GREY_900 = '#3D434A';

const BAR_FIRST = '#5C56CE';
const BAR_SECOND = '#2E2C4E';
const BAR_THIRD = '#4B496B';

const tenantBoardTheme = {
  palette: {
    primary: {
      main: PRIMARY,
      c100: PRIMARY_100,
      c200: PRIMARY_200,
      c400: PRIMARY_400,
      c600: PRIMARY_600,
      dark: PRIMARY_900,
    },
    secondary: {
      main: WHITE,
    },
    error: {
      main: ERROR,
      light: ERROR_LIGHT,
      extraLight: ERROR_EXTRA_LIGHT,
    },
    success: {
      main: SUCCESS,
      light: SUCCESS_LIGHT,
      extraLight: SUCCESS_EXTRA_LIGHT,
    },
    info: {
      main: PRIMARY,
    },
    text: {
      primary: TEXT_PRIMARY,
      primaryLight: TEXT_PRIMARY_LIGHT,
      secondary: WHITE,
    },
    grey: {
      c100: GREY_100,
      c300: GREY_300,
      c500: GREY_500,
      c900: GREY_900,
    },
    dat: {
      main: DATA,
    },
    ema: {
      main: EMAIL,
    },
    pwd: {
      main: PASSWORD,
    },
    web: {
      main: WEB,
    },
    mob: {
      main: MOBILE,
    },
    inc: {
      main: INCIDENT,
    },
    bars: {
      first: BAR_FIRST,
      second: BAR_SECOND,
      third: BAR_THIRD,
    },
    background: {
      default: GREY_100,
      paper: WHITE,
    },
  },
  spacing: factor => [0, 1, 2, 4, 8, 16, 24, 32, 48, 64][factor],
  shape: {
    borderRadius: 4,
    borderRadiusCard: 20,
    cardBorderRadius: 30,
  },
  customShadows: {
    lightBottom: `0 4px 4px ${GREY_100}`,
    strongBottom: '0px 4px 10px rgba(0, 0, 0, 0.2)',
    light: `0 0 4px ${GREY_100}`,
    medium: `0 0 8px 2px ${GREY_500}`,
    dark: `0 0 8px 2px ${GREY_900}`,
    card: '0px 4px 14px rgba(0, 0, 0, 0.25)',
    lightTop: '0px -2px 5px rgba(0, 0, 0, 0.1)',
  },
  layers: {
    content: 1,
    appBar: 2,
    fullScreenLoader: 3,
    questionCard: 3,
  },
  padding: {
    vertical: {
      xs: 4,
      sm: 8,
      md: 8,
    },
    horizontal: {
      xs: 8,
      sm: 24,
      md: 32,
    },
  },
  breakpoints: {
    values: {
      xs: 321,
      sm: 500,
      md: 600,
      lg: 900,
    },
  },
  fixedWidth: {
    maxWidth: 1800,
    margin: '0 auto',
    width: '100%',
  },
  height: {
    header: 55,
    questionFooter: 50,
  },
};

export default tenantBoardTheme;
