import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  feedbackList: {
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
}));

export default useStyles;
