import React from 'react';
import isEmpty from 'lodash/isEmpty';
import Typography from '../components/Typography/Typography';
import Loader from '../components/Loader/Loader';

const ErrorDisplay = ({ error }) => {
  const message = error.message || 'Sorry, something went wrong';
  return <Typography color="error">{message}</Typography>;
};

const createGuard = useContext => ({ children, fallback = <Loader />, allowOldData = true }) => {
  const { data, pending, error } = useContext();

  const emptyData = isEmpty(data);

  if (pending) {
    if (allowOldData && !emptyData) {
      return children;
    }
    return fallback;
  }
  if (!emptyData) {
    return children;
  }
  if (error) {
    return <ErrorDisplay error={error} />;
  }
  return null;
};

export default createGuard;
