import React from 'react';
import { Container } from '@material-ui/core';
import PageTitle from '../../components/PageTitle/PageTitle';
import useStyles from './TenantDisabled.styles';
import strings from '../../i18n/localization';

const TenantDisabled = () => {
  const classes = useStyles();
  return (
    <Container maxWidth="sm">
      <PageTitle title={strings.disabled.title} />
      <div className={classes.disableText}>{strings.disabled.text}</div>
    </Container>
  );
};

export default TenantDisabled;
