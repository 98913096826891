import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: 'auto',
    paddingTop: theme.spacing(7),
  },
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(4),
  },
  text: {
    fontSize: '0.75rem', // 12px
    textDecoration: 'none',
    color: theme.palette.text.primaryLight,
  },
}));

export default useStyles;
