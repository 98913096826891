const succeed = payload => ({
  error: null,
  pending: false,
  data: payload,
});

const fail = payload => ({
  error: payload,
  pending: false,
  data: null,
});

export { succeed, fail };
