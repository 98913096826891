import React, { useEffect } from 'react';
import AdminFeedbackDialog from '../../../components/AdminFeedbackDialog/AdminFeedbackDialog';
import Button from '../../../components/Button/Button';
import usePopupControl, { popupKeys } from '../../../hooks/popup/usePopupControl';
import FeedbackIcon from '../../../components/Icons/FeedbackIcon/FeedbackIcon';
import PendingFeedbackBadge from '../../../components/PendingFeedbackBadge/PendingFeedbackBadge';
import {
  FeedbackStatsGuard,
  useFeedbackStats,
} from '../../../context/AdminQuestionFeedbackStatsContext/AdminQuestionFeedbackStatsContext';
import AdminFeedbackListContext from '../../../context/AdminFeedbackListContext/AdminFeedbackListContext';

const FeedbackDialogButton = ({ questionId }) => {
  const { questionFeedbackStats } = useFeedbackStats();
  const { popupIsOpen, openPopup, closePopup } = usePopupControl(popupKeys.ADMIN_FEEDBACK);
  return (
    <>
      <AdminFeedbackListContext>
        <AdminFeedbackDialog isOpen={popupIsOpen} questionId={questionId} close={closePopup} />
      </AdminFeedbackListContext>
      <Button onClick={openPopup} data-cy="show-feedback">
        {questionFeedbackStats.pendingFeedbackCount > 0 ? (
          <PendingFeedbackBadge number={questionFeedbackStats.pendingFeedbackCount} />
        ) : (
          <FeedbackIcon />
        )}
      </Button>
    </>
  );
};

const FeedbackDialogButtonFetcher = ({ questionId }) => {
  const { loadFeedbackStats } = useFeedbackStats(questionId);
  useEffect(() => {
    loadFeedbackStats();
  }, [loadFeedbackStats]);
  return (
    <FeedbackStatsGuard fallback={null}>
      <FeedbackDialogButton questionId={questionId} />
    </FeedbackStatsGuard>
  );
};

export default FeedbackDialogButtonFetcher;
