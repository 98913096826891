import React from 'react';
import { Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from './ResetCache.styles';
import Widget from '../../../components/Widget/Widget';
import useResetCache from '../../../hooks/api/resetCache/useResetCache';

const ResetCacheWidget = () => {
  const classes = useStyles();
  const { resetCache } = useResetCache();

  return (
    <Widget className={classes.widget} title="Reset Hibernate Cache">
      <Button variant="contained" onClick={resetCache} className={classes.button} startIcon={<DeleteIcon />}>
        Delete
      </Button>
    </Widget>
  );
};

export default ResetCacheWidget;
