import React, { useEffect, useState } from 'react';
import { Divider, Input, Typography } from '@material-ui/core';
import { useAdminTenantBoard } from '../../context/AdminTenantBoardContext/AdminTenantBoardContext';
import TenantBoardWidget from './TenantBoardWidget';
import useStyles from './TenantWidget.styles';
import Widget from '../../components/Widget/Widget';
import Paginator from '../../components/Paginator/Paginator';
import DownloadTenantCsv from './CsvWidget/DownloadCsv/DownloadTenantCsv';

const TenantBoard = () => {
  const classes = useStyles();
  const { tenantBoard, loadAdminTenantBoard } = useAdminTenantBoard();
  const [filterResultMessage, setFilterResultMessage] = useState();
  const [filteredData, setFilteredData] = useState([]);
  const [startSearching, setStartSearching] = useState(false);
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const noOfPages = Math.ceil(tenantBoard.data?.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };
  useEffect(() => {
    loadAdminTenantBoard();
  }, [loadAdminTenantBoard]);

  const handleSearch = event => {
    const value = event.target.value.toLowerCase();
    setStartSearching(true);
    let result = [];
    result = tenantBoard.data.filter(
      data => data.tenantName.toLowerCase().search(value) !== -1 || data.contractId?.startsWith(value),
    );
    if (result) {
      const msg = `${result.length.toString()} tenants`;
      setFilterResultMessage(msg);
    }
    setFilteredData(result);
  };
  function getCurrentTenants(tenants) {
    const indexOfLastTenant = page * itemsPerPage;
    const indexOfFirstTenant = indexOfLastTenant - itemsPerPage;
    return tenants.slice(indexOfFirstTenant, indexOfLastTenant);
  }
  return (
    <>
      <Input
        onKeyPress={() => {
          setPage(1);
          setStartSearching(true);
        }}
        icon="search"
        placeholder="Search ..."
        onChange={event => handleSearch(event)}
      />
      <Divider className={classes.divider} />
      <Widget className={classes.widget}>
        {!tenantBoard.pending && getCurrentTenants(filteredData).length === 0 && startSearching === false
          ? tenantBoard.data.slice((page - 1) * itemsPerPage, page * itemsPerPage).map(tenant => (
            <div className={classes.card} key={tenant.tenantName}>
              <TenantBoardWidget tenant={tenant} />
            </div>
            ))
          : null}
        {!tenantBoard.pending && getCurrentTenants(filteredData)
          ? getCurrentTenants(filteredData).map(filteredTenant => (
            <div className={classes.card} key={filteredTenant.tenantName}>
              <TenantBoardWidget tenant={filteredTenant} />
            </div>
            ))
          : null}
        {getCurrentTenants(filteredData).length === 0 && startSearching === true ? (
          <>
            <Typography>
              <b>No tenant has been found !</b>
            </Typography>
          </>
        ) : null}

        <b>{filterResultMessage}</b>
      </Widget>
      {getCurrentTenants(filteredData).length ? (
        <Paginator
          noOfPages={Math.ceil(filteredData.length / itemsPerPage)}
          page={page}
          onChange={handleChange}
          defaultPage={1}
        />
      ) : (
        <Paginator noOfPages={noOfPages} page={page} onChange={handleChange} defaultPage={1} />
      )}
      <Divider className={classes.divider} />
      <DownloadTenantCsv />
    </>
  );
};

export default TenantBoard;
