import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(4),
    width: '100%',
    overflowX: 'auto',
    marginBottom: theme.spacing(3),
  },
}));

export default useStyles;
