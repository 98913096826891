import React from 'react';
import useStyles from './TopHalfCircle.styles';

const TopHalfCircle = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.halfCircle} />
      <div className={classes.content}>{children}</div>
    </div>
  );
};

export default TopHalfCircle;
