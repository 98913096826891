import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    height: 1,
    backgroundColor: theme.palette.grey.c300,
  },
  disableText: {
    fontSize: 15,
    fontWeight: 'bold',
  },
}));

export default useStyles;
