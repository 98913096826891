const MAX_DB_SIZE = 8000;
const INFERIOR_POLICYNR_LIMIT = 14000000;
const SUPERIOR_POLICYNR_LIMIT = 15999999;

export const isWithinMaxLength = field => {
  if (field.length >= 1 && field.length <= MAX_DB_SIZE) {
    return true;
  }
  return false;
};

export const isValidEmail = email => {
  if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
    return true;
  }
  return false;
};

export const replaceDots = stringWithDots => {
  const regex = /[.]/g;
  return stringWithDots.replace(regex, '');
};

const checkIfDotsArePresent = policyNumberWithDots => {
  if (policyNumberWithDots.indexOf('.') !== -1) {
    const isSecondPositionFirstDot = policyNumberWithDots.charAt(2) === '.';
    const isSixthPositionSecondDot = policyNumberWithDots.charAt(6) === '.';
    if (isSecondPositionFirstDot && isSixthPositionSecondDot) {
      return replaceDots(policyNumberWithDots);
    }
  }
  return policyNumberWithDots;
};
export const isValidPolicyNr = policyNr => {
  if (
    checkIfDotsArePresent(policyNr) >= INFERIOR_POLICYNR_LIMIT &&
    checkIfDotsArePresent(policyNr) <= SUPERIOR_POLICYNR_LIMIT
  ) {
    return true;
  }
  return false;
};

export const isValidURL = domains => {
  const pattern = new RegExp(
    '^https?:\\/\\/' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  ); // fragment locator
  const urls = domains
    .replaceAll(' ', '')
    .replaceAll('\n', '')
    .split(',');
  const valid = urls.every(url => url.match(pattern));
  return valid;
};
