import React, { useState, useCallback } from 'react';
import ReactCardFlip from 'react-card-flip';

import QuestionCardExplanation from './QuestionCardExplanation/QuestionCardExplanation';
import QuestionCard from './QuestionCard/QuestionCard';
import FeedbackDialog from './FeedbackDialog/FeedbackDialog';
import { useQuestion, useQuestionState } from '../../context/QuestionContext/QuestionContext';
import useStyles from './QuestionCardLayout.styles';
import QuestionCardAnimation from './QuestionCardAnimation/QuestionCardAnimation';

const QuestionCardLayout = () => {
  const classes = useStyles();
  const [isFeedbackVisible, setFeedbackVisibility] = useState(false);
  const { question } = useQuestion();
  const { flipQuestion, isFlipped } = useQuestionState();
  const showFeedback = () => {
    setFeedbackVisibility(true);
  };
  const closeFeedback = useCallback(() => setFeedbackVisibility(false), [setFeedbackVisibility]);

  return (
    <QuestionCardAnimation className={classes.cardAnimContainer}>
      <ReactCardFlip
        isFlipped={isFlipped}
        flipSpeedBackToFront={0.5}
        flipSpeedFrontToBack={0.5}
        containerStyle={{ height: '100%', width: '100%' }}
        cardStyles={{ front: isFlipped ? { pointerEvents: 'none' } : {} }}
      >
        <QuestionCard question={question} showFeedback={showFeedback} flipCard={flipQuestion} />
        <QuestionCardExplanation showFeedback={showFeedback} flipCard={flipQuestion} />
      </ReactCardFlip>
      <FeedbackDialog open={isFeedbackVisible} onClose={closeFeedback} />
    </QuestionCardAnimation>
  );
};

export default QuestionCardLayout;
