import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  spacing: {
    marginTop: theme.spacing(3),
    display: 'block',
  },
  arrowPaddingLeft: {
    paddingTop: theme.spacing(4),
    marginLeft: theme.spacing(4),
    display: 'initial',
  },
  chartsContainer: {
    margin: `${theme.spacing(6)}px auto`,
    maxWidth: theme.fixedWidth.maxWidth - 150,
  },
}));

export default useStyles;
