/* eslint-disable import/no-unresolved */
import React, { useState, useMemo, useContext, useEffect } from 'react';
// eslint-disable-next-line import/no-webpack-loader-syntax
import searchWorker from 'workerize-loader!./searchWorker';

const SearchContext = React.createContext();
const useSearchContext = () => useContext(SearchContext);

const SearchProvider = ({ items, transformerNamespace, children }) => {
  const itemMap = useMemo(
    () =>
      items.reduce((agg, question) => {
        // eslint-disable-next-line no-param-reassign
        agg[question.id] = question;
        return agg;
      }, {}),
    [items],
  );
  const [filter, setFilter] = useState({});
  const [worker] = useState(() => searchWorker());
  const [filteredItems, setFilteredItems] = useState(null);

  // update worker reference and post items
  useEffect(() => {
    worker.setItems(items, transformerNamespace);
  }, [items, transformerNamespace, worker]);

  // apply filter
  useEffect(() => {
    (async () => {
      const questionIds = await worker.applyFilter(filter);
      setFilteredItems(questionIds.map(id => itemMap[id]));
    })();
  }, [filter, worker, itemMap]);

  const context = useMemo(
    () => ({
      filteredItems,
      applyFilter: setFilter,
    }),
    [filteredItems],
  );

  return <SearchContext.Provider value={context}>{children}</SearchContext.Provider>;
};

export { SearchProvider };

export default useSearchContext;
