import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  textPrimaryLight: {
    color: theme.palette.text.primaryLight,
  },
  primary: {
    color: theme.palette.primary.main,
  },
  primaryLight: {
    color: theme.palette.primary.c600,
  },
  textPrimary: {
    color: theme.palette.text.primary,
  },
  textSecondary: {
    color: theme.palette.text.secondary,
  },
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  extraBold: {
    fontWeight: 900,
  },
  bold: {
    fontWeight: 700,
  },
  body3: theme.typography.body3,
  button2: theme.typography.button2,
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'underline',
  },
  gutterBottomMedium: {
    marginBottom: theme.spacing(5),
  },
  gutterBottomLarge: {
    marginBottom: theme.spacing(7),
  },
  gutterTopMedium: {
    marginTop: theme.spacing(5),
  },
  center: {
    textAlign: 'center',
  },
}));

export default useStyles;
