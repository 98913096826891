import React from 'react';
import { Typography as TypographyBase } from '@material-ui/core';
import classnames from 'classnames';
import Markdown from 'markdown-to-jsx';

import useStyles from './Typography.styles';

const variantMapping = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  subtitle1: 'h6',
  subtitle2: 'h6',
  body1: 'p',
  body2: 'p',
  body3: 'p',
};

const Typography = ({
  children,
  color = 'textPrimary',
  uppercase = false,
  bold = false,
  extraBold = false,
  className = undefined,
  variant = 'body2',
  markdown = false,
  gutterBottom = undefined,
  gutterTop = undefined,
  center = false,
  ...rest
}) => {
  const classes = useStyles();
  const textColor = classes[color];
  const variantClass = classes[variant];
  const compatibleVariant = variantClass ? 'inherit' : variant;

  return (
    <TypographyBase
      classes={{ root: classnames(textColor, variantClass) }}
      className={classnames(className, {
        [classes.uppercase]: uppercase,
        [classes.extraBold]: extraBold,
        [classes.bold]: bold,
        [classes.gutterBottomMedium]: gutterBottom === 'm',
        [classes.gutterBottomLarge]: gutterBottom === 'l',
        [classes.gutterTopMedium]: gutterTop === 'm',
        [classes.center]: center,
      })}
      variant={compatibleVariant}
      variantMapping={variantMapping}
      {...rest}
    >
      {markdown && typeof children === 'string' ? (
        <Markdown
          options={{
            overrides: { a: { props: { className: classes.link } } },
          }}
        >
          {children}
        </Markdown>
      ) : (
        children
      )}
    </TypographyBase>
  );
};

export default Typography;
