import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import capitalize from 'lodash/capitalize';
import Typography from '../../components/Typography/Typography';
import Button from '../../components/Button/Button';
import ArrowIcon from '../../components/ArrowIcon/ArrowIcon';
import strings from '../../i18n/localization';
import useStyles from './AdminQuestionDetail.styles';
import Separator from '../../components/Separator/Separator';
import Facts from './Facts/Facts';
import Answers from './Answers/Answers';
import LanguageBar from './LanguageBar/LanguageBar';
import FeedbackDialogButton from './FeedbackDialogButton/FeedbackDialogButton';
import EditQuestionDialogButton from './EditQuestionDialogButton/EditQuestionDialogButton';
import PreviewDialogButton from './PreviewDialogButton/PreviewDialogButton';
import AdminQuestionContext, {
  AdminQuestionGuard,
  useAdminQuestion,
} from '../../context/AdminQuestionContext/AdminQuestionContext';
import FeedbackStatsContext from '../../context/AdminQuestionFeedbackStatsContext/AdminQuestionFeedbackStatsContext';

const AdminQuestionDetail = () => {
  const [languageTab, setLanguageTab] = useState(strings.getLanguage());
  const languageCase = capitalize(languageTab);

  const classes = useStyles();
  const { questionId } = useParams();
  const history = useHistory();
  const { question } = useAdminQuestion();

  return (
    <>
      <div className={classes.container}>
        <Typography variant="h3" data-cy="question-detail-title">
          #{question.questionId}
        </Typography>
        <Button
          variant="link"
          onClick={() => history.goBack()}
          iconLeft={<ArrowIcon variant="left" className={classes.arrowIcon} />}
        >
          {strings.admin.questionDetail.goBackToOverview}
        </Button>

        <Facts question={question} />

        <LanguageBar onLanguageSelected={lang => setLanguageTab(lang)} selectedLanguage={languageTab} />

        <Separator />

        <Typography variant="h5">{strings.admin.questionDetail.text}</Typography>
        <Typography>{question[`questionText${languageCase}`]}</Typography>

        <Separator />

        <Answers question={question} language={languageCase} />

        <Separator />

        <Typography variant="h5">{strings.admin.questionDetail.explanation}</Typography>
        <Typography>{question[`explanation${languageCase}`]}</Typography>

        <div className={classes.footer}>
          <div className={classes.iconContainer}>
            <FeedbackStatsContext>
              <FeedbackDialogButton questionId={questionId} />
            </FeedbackStatsContext>
            <PreviewDialogButton language={languageTab} />
          </div>
          <EditQuestionDialogButton />
        </div>
      </div>
    </>
  );
};

const AdminQuestionFetcher = () => {
  const { questionId } = useParams();
  const { loadQuestion } = useAdminQuestion(questionId);
  useEffect(() => {
    loadQuestion();
  }, [loadQuestion]);
  return (
    <AdminQuestionGuard>
      <AdminQuestionDetail />
    </AdminQuestionGuard>
  );
};

const AdminQuestionContextWrapper = () => (
  <AdminQuestionContext>
    <AdminQuestionFetcher />
  </AdminQuestionContext>
);

export default AdminQuestionContextWrapper;
