import React from 'react';

import strings from '../../../i18n/localization';
import { useCategoryLabels } from '../../../context/selectors/categories';

import Separator from '../../../components/Separator/Separator';
import useStyles from './Facts.styles';
import Typography from '../../../components/Typography/Typography';

const DataTerm = ({ ...rest }) => <Typography variant="h5" component="dt" {...rest} />;
const DataDefinition = ({ ...rest }) => <Typography component="dd" {...rest} />;

const Facts = ({ question }) => {
  const classes = useStyles();
  const categoryLabels = useCategoryLabels();
  return (
    <>
      <Separator />
      <dl className={classes.container}>
        <div className={classes.item}>
          <DataTerm>{strings.admin.questionDetail.category}</DataTerm>
          <DataDefinition uppercase>{categoryLabels[question.category.categoryId]}</DataDefinition>
        </div>
        <div className={classes.item}>
          <DataTerm>{strings.admin.questionDetail.difficulty}</DataTerm>
          <DataDefinition>{question.difficulty}</DataDefinition>
        </div>
      </dl>
      <Separator />
    </>
  );
};

export default Facts;
