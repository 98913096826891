import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  dropdown: {
    width: '100%',
    marginBottom: theme.spacing(5),
  },
}));

export default useStyles;
