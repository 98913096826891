import React from 'react';
import { Drawer, List } from '@material-ui/core';
import { Link } from 'react-router-dom';

import Avatar from '../../../Avatar/Avatar';
import Typography from '../../../Typography/Typography';
import SideBarLink from './SideBarLink/SideBarLink';
import strings from '../../../../i18n/localization';
import routes, { dashboard } from '../../../../routes';
import { useScoresSum } from '../../../../context/selectors/scores';
import useStyles from './SideBar.styles';
import usePopupControl, { popupKeys } from '../../../../hooks/popup/usePopupControl';
import { ROLES, ENABLE_LOGOUT, CONTACT_ADDRESS, DISABLE_INVITATION } from '../../../../constants';
import { useUser } from '../../../../context/UserContext/UserContext';
import { useIdToken } from '../../../../context/AuthenticationContext/AuthenticationContext';
import LanguagePicker from './LanguagePicker/LanguagePicker';
import AppSwitchLink from './AppSwitchLink/AppSwitchLink';

const SideBar = ({ setIsDrawerOpen, isDrawerOpen }) => {
  const classes = useStyles();
  const { signOut } = useIdToken();
  const scoresSum = useScoresSum();
  const { openPopup } = usePopupControl(popupKeys.RANKING);

  const {
    user: { firstName, lastName, avatar },
  } = useUser();

  const onRankingClick = () => {
    setIsDrawerOpen(false);
    openPopup();
  };

  const closeDrawer = () => setIsDrawerOpen(false);

  const goToSecurityDashboard = () => {
    window.location.href = dashboard.HOME;
    closeDrawer();
  };

  // currently there's only a german version available, this could be easily extended here
  const TENANT_ADMIN_GUIDE = {
    en: '/files/tenant_admin_guide_de.pdf',
    de: '/files/tenant_admin_guide_de.pdf',
    fr: '/files/tenant_admin_guide_de.pdf',
    it: '/files/tenant_admin_guide_de.pdf',
  };

  const bottomMenuItems = [{ title: strings.menu.contact, mail: CONTACT_ADDRESS }];

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={isDrawerOpen}
      onClose={closeDrawer}
      classes={{ paper: classes.drawerPaper, root: classes.root }}
      ModalProps={{ keepMounted: true }}
      data-cy="sidebar"
    >
      <div className={classes.menuWrapper}>
        <div className={classes.menuTop}>
          <Link to={routes.PROFILE} className={classes.user} onClick={closeDrawer}>
            <Avatar name={avatar} size="M" />
            <div className={classes.userDetails}>
              <Typography extraBold>
                {firstName} {lastName}
              </Typography>
              <Typography>{`${scoresSum} ${strings.ranking.score}`}</Typography>
            </div>
          </Link>
          <SideBarLink
            label={strings.menu.dashboard}
            path={routes.OVERVIEW}
            allowedRole={[ROLES.QUIZEE]}
            onClick={closeDrawer}
          />
          <SideBarLink
            label={strings.menu.profile}
            path={routes.PROFILE}
            allowedRole={[ROLES.QUIZEE]}
            onClick={closeDrawer}
            data-cy="show-profile-link"
          />
          <SideBarLink label={strings.ranking.ranking} allowedRole={[ROLES.QUIZEE]} onClick={onRankingClick} />
          <SideBarLink
            label={strings.awards.title}
            allowedRole={[ROLES.QUIZEE]}
            onClick={closeDrawer}
            path={routes.AWARDS}
          />
          <SideBarLink
            label={strings.menu.adminDashboard}
            path={routes.ADMIN_DASHBOARD}
            allowedRole={[ROLES.SECURED_ADMIN]}
            onClick={closeDrawer}
          />
          <SideBarLink
            label={strings.menu.tenantBoard}
            path={routes.ADMIN_TENANT_BOARD}
            allowedRole={[ROLES.TENANT_MANAGER, ROLES.SECURED_ADMIN]}
            onClick={closeDrawer}
          />
          <SideBarLink
            label={strings.menu.questionList}
            path={routes.ADMIN_QUESTIONS}
            allowedRole={[ROLES.SECURED_ADMIN]}
            onClick={closeDrawer}
          />
          {DISABLE_INVITATION ? null : (
            <SideBarLink
              label={strings.menu.inviteUsers}
              allowedRole={[ROLES.TENANT_ADMIN]}
              path={routes.INVITE}
              onClick={closeDrawer}
            />
          )}
          {ENABLE_LOGOUT ? (
            <SideBarLink label={strings.menu.logout} onClick={signOut} allowedRole={[ROLES.QUIZEE]} />
          ) : null}

          <AppSwitchLink
            label={strings.menu.securityDashboard}
            allowedRole={ROLES.TENANT_ADMIN}
            onClick={goToSecurityDashboard}
          />
        </div>

        <List className={classes.infoSection}>
          <LanguagePicker />
          {DISABLE_INVITATION ? null : (
            <div key="Link" className={classes.listItem}>
              <Typography variant="body2" bold>
                {strings.menu.tenantAdminGuide}
              </Typography>
              <Typography variant="link" component="a" href={TENANT_ADMIN_GUIDE.de}>
                {strings.menu.download}
              </Typography>
            </div>
          )}
          {bottomMenuItems.map(item => (
            <div key={item.title} className={classes.listItem}>
              <Typography variant="body2" bold>
                {item.title}
              </Typography>
              <Typography variant="link" component="a" href={`mailto:${item.mail}`}>
                {item.mail}
              </Typography>
            </div>
          ))}
        </List>
      </div>
    </Drawer>
  );
};

export default SideBar;
