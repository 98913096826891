import React, { useState } from 'react';
import classnames from 'classnames';
import useStyles from './AwardBadge.styles';
import { useAwardDescription, useAwardName } from '../../hooks/awards';
import AwardIcon from '../AwardIcon/AwardIcon';
import Typography from '../Typography/Typography';
import FullScreenDialog from '../FullScreenDialog/FullScreenDialog';
import strings from '../../i18n/localization';

const AwardBadge = ({ id, level, obtained = false, clickable = false }) => {
  const classes = useStyles();

  const name = useAwardName(id, level);
  const levelLabelKey = level.toLowerCase();
  const qualifiedName = strings.formatString(strings.awards.awardName, {
    name,
    level: strings.awards.level[levelLabelKey],
  });
  const bronzeDescription = useAwardDescription(id, 'Bronze');
  const silverDescription = useAwardDescription(id, 'Silver');
  const goldDescription = useAwardDescription(id, 'Gold');

  const ParentComponent = clickable ? 'button' : 'div';
  const [showDetails, setShowDetails] = useState(false);

  const openDetails = clickable
    ? () => {
        setShowDetails(true);
      }
    : undefined;

  return (
    <>
      <FullScreenDialog
        fullScreen
        open={showDetails}
        onCloseButton={() => {
          setShowDetails(false);
        }}
        title={qualifiedName}
      >
        <div className={classnames(classes.overlayContainer)}>
          <div className={classnames({ [classes.inactive]: !obtained })}>
            <AwardIcon size="L" level={obtained ? level : 'unclaimed'} award={id} />
          </div>
          <div className={classes.spacer} />
          <Typography>
            <b>Bronze:</b> {bronzeDescription}
          </Typography>
          <div className={classes.spacer} />
          <Typography>
            <b>Silver:</b> {silverDescription}
          </Typography>
          <div className={classes.spacer} />
          <Typography>
            <b>Gold:</b> {goldDescription}
          </Typography>
        </div>
      </FullScreenDialog>
      <ParentComponent className={classnames(classes.root, { [classes.inactive]: !obtained })} onClick={openDetails}>
        <AwardIcon size="S" level={obtained ? level : 'unclaimed'} award={id} />
        <Typography className={classes.badgeCaption} center>
          {name}
        </Typography>
      </ParentComponent>
    </>
  );
};

export default AwardBadge;
