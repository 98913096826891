import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  paginator: {
    justifyContent: 'center',
    padding: '10px',
  },
}));

export default useStyles;
