import React from 'react';

import FeedbackIcon from '../Icons/FeedbackIcon/FeedbackIcon';
import useStyles from './CardFooter.styles';

const CardFooter = ({ onFeedbackClick, children }) => {
  const classes = useStyles();
  return (
    <div className={classes.footer}>
      <div className={classes.flexBorderElement} />
      <div>{children}</div>
      <div className={classes.flexBorderElement}>
        <button onClick={onFeedbackClick} type="button" data-cy="show-feedback">
          <FeedbackIcon />
        </button>
      </div>
    </div>
  );
};

export default CardFooter;
