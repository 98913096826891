import React from 'react';
import classnames from 'classnames';

import strings from '../../../i18n/localization';
import useStyles from './NameTick.styles';

const ellipsis = text => (text.length <= 8 ? text : text.substring(0, 7).concat('…'));

const NameTick = ({ x, y, payload, activeUser }) => {
  const classes = useStyles();
  const isActiveUser = activeUser === payload.value;
  return (
    <text
      x={x}
      y={y}
      textAnchor="middle"
      overflow="scroll"
      dy="8"
      className={classnames(classes.textNode, { [classes.activeUser]: isActiveUser })}
    >
      {isActiveUser ? strings.ranking.you : ellipsis(payload.value)}
    </text>
  );
};

export default NameTick;
