import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  startButton: {
    margin: 'auto',
    position: 'relative',
    width: '60%',
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(8),
  },
  marginBottom: {
    display: 'block',
    marginBottom: theme.spacing(9),
  },
  alignCenter: {
    margin: `${theme.spacing(6)}px auto`,
  },
  backgroundBar: {
    position: 'relative',
    background: `linear-gradient( -22deg, transparent 143px, ${theme.palette.primary.c400} 143px, ${theme.palette.primary.c400} 268px, transparent 268px )`,
  },
  axaLogo: {
    width: 120,
    position: 'absolute',
    right: 40,
  },
}));

export default useStyles;
