import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import Typography from '../../Typography/Typography';
import routes from '../../../routes';
import LogoIcon from '../../LogoIcon/LogoIcon';
import AppBar from './AppBar/AppBar';

import useStyles from './Header.styles';
import SideBar from './SideBar/SideBar';

const Header = ({ isDashboard, isQuestions }) => {
  const classes = useStyles();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const hasSideBar = pathname !== routes.ONBOARDING;

  return (
    <>
      <AppBar isDashboard={isDashboard} isQuestions={isQuestions}>
        <Link to={routes.OVERVIEW} className={classes.logoContainer} data-cy="show-dashboard">
          <LogoIcon size="S" className={classes.logoIcon} />
          <Typography variant="h6" noWrap>
            SecurEd
          </Typography>
        </Link>

        {hasSideBar && (
          <div className={classes.headerNav}>
            <IconButton
              className={classes.menuButton}
              color="inherit"
              aria-label="open drawer"
              edge="end"
              onClick={toggleDrawer}
              data-cy="show-menu"
            >
              <MenuIcon />
            </IconButton>
          </div>
        )}
      </AppBar>
      <SideBar setIsDrawerOpen={setIsDrawerOpen} isDrawerOpen={isDrawerOpen} />
    </>
  );
};

export default Header;
