import React from 'react';
import Button from '../../../components/Button/Button';
import usePopupControl, { popupKeys } from '../../../hooks/popup/usePopupControl';
import strings from '../../../i18n/localization';
import AdminQuestionDialog from '../../../components/AdminQuestionDialog/AdminQuestionDialog';

const EditQuestionDialogButton = () => {
  const { popupIsOpen, openPopup, closePopup } = usePopupControl(popupKeys.ADMIN_EDIT);

  return (
    <>
      <AdminQuestionDialog open={popupIsOpen} onCloseButton={closePopup} />
      <Button variant="contained" onClick={openPopup} data-cy="edit-question">
        {strings.admin.questionDetail.edit}
      </Button>
    </>
  );
};

export default EditQuestionDialogButton;
