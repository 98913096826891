import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    textAlign: 'center',
  },
  iconContainer: {
    width: 100,
    height: 100,
    margin: 'auto',
  },
  icon: {
    marginBottom: '-20px',
  },
  scoreCircle: {
    borderRadius: '90px',
    backgroundColor: theme.palette.text.secondary,
    width: '145px',
    height: '145px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  scoreText: {
    marginTop: theme.spacing(4),
    marginBottom: -theme.spacing(4),
    fontSize: '0.8rem',
  },
  feedback: {
    fontSize: '1.4rem',
    padding: theme.spacing(3),
  },
  pointsEarnedCircleBackground: {
    backgroundColor: props => props.color,
  },
  pointsEarnedCircle: {
    borderColor: theme.palette.text.secondary,
    borderRadius: '35px',
    borderStyle: 'solid',
    borderWidth: '6px',
    width: '65px',
    height: '65px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: '30px',
    left: '30px',
  },
  pointsEarned: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
}));

export default useStyles;
