import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  filter: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    display: 'flex',
    padding: theme.spacing(3),
  },
  verticalAlign: {
    margin: 'auto 0',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dropdown: {
    minWidth: props => props.minDropdownWidth,
    width: '60%',
  },
}));

export default useStyles;
