import { useMemo } from 'react';
import strings from '../../i18n/localization';

const useFeedbackTypeLabels = () => {
  const currentLanguage = strings.getLanguage();

  return useMemo(
    () => ({
      1: strings.questionFeedback.types.misc,
      2: strings.questionFeedback.types.spelling,
      3: strings.questionFeedback.types.tooSimple,
      4: strings.questionFeedback.types.tooDifficult,
    }),
    // strings will automatically update based on selected language
    // therefore upon language change the labels must be re-calculated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );
};

export { useFeedbackTypeLabels };
