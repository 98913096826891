import { makeStyles } from '@material-ui/core/styles';

// Make sure the logo container and headerNav have the same width
// This ensures proper alignment of a potential element in the middle.
const headerItemsWidth = 90;

const useStyles = makeStyles(theme => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: headerItemsWidth,
    backgroundColor: 'transparent ',
  },
  logoIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(4),
  },
  buttonSpace: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '60px',
  },
  checkBoxSpace: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  logoSpace: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: '60px',
    marginBottom: '40px',
  },
  axaLogo: {
    width: 145,
    height: 50,
    marginTop: '10px',
  },
  oneConsultLogo: {
    width: 130,
    height: 25,
    marginTop: '40px',
  },
  zuehlkeLogo: {
    width: 60,
    marginTop: '10px',
  },
  verticalSpacing: {
    marginTop: '40px',
  },
  verticalSpacingDescriptionText: {
    marginTop: '20px',
  },
  dividerSpace: {
    marginTop: '60px',
  },
  buttonFixedSize: {
    minHeight: '56px',
    width: '30%',
  },
}));

export default useStyles;
