import React from 'react';

import CardContent from '@material-ui/core/CardContent';
import { Divider, Typography } from '@material-ui/core';
import useStyles from '../TenantWidget.styles';
import { InviteTenantAdmin } from './InviteTenantAdmin';

const InvitedTenantAdmins = ({ invitedTenantAdmins }) => {
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.divider} />
      <Typography>
        <span className={classes.tenantAdmins}>
          <b>Invited</b>
        </span>
      </Typography>
      {invitedTenantAdmins.invitedAdmins.map(invitedAdmin => (
        <div key={invitedAdmin.emailAddress}>
          <CardContent className={classes.tenantInfo}>
            <Typography className={classes.infoContent}>
              {invitedAdmin.firstName && (
                <span>
                  <b>First Name: </b> <span>{`${invitedAdmin.firstName} `}</span>
                </span>
              )}
            </Typography>
            <Typography className={classes.infoContent}>
              {invitedAdmin.lastName && (
                <span>
                  <b>Last Name: </b> <span>{invitedAdmin.lastName}</span>
                </span>
              )}
            </Typography>
            <Typography className={classes.infoContent} component="div">
              {invitedAdmin.emailAddress && (
                <span>
                  <b>Email: </b> <span>{invitedAdmin.emailAddress}</span>
                </span>
              )}
              {invitedAdmin.emailAddress && (
                <InviteTenantAdmin userTenantAdmin={invitedAdmin} tenant={invitedTenantAdmins} />
              )}
            </Typography>

            <Typography className={classes.infoContent}>
              {invitedAdmin.lastInvitationMail && (
                <span>
                  <b>Last invitation Mail:</b>{' '}
                  <span>{new Date(invitedAdmin.lastInvitationMail).toLocaleDateString()}</span>
                </span>
              )}
            </Typography>
          </CardContent>
        </div>
      ))}
    </>
  );
};

export default InvitedTenantAdmins;
