import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  hidden: {
    display: 'none',
  },
  form: {
    padding: theme.spacing(0, 7, 7),
  },
  spacing: {
    marginBottom: theme.spacing(5),
  },
  addAnswerButton: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(8),
  },
}));

export default useStyles;
