import { useState } from 'react';
import { API_URL, ENDPOINTS } from '../constants';

import { useIdToken } from '../../../context/AuthenticationContext/AuthenticationContext';
import { useUser } from '../../../context/UserContext/UserContext';

// Inspired by https://blog.jayway.com/2017/07/13/open-pdf-downloaded-api-javascript/
const downloadPdf = (filename, blob) => {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([blob], { type: 'application/pdf' });

  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = filename;
  link.click();
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
  }, 100);
};

const useChecklist = filename => {
  const [isPending, setIsPending] = useState(false);
  const { idToken } = useIdToken();
  const {
    user: { language },
  } = useUser();

  const downloadChecklist = () => {
    setIsPending(true);
    fetch(`${API_URL}${ENDPOINTS.CHECKLIST_PREFIX}_${language.toUpperCase()}.pdf`, {
      headers: {
        Authorization: `Bearer ${idToken}`,
      },
    })
      .then(r => r.blob())
      .then(blob => {
        downloadPdf(filename, blob);
        setIsPending(false);
      });
  };

  return { pending: isPending, downloadChecklist };
};

export default useChecklist;
