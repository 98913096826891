import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  pageTitleContainer: {
    marginBottom: theme.spacing(7),
    marginTop: theme.spacing(7),
  },
}));

export default useStyles;
