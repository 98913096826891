import React from 'react';
import useStyles from './PageTitle.styles';
import Typography from '../Typography/Typography';

const PageTitle = ({ title, subTitle = null, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.pageTitleContainer} {...rest}>
      <Typography variant="h3">{title}</Typography>
      {subTitle ? (
        <Typography variant="h4" className={classes.subTitle}>
          {subTitle}
        </Typography>
      ) : null}
    </div>
  );
};

export default PageTitle;
