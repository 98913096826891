import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  fullscreen: {
    height: '100vh',
    width: '100vw',
    position: 'fixed',
    top: 0,
    left: 0,
    background: theme.palette.background.default,
    zIndex: theme.layers.fullScreenLoader,
  },
}));

export default useStyles;
