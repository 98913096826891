import { useCallback } from 'react';
import strings from '../i18n/localization';
import { useAddMessage } from '../context/MessageContext/MessageContext';

const useUnhandledDataLoad = (fetch, fetchConfig, dispatch, loaded, failed, pending) => {
  const configSerialized = JSON.stringify(fetchConfig);
  return useCallback(
    async (overwriteConfig = {}) => {
      try {
        if (pending) dispatch(pending());
        const combinedConfig = {
          ...fetchConfig,
          ...overwriteConfig,
        };
        const response = await fetch(combinedConfig);
        if (loaded) dispatch(loaded(response.data));
        return {
          response,
          success: true,
        };
      } catch (error) {
        if (failed) dispatch(failed(error));
        return {
          response: error.response,
          error,
          success: false,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetch, configSerialized, dispatch, loaded, failed, pending],
  );
};

const useDataLoad = (...args) => {
  const unhandledDataLoad = useUnhandledDataLoad(...args);
  const { addErrorMessage } = useAddMessage();

  return useCallback(
    async (...config) => {
      const returnValue = await unhandledDataLoad(...config);
      const { success, error } = returnValue;
      if (!success) {
        addErrorMessage(error?.response?.data?.reason || strings.forms.submitError);
      }
      return returnValue;
    },
    [unhandledDataLoad, addErrorMessage],
  );
};

const useQuestionDataLoad = (...args) => {
  const unhandledDataLoad = useUnhandledDataLoad(...args);

  return useCallback(async (...config) => unhandledDataLoad(...config), [unhandledDataLoad]);
};

export default useDataLoad;

export { useUnhandledDataLoad, useQuestionDataLoad };
