import { Circ, gsap } from 'gsap';
import { useCallback } from 'react';

const animateScoreCallback = ($container, submission, classes) => {
  let earnedCounter = 0;
  let overallCounter = 0;

  const updateCounter = (updatedValues, earned, overall) => {
    const earnedRounded = Math.ceil(updatedValues.earned);
    if (earnedRounded !== earnedCounter) {
      earnedCounter = earnedRounded;
      // eslint-disable-next-line no-param-reassign
      earned.innerHTML = `+${earnedCounter}`;
    }

    const overallRounded = Math.ceil(updatedValues.overall);
    if (overallRounded !== overallCounter) {
      overallCounter = overallRounded;
      // eslint-disable-next-line no-param-reassign
      overall.innerHTML = overallCounter;
    }
  };

  const degree = 45;
  const ease = 'elastic.out(1.2, 0.3)';
  const duration = 1;

  // rotate points circle
  gsap.fromTo(`.${classes.pointsEarnedCircle}`, { opacity: 0 }, { x: -70, rotation: 0, opacity: 1, duration, ease });
  gsap.fromTo(`.${classes.scoreCircle}`, { scale: 0.5 }, { scale: 1, rotation: degree, duration, ease });

  // counter rotate numbers to appear still
  gsap.to('.scoreInnerTag', 1, { rotation: -degree, duration, ease });
  gsap.to(`.${classes.pointsEarned}`, { rotation: -degree, duration: 1, ease });

  // let icon and rank appear/ wobble
  gsap.fromTo(`.${classes.icon}`, { y: 120 }, { scale: 2, y: 40, duration, ease });
  gsap.from('.subtitle', { scale: 0.8, duration, ease, delay: 0 });

  const earned = $container.querySelector('.earned');
  const overall = $container.querySelector('.overall');
  const currentNum = { earned: 0, overall: submission.score - submission.pointsEarned };
  earned.innerHTML = '+0';
  overall.innerHTML = currentNum.overall;
  // count up
  gsap.to(currentNum, 1.5, {
    earned: submission.pointsEarned,
    overall: submission.score,
    onUpdate: () => updateCounter(currentNum, earned, overall),
    ease: Circ.easeOut,
    delay: 0.8,
  });
};

const useAnimateScore = () => {
  const animateScore = useCallback(animateScoreCallback, []);

  return {
    animateScore,
  };
};

export { useAnimateScore };
