import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS, PUT } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';
import { useUpdateFeedbackStats } from '../AdminQuestionFeedbackStatsContext/AdminQuestionFeedbackStatsContext';

const initialContext = {
  list: {
    data: {},
    pending: true,
    error: null,
  },
  status: {
    data: null,
    pending: false,
    error: null,
  },
};

const FEEDBACK_LIST_LOADED = 'FEEDBACK_LIST_LOADED';
const FEEDBACK_LIST_FAILED = 'FEEDBACK_LIST_FAILED';
const FEEDBACK_LIST_PENDING = 'FEEDBACK_LIST_PENDING';
const FEEDBACK_STATUS_LOADED = 'FEEDBACK_STATUS_LOADED';
const FEEDBACK_STATUS_FAILED = 'FEEDBACK_STATUS_FAILED';
const FEEDBACK_STATUS_PENDING = 'FEEDBACK_STATUS_PENDING';

const feedbackLoaded = createAction(FEEDBACK_LIST_LOADED);
const feedbackFailed = createAction(FEEDBACK_LIST_FAILED);
const feedbackPending = createAction(FEEDBACK_LIST_PENDING);
const feedbackStatusLoaded = createAction(FEEDBACK_STATUS_LOADED);
const feedbackStatusFailed = createAction(FEEDBACK_STATUS_FAILED);
const feedbackStatusPending = createAction(FEEDBACK_STATUS_PENDING);

const reducer = produce((draft, action) => {
  let feedbackInstance;
  switch (action.type) {
    case FEEDBACK_LIST_LOADED:
      draft.list = succeed(action.payload);
      break;
    case FEEDBACK_LIST_FAILED:
      draft.list = fail(action.payload);
      break;
    case FEEDBACK_LIST_PENDING:
      draft.list.pending = true;
      break;
    case FEEDBACK_STATUS_LOADED:
      feedbackInstance = draft.list.data.find(feedback => feedback.id === action.payload.feedbackId);
      feedbackInstance.resolved = true;
      draft.status = succeed(action.payload);
      break;
    case FEEDBACK_STATUS_FAILED:
      draft.status = fail(action.payload);
      break;
    case FEEDBACK_STATUS_PENDING:
      draft.status.pending = true;
      break;
    default:
      break;
  }
});

const [FeedbackListContext, useFeedbackContext] = createContext(reducer, initialContext);

const feedbackCoordinator = promiseCoordinator();
const useFeedbackListDataContext = () => useFeedbackContext().list;
const useFeedbackStatusDataContext = () => useFeedbackContext().status;
const FeedbackListGuard = createGuard(useFeedbackListDataContext);

const useFeedbackList = questionId => {
  const { dispatch } = useFeedbackContext();
  const { data: feedbackList } = useFeedbackListDataContext();

  const fetch = useFetch(feedbackCoordinator);

  const loadFeedbackList = useDataLoad(
    fetch,
    {
      url: `${ENDPOINTS.ADMIN_QUESTIONS}/${questionId}/feedback`,
    },
    dispatch,
    feedbackLoaded,
    feedbackFailed,
    feedbackPending,
  );

  return {
    feedbackList,
    loadFeedbackList,
  };
};

const useFeedbackResolve = () => {
  const { dispatch } = useFeedbackContext();
  const { pending } = useFeedbackStatusDataContext();
  const { decrementPendingFeedback } = useUpdateFeedbackStats();

  const fetch = useFetch(feedbackCoordinator);

  const resolveFeedbackRequest = useDataLoad(
    fetch,
    {
      method: PUT,
      data: {
        resolved: true,
      },
    },
    dispatch,
    feedbackStatusLoaded,
    feedbackStatusFailed,
    feedbackStatusPending,
  );
  const resolveFeedback = async feedbackId => {
    const { success } = await resolveFeedbackRequest({
      url: `${ENDPOINTS.ADMIN_FEEDBACK}/${feedbackId}/status`,
    });
    if (success) {
      decrementPendingFeedback();
    }
  };
  return {
    pending,
    resolveFeedback,
  };
};

export default FeedbackListContext;
export { useFeedbackList, useFeedbackResolve, FeedbackListGuard };
