import React from 'react';
import { useForm } from 'react-hook-form';

import Fieldset from '../../components/FormElements/Fieldset/Fieldset';
import InputField from '../../components/FormElements/InputField/InputField';
import strings from '../../i18n/localization';
import Button from '../../components/Button/Button';
import { useUser } from '../../context/UserContext/UserContext';

const FIRST_NAME = 'firstName';
const LAST_NAME = 'lastName';
const NICK_NAME = 'nickName';

const UserProfileForm = ({ onSubmit, loading }) => {
  const {
    user: { firstName, lastName, nickName },
  } = useUser();
  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      [FIRST_NAME]: firstName,
      [LAST_NAME]: lastName,
      [NICK_NAME]: nickName,
    },
  });

  return (
    <form action="post" onSubmit={handleSubmit(onSubmit)}>
      <Fieldset disabled={loading} aria-busy={loading}>
        <InputField
          name={FIRST_NAME}
          placeholder={strings.forms[FIRST_NAME]}
          error={errors[FIRST_NAME]}
          type="text"
          ref={register({ required: strings.forms.firstNameRequired })}
        >
          {strings.forms.firstName}
        </InputField>
        <InputField
          name={LAST_NAME}
          placeholder={strings.forms[LAST_NAME]}
          error={errors[LAST_NAME]}
          type="text"
          ref={register({ required: strings.forms.lastNameRequired })}
        >
          {strings.forms.lastName}
        </InputField>
        <InputField
          name={NICK_NAME}
          placeholder={strings.forms[NICK_NAME]}
          error={errors[NICK_NAME]}
          type="text"
          ref={register({
            maxLength: { value: 12, message: strings.forms.nickNameMaxLength },
            pattern: { value: /^[A-Za-z\d_-]+$/i, message: strings.forms.nickNameLimitChars },
            required: strings.forms.nickNameRequired,
          })}
        >
          {strings.forms.nickName}
        </InputField>
        <Button variant="contained" color="primary" size="large" type="submit" data-cy="button-submit-profile">
          {strings.forms.save}
        </Button>
      </Fieldset>
    </form>
  );
};

export default UserProfileForm;
