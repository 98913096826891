import React, { useEffect, useState } from 'react';
import gsap, { Circ } from 'gsap';
import classnames from 'classnames';
import { useQuestion } from '../../../context/QuestionContext/QuestionContext';

const QuestionCardAnimation = ({ children, className }) => {
  const { pending } = useQuestion();
  const [pendingAnim, setPendingAnim] = useState(null);

  useEffect(() => {
    if (!pending) {
      const cardAnim = gsap.fromTo(
        '.card-anim',
        {
          y: window.innerHeight,
          rotation: 0,
          x: 0,
          opacity: 1,
        },
        {
          y: 0,
          x: 0,
          duration: 0.3,
          opacity: 1,
          rotation: 0,
          paused: true,
        },
        Circ.easeOut,
      );

      if (pendingAnim) {
        pendingAnim.eventCallback('onComplete', () => {
          cardAnim.paused(false);
        });
      } else {
        cardAnim.paused(false);
      }
    }
  }, [pending, pendingAnim]);

  useEffect(() => {
    if (pending) {
      setPendingAnim(
        gsap.fromTo(
          '.card-anim',
          {
            x: 0,
            y: 0,
          },
          {
            x: window.innerWidth + 100,
            duration: 0.3,
            rotation: 15,
            opacity: 0.5,
            onComplete: () => {
              setPendingAnim(null);
            },
          },
          Circ.easeIn,
        ),
      );
    }
  }, [pending]);

  return <div className={classnames('card-anim', className)}>{children}</div>;
};

export default QuestionCardAnimation;
