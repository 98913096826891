import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  divider: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(0),
    height: 1,
    backgroundColor: theme.palette.grey.c300,
  },
  widget: {
    marginTop: 5,
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoContent: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 12,
  },
  cardStyle: {
    paddingTop: 16,
    paddingRight: 16,
    paddingLeft: 16,
    paddingBottom: 0,
  },
  expander: {
    padding: 0,
    justifyContent: 'center',
  },
  tenantTitle: {
    fontWeight: 'bold',
    color: 'green',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  tenantDisabled: {
    fontWeight: 'bold',
    color: 'red',
    textDecoration: 'line-through',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  disable: {
    color: 'orange',
  },
  disabled: {
    color: '#dddddd',
  },
  disableRed: {
    color: 'red',
  },
  enable: {
    color: 'green',
  },
  delete: {
    color: 'red',
  },
  tenantInfo: {
    fontSize: 12,
  },
  card: {
    paddingBottom: 10,
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  cancelButton: {
    color: '#000000',
    backgroundColor: '#ffffff',
  },
  deleteButton: {
    color: '#ffffff',
    backgroundColor: '#f44336',
  },
  tenantAdmins: {
    marginLeft: '15px',
  },
}));

export default useStyles;
