import React from 'react';
import { ReactComponent as Icon } from './assets/ArrowIcon.svg';

const ArrowIcon = ({ size, variant = 'right', ...rest }) => {
  const direction = variant === 'left' ? 180 : 0;
  const style = { transform: `rotate(${direction}deg)` };
  return <Icon {...rest} width="20px" height="20px" style={style} />;
};

export default ArrowIcon;
