import React from 'react';
import useStyles from './PendingFeedbackBadge.styles';
import FeedbackIcon from '../Icons/FeedbackIcon/FeedbackIcon';
import Typography from '../Typography/Typography';

const PendingFeedbackBadge = ({ number }) => {
  const classes = useStyles();

  return (
    <div className={classes.iconContainer}>
      <FeedbackIcon />
      <div className={classes.numberCircle}>
        <Typography className={classes.number} bold data-cy="pending-feedback-count">
          {number}
        </Typography>
      </div>
    </div>
  );
};

export default PendingFeedbackBadge;
