import React, { forwardRef } from 'react';
import classnames from 'classnames';

import useStyles from './InputField.styles';
import Typography from '../../Typography/Typography';

const InputField = forwardRef(({ type, children, name, placeholder, error, light = false, ...rest }, ref) => {
  const classes = useStyles();
  return (
    <div className={classes.inputField}>
      <Typography variant="h5" component="label" htmlFor={name} className={classes.label}>
        {children}
        <input
          ref={ref}
          className={classnames(classes.input, { [classes.light]: light })}
          type={type}
          name={name}
          placeholder={placeholder}
          {...rest}
        />
      </Typography>
      {error && (
        <Typography color="error" className={classes.error}>
          {error.message}
        </Typography>
      )}
    </div>
  );
});

export default InputField;
