import React, { useContext, useMemo, useReducer } from 'react';

const defaultContext = {
  data: null,
  error: null,
  pending: true,
};

const createContext = (reducer, initial = defaultContext) => {
  const ContextComponent = React.createContext();
  const useAppContext = () => useContext(ContextComponent);

  const Component = ({ children, staticData = undefined, additionalContextData = {} }) => {
    const [contextData, dispatch] = useReducer(reducer, initial);

    const context = useMemo(() => ({ ...contextData, ...additionalContextData, dispatch }), [
      contextData,
      additionalContextData,
    ]);

    return (
      <ContextComponent.Provider value={staticData === undefined ? context : staticData}>
        {children}
      </ContextComponent.Provider>
    );
  };

  return [Component, useAppContext];
};

export default createContext;
