import React from 'react';
import { useTheme } from '@material-ui/core';

import useStyles from './CategoryTile.styles';
import Button from '../Button/Button';
import Typography from '../Typography/Typography';
import { useCategoryColors } from '../../context/selectors/categories';

const CategoryTile = ({ title, category, icon, textColor = 'textPrimary', ...rest }) => {
  const classes = useStyles();
  const { palette } = useTheme();
  const categoryColors = useCategoryColors();
  const color = category === undefined ? palette.primary.main : categoryColors[category];
  const { disabled } = rest;

  return (
    <Button
      {...rest}
      classes={{ root: classes.button, label: classes.label }}
      style={{ backgroundColor: color }}
      border="0"
    >
      <div className={classes.icon}>{icon}</div>
      <Typography color={textColor} variant="h5" align="center">
        {title}
      </Typography>
      {disabled ? <div className={classes.disabledOverlay} /> : null}
    </Button>
  );
};

export default CategoryTile;
