import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import strings from '../../../i18n/localization';
import CutoutButton from '../../../components/CutoutButton/CutoutButton';
import { useQuestion, useQuestionSubmission } from '../../../context/QuestionContext/QuestionContext';
import useStyles from './DrawNewCard.styles';

const DrawNewCard = () => {
  const classes = useStyles();
  const { category } = useParams();
  const { loadQuestion, pending } = useQuestion(category);
  const { submission } = useQuestionSubmission();

  useEffect(() => {
    loadQuestion();
  }, [loadQuestion]);

  const onClick = () => {
    loadQuestion();
  };

  return (
    <div className={classes.buttonContainer}>
      <CutoutButton disabled={pending || isEmpty(submission)} onClick={onClick} data-cy="draw-new-card">
        {strings.card.newCard}
      </CutoutButton>
    </div>
  );
};

export default DrawNewCard;
