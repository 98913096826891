import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing(4)}px 0`,
    flexShrink: 0,
    flexGrow: 0,
  },
  flexBorderElement: {
    flexBasis: 40,
    flexGrow: '1',
    paddingLeft: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backfaceVisibility: 'hidden',
  },
}));

export default useStyles;
