import React from 'react';
import { Container } from '@material-ui/core';
import ReactionIcon from '../ReactionIcon/ReactionIcon';
import Typography from '../../../../components/Typography/Typography';
import strings from '../../../../i18n/localization';
import useStyles from './FeedbackContentNegative.styles';

const FeedbackContentNegative = () => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <ReactionIcon icon="negative" size="L" className={classes.icon} />
      <Typography variant="h1" color="error" data-cy="title-negative">
        {strings.feedback.ohno}
      </Typography>
      <Typography variant="h3" color="error" className={classes.description}>
        {strings.feedback.incorrect}
      </Typography>
    </Container>
  );
};

export default FeedbackContentNegative;
