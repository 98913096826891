import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';

const FEEDBACK_LOADED = 'FEEDBACK_LOADED';
const FEEDBACK_FAILED = 'FEEDBACK_FAILED';
const FEEDBACK_PENDING = 'FEEDBACK_PENDING';
const FEEDBACK_DECREMENT = 'FEEDBACK_DECREMENT';

const feedbackLoaded = createAction(FEEDBACK_LOADED);
const feedbackFailed = createAction(FEEDBACK_FAILED);
const feedbackPending = createAction(FEEDBACK_PENDING);
const feedbackDecrement = createAction(FEEDBACK_DECREMENT);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case FEEDBACK_LOADED:
      return succeed(action.payload);
    case FEEDBACK_FAILED:
      return fail(action.payload);
    case FEEDBACK_PENDING:
      draft.pending = true;
      break;
    case FEEDBACK_DECREMENT:
      draft.data.pendingFeedbackCount -= 1;
      break;
    default:
      break;
  }
});

const [FeedbackStatsContext, useFeedbackStatsContext] = createContext(reducer);

const feedbackCoordinator = promiseCoordinator();
const FeedbackStatsGuard = createGuard(useFeedbackStatsContext);

const useFeedbackStats = id => {
  const { data: questionFeedbackStats, dispatch } = useFeedbackStatsContext();

  const fetch = useFetch(feedbackCoordinator);

  const loadFeedbackStats = useDataLoad(
    fetch,
    {
      url: `${ENDPOINTS.ADMIN_QUESTIONS}/${id}/feedback/stats`,
    },
    dispatch,
    feedbackLoaded,
    feedbackFailed,
    feedbackPending,
  );

  return {
    questionFeedbackStats,
    loadFeedbackStats,
  };
};

const useUpdateFeedbackStats = () => {
  const { dispatch } = useFeedbackStatsContext();

  return {
    decrementPendingFeedback: () => {
      dispatch(feedbackDecrement());
    },
  };
};

export default FeedbackStatsContext;
export { useFeedbackStats, useUpdateFeedbackStats, FeedbackStatsGuard };
