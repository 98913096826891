import { makeStyles } from '@material-ui/styles';

const heightBackgroundBar = 700;

const useStyles = makeStyles(theme => ({
  backgroundBarContainer: {
    ...theme.fixedWidth,
    height: heightBackgroundBar,
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
  },
  backgroundBarCutout: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: `linear-gradient( -22deg, ${theme.palette.background.default} ${heightBackgroundBar *
      0.6}px, transparent ${heightBackgroundBar * 0.6}px, transparent ${heightBackgroundBar * 0.969}px, ${
      theme.palette.background.default
    } ${heightBackgroundBar * 0.969}px)`,
    transition: '1s ease',
  },
  backgroundBarColor: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: props => props.color,
    transition: '1s ease',
  },
}));

export default useStyles;
