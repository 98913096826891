import { makeStyles } from '@material-ui/core/styles';
import { darken, lighten } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    ...theme.typography.body2,
    borderRadius: theme.shape.borderRadius,
    display: 'flex',
    padding: '6px 16px',
    color: darken(theme.palette.info.main, 0.6),
    backgroundColor: lighten(theme.palette.info.main, 0.8),
  },
  icon: {
    marginRight: 12,
    padding: '7px 0',
    display: 'flex',
    fontSize: 22,
    opacity: 0.9,
    color: theme.palette.info.main,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
    marginTop: -2,
  },
  message: {
    padding: '8px 0',
  },
  action: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    paddingLeft: 16,
    marginRight: -8,
  },
}));

export default useStyles;
