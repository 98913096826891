import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS, POST, PUT } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad, { useUnhandledDataLoad } from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';
import strings from '../../i18n/localization';
import { useAddMessage } from '../MessageContext/MessageContext';

const initialContext = {
  data: {},
  pending: true,
  error: null,
  mutation: {
    pending: false,
    error: null,
  },
};

const QUESTION_LOADED = 'QUESTION_LOADED';
const QUESTION_FAILED = 'QUESTION_FAILED';
const QUESTION_PENDING = 'QUESTION_PENDING';
// This action prevents QuestionGuard from interfering with form
const QUESTION_MUTATION_PENDING = 'QUESTION_MUTATION_PENDING';
const QUESTION_MUTATION_FAILED = 'QUESTION_MUTATION_FAILED';

const questionLoaded = createAction(QUESTION_LOADED);
const questionFailed = createAction(QUESTION_FAILED);
const questionPending = createAction(QUESTION_PENDING);
const questionMutationPending = createAction(QUESTION_MUTATION_PENDING);
const questionMutationFailed = createAction(QUESTION_MUTATION_FAILED);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case QUESTION_LOADED:
      return {
        ...succeed(action.payload),
        mutation: {
          pending: false,
          error: null,
        },
      };
    case QUESTION_FAILED:
      return {
        ...fail(action.payload),
        mutation: {
          pending: false,
          error: null,
        },
      };
    case QUESTION_PENDING:
      draft.pending = true;
      break;
    case QUESTION_MUTATION_PENDING:
      draft.mutation.pending = true;
      break;
    case QUESTION_MUTATION_FAILED:
      draft.mutation = fail(action.payload);
      break;
    default:
      break;
  }
});

const [AdminQuestionContext, useQuestionContext] = createContext(reducer, initialContext);

const questionCoordinator = promiseCoordinator();
const AdminQuestionGuard = createGuard(useQuestionContext);

const useAdminQuestion = id => {
  const { data: question, dispatch } = useQuestionContext();

  const fetch = useFetch(questionCoordinator);

  const loadQuestion = useDataLoad(
    fetch,
    {
      url: `${ENDPOINTS.ADMIN_QUESTIONS}/${id}`,
    },
    dispatch,
    questionLoaded,
    questionFailed,
    questionPending,
  );

  return {
    question,
    loadQuestion,
  };
};

const useAdminQuestionMutation = ({ isCreate }) => {
  const { addSuccessMessage, addErrorMessage } = useAddMessage();
  const { dispatch } = useQuestionContext();
  const { data: question } = useQuestionContext();

  const fetch = useFetch(questionCoordinator);

  let config;
  if (isCreate) {
    config = {
      method: POST,
      url: `${ENDPOINTS.ADMIN_QUESTIONS}`,
    };
  } else {
    config = {
      method: PUT,
      url: `${ENDPOINTS.ADMIN_QUESTIONS}/${question.questionId}`,
    };
  }

  const updateQuestionRequest = useUnhandledDataLoad(
    fetch,
    config,
    dispatch,
    questionLoaded,
    questionMutationFailed,
    questionMutationPending,
  );

  const updateQuestion = async ({ data }) => {
    const { success, response, error } = await updateQuestionRequest({
      data,
    });
    if (success) {
      addSuccessMessage(strings.forms.submitSuccess);
    }
    if (!success) {
      addErrorMessage(error?.response?.data?.reason || strings.misc.unknownServerError);
    }
    return { success, response, error };
  };

  return {
    question,
    updateQuestion,
  };
};

export default AdminQuestionContext;
export { useAdminQuestion, useAdminQuestionMutation, AdminQuestionGuard };
