import { ENDPOINTS } from '../constants';
import useAxios from '../../useAxios';
import promiseCoordinator from '../../../service/promiseCoordinator';

const coordinator = promiseCoordinator();

const useCsvExportTenantBoard = () => {
  const config = {
    url: ENDPOINTS.TENANTS_EXPORT,
  };

  const [{ data, pending, error }, exportCsv] = useAxios(coordinator, config);

  return { data, pending, error, exportCsv };
};

export default useCsvExportTenantBoard;
