import React, { useEffect, useState } from 'react';
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core';
import useStyles from './EmailTableFormatter.styles';
import strings from '../../../i18n/localization';
import { parse, parsedAdressesToJson } from './addressParser/addressParser';

const EmailTableFormatter = ({ input, maxEntries, onAddressUpdate }) => {
  const classes = useStyles();
  const [parsedAddresses, setParsedAddresses] = useState([]);

  useEffect(() => {
    let parsedInput = parse(input);
    if (maxEntries) parsedInput = parsedInput.slice(0, maxEntries);
    setParsedAddresses(parsedInput);
  }, [input, maxEntries]);

  useEffect(() => {
    onAddressUpdate(parsedAdressesToJson(parsedAddresses));
  }, [parsedAddresses, onAddressUpdate]);

  if (parsedAddresses.length < 1) return null;

  return (
    <Paper className={classes.paper}>
      <Table size="small" id="address-table">
        <TableHead>
          <TableRow>
            <TableCell align="right">{strings.invite.name}</TableCell>
            <TableCell align="left">{strings.invite.address}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parsedAddresses.map(row => (
            <TableRow key={row.address}>
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="left">{row.address}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
};

export default EmailTableFormatter;
