import React, { useState } from 'react';
import { Container } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import strings from '../../i18n/localization';
import useStyles from './UserProfile.styles';
import Avatar from '../../components/Avatar/Avatar';
import AvatarDialog from './AvatarDialog/AvatarDialog';
import PageTitle from '../../components/PageTitle/PageTitle';
import Button from '../../components/Button/Button';
import UserProfileForm from './UserProfileForm';
import routes from '../../routes';
import usePopupControl, { popupKeys } from '../../hooks/popup/usePopupControl';
import { useUser } from '../../context/UserContext/UserContext';

const UserProfile = () => {
  const classes = useStyles();
  const {
    user: { avatar: userAvatar, language },
    updateUser,
    pending,
  } = useUser();

  const { pathname } = useLocation();
  const [selectedAvatar, setSelectedAvatar] = useState(userAvatar || 'Avatar1');
  const { popupIsOpen, openPopup, closePopup } = usePopupControl(popupKeys.AVATARS);

  const isOnboarding = pathname === routes.ONBOARDING;

  const handleAvatarSelection = avatarId => {
    setSelectedAvatar(avatarId);
    closePopup();
  };

  const onSubmit = formData => {
    updateUser({ avatar: selectedAvatar, ...formData, language });
  };

  return (
    <Container maxWidth="sm" className={classes.root}>
      <PageTitle title={isOnboarding ? strings.userProfile.titleOnboarding : strings.userProfile.title} />
      <div
        role="button"
        className={classes.avatar}
        onClick={openPopup}
        onKeyDown={openPopup}
        tabIndex={0}
        data-cy="show-avatars"
      >
        <Avatar name={selectedAvatar} className={classes.avatarImage} data-cy={`profile-${selectedAvatar}`} />
        <Button variant="link">{strings.forms.change}</Button>
      </div>
      <UserProfileForm onSubmit={onSubmit} loading={pending} />
      <AvatarDialog open={popupIsOpen} onAvatarSelected={handleAvatarSelection} onCloseButton={closePopup} />
    </Container>
  );
};

export default UserProfile;
