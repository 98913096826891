import React from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';

const initialContext = {
  data: [],
  pending: true,
  error: null,
};

const SCORE_LOADED = 'SCORE_LOADED';
const SCORE_ERROR = 'SCORE_ERROR';
const SCORE_PENDING = 'SCORE_PENDING';

const scoreLoadedAction = createAction(SCORE_LOADED);
const scoreFailedAction = createAction(SCORE_ERROR);
const scorePendingAction = createAction(SCORE_PENDING);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case SCORE_ERROR:
      return fail(action.payload);
    case SCORE_PENDING:
      draft.pending = true;
      break;
    case SCORE_LOADED:
      return succeed(action.payload);
    default:
      break;
  }
});

const [ScoreContextProvider, useScoreContext] = createContext(reducer, initialContext);

const ScoreContext = props => <ScoreContextProvider {...props} />;
const scoreCoordinator = promiseCoordinator();
const ScoreGuard = createGuard(useScoreContext);

const useScores = () => {
  const { data: scores, dispatch } = useScoreContext();
  const fetch = useFetch(scoreCoordinator);

  const loadScores = useDataLoad(
    fetch,
    {
      url: ENDPOINTS.SCORE,
    },
    dispatch,
    scoreLoadedAction,
    scoreFailedAction,
    scorePendingAction,
  );

  return {
    scores,
    loadScores,
  };
};

export default ScoreContext;
export { useScores, ScoreGuard };
