import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  overlayContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  inactive: {
    opacity: 0.2,
  },
  spacer: {
    padding: theme.spacing(5, 0),
  },
  badgeCaption: {
    maxWidth: 80,
  },
}));

export default useStyles;
