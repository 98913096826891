import React, { forwardRef } from 'react';
import { Dialog, Slide } from '@material-ui/core';
import FeedbackContentPositive from '../../../../components/FeedbackContentPositive/FeedbackContentPositive';
import FeedbackContentNegative from '../FeedbackContentNegative/FeedbackContentNegative';
import Button from '../../../../components/Button/Button';
import strings from '../../../../i18n/localization';
import Typography from '../../../../components/Typography/Typography';
import useStyles from './ResponseStateOverlay.style';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const ResponseStateOverlay = forwardRef(({ submission, isOpen, isCorrect, onClose }, ref) => {
  const classes = useStyles();
  const backgroundClass = isCorrect ? classes.correct : classes.incorrect;
  const buttonColor = isCorrect ? 'primary' : 'secondary';
  const footerColor = isCorrect ? 'textPrimary' : 'textSecondary';
  return (
    <Dialog
      ref={ref}
      open={isOpen}
      fullScreen
      classes={{ root: classes.root, paper: `${classes.shape} ${backgroundClass}` }}
      onClose={onClose}
      TransitionComponent={Transition}
      BackdropProps={{
        classes: {
          root: `${classes.shape} ${classes.root}`,
        },
      }}
    >
      <div className={classes.container}>
        {isCorrect ? <FeedbackContentPositive submission={submission} /> : <FeedbackContentNegative />}

        <Button variant="outlined" onClick={onClose} className={classes.button} color={buttonColor} data-cy="show-card">
          {strings.feedback.showSolution}
        </Button>
        <div className={classes.spacer} />
        <div className={classes.footer}>
          <Typography color={footerColor}>
            {strings.feedback.or} {strings.feedback.pickNewCard}
          </Typography>
        </div>
      </div>
    </Dialog>
  );
});

export default ResponseStateOverlay;
