import React from 'react';
import { Link } from '@material-ui/core';
import strings from '../../i18n/localization';

export const TermsAndConditions = () => (
  <>
    {' '}
    * {strings.registration.normalTextTermsAndConditions}
    <br />
    <span style={{ marginLeft: '40px' }}>
      <Link href={strings.registration.contractOfUseLink} variant="subtitle1">
        {strings.registration.contractOfUse}
      </Link>
      {strings.registration.postTermText}
    </span>
    <br />
  </>
);
