import React from 'react';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import strings from '../../i18n/localization';

import useStyles from './UploadMessages.styles';
import Button from '../../components/Button/Button';
import Typography from '../../components/Typography/Typography';

const UploadMessages = ({ response }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body2" extraBold>
        {strings.admin.uploadMsg}
      </Typography>
      {!isEmpty(response) && (
        <ul>
          {response.error ? (
            <li className={classes.errorFeedback}>
              {response.error.response && response.error.response.data
                ? response.error.response.data.error
                : response.error.toString()}
            </li>
          ) : (
            <>
              <li>{response.changes}</li>

              {response.corruptedEntries.map(elem => (
                <li className={classes.errorFeedback} key={elem.id}>
                  {elem.id}: {elem.errors.toString()}
                </li>
              ))}
            </>
          )}
        </ul>
      )}
      <Button
        size="small"
        className={classnames(classes.button, 'px-2')}
        variant="contained"
        disabled={isEmpty(response)}
      >
        {strings.admin.saveAsTxt}
      </Button>
    </>
  );
};

export default UploadMessages;
