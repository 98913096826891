import React, { useMemo } from 'react';
import strings from '../../../i18n/localization';
import InputField from '../../FormElements/InputField/InputField';
import { useQuestionList } from '../../../context/AdminQuestionListContext/AdminQuestionListContext';

const QuestionIdInput = ({ register, errors }) => {
  const { questions } = useQuestionList();
  const questionIdMap = useMemo(() => questions.reduce((map, { questionId }) => map.set(questionId, true), new Map()), [
    questions,
  ]);
  const validateQuestionId = questionId =>
    questionIdMap.get(questionId) ? strings.admin.question.errors.idAlreadyUsed : true;
  return (
    <InputField
      name="questionId"
      light
      data-cy="question-id"
      ref={register({
        validate: validateQuestionId,
      })}
      error={errors?.questionId}
    >
      {strings.admin.question.questionId}
    </InputField>
  );
};

export default QuestionIdInput;
