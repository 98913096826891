import React, { forwardRef } from 'react';

import useStyles from './Select.styles';
import Typography from '../../Typography/Typography';
import Dropdown from '../../Dropdown/Dropdown';

const Select = forwardRef(({ children, error, ...rest }, ref) => {
  const classes = useStyles();
  return (
    <div className={classes.inputField}>
      <Typography variant="h5" component="label" className={classes.label}>
        {children}
        <Dropdown ref={ref} {...rest} />
      </Typography>
      {error && (
        <Typography color="error" className={classes.error}>
          {error.message}
        </Typography>
      )}
    </div>
  );
});

export default Select;
