import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  avatar: {
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'inline-flex',
    alignItems: 'flex-end',
    marginBottom: theme.spacing(7),
  },
  avatarImage: {
    width: 100,
    height: 100,
    marginRight: theme.spacing(5),
  },
  error: {
    marginTop: theme.spacing(4),
  },
  success: {
    marginTop: theme.spacing(4),
  },
}));

export default useStyles;
