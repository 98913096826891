import addressparser from 'addressparser';

const parse = val => {
  let lastEmptyMailName = '';

  const addresses = addressparser(val).filter(it => {
    const touple = it;

    if (!touple.address) {
      lastEmptyMailName = touple.name;
    } else if (lastEmptyMailName.length > 0) {
      touple.name += ` ${lastEmptyMailName}`;
      lastEmptyMailName = '';
    } else {
      lastEmptyMailName = '';
    }
    return touple.address;
  });

  return addresses;
};

const parsedAdressesToJson = parsedAddresses => {
  const addresses = [];

  parsedAddresses.map(item => {
    const names = item.name.split(' ');
    let [first, last] = ['', ''];
    switch (names.length) {
      case 0:
        break;
      case 1:
        [first] = names;
        break;
      case 2:
        [first, last] = names;
        break;
      default:
        first = names.slice(0, names.length - 1).join(' ');
        last = names.slice(-1).toString();
    }

    return addresses.push({ firstName: first, lastName: last, email: item.address });
  });

  return addresses;
};

export { parse, parsedAdressesToJson };
