import React, { useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { Snackbar, SnackbarContent, IconButton } from '@material-ui/core';
import {
  CheckCircle as CheckCircleIcon,
  Error as ErrorIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';

import useStyles from './FeedbackSnackbar.styles';
import { useDisplayMessage } from '../../context/MessageContext/MessageContext';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const FeedbackSnackbar = () => {
  const classes = useStyles();
  const { currentMessage, isOpen, closeMessage, messages, openMessage, popMessage } = useDisplayMessage();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    closeMessage();
  };

  const handleExited = useCallback(() => {
    if (messages.length > 0) {
      openMessage();
      popMessage();
    }
  }, [openMessage, popMessage, messages.length]);

  useEffect(() => {
    handleExited();
  }, [handleExited]);

  const messageVariant = currentMessage?.variant;
  const Icon = variantIcon[messageVariant];

  return (
    <Snackbar
      key={currentMessage?.key}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={isOpen}
      autoHideDuration={5000}
      onClose={handleClose}
      onExited={handleExited}
      data-cy="feedback-snackbar"
    >
      <SnackbarContent
        className={classnames(classes[messageVariant])}
        aria-describedby="client-snackbar"
        data-cy={`feedback-snackbar-${messageVariant}`}
        message={
          messageVariant && (
            <span id="client-snackbar" className={classes.message}>
              <Icon className={classnames(classes.icon, classes.iconVariant)} />
              {currentMessage.message}
            </span>
          )
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            className={classes.close}
            onClick={handleClose}
            data-cy="close-snackbar"
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>
  );
};

export default FeedbackSnackbar;
