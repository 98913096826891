import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  filterPadding: {
    padding: theme.spacing(0, 6, 6),
  },
  fab: {
    position: 'absolute',
    right: 60,
    backgroundColor: theme.palette.secondary.main,
  },
  fabContainer: {
    position: 'fixed',
    bottom: 100,
    ...theme.fixedWidth,
  },
}));

export default useStyles;
