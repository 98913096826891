import React from 'react';
import classnames from 'classnames';
import { Checkbox, FormControlLabel, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Avatar from '../../Avatar/Avatar';
import Typography from '../../Typography/Typography';
import strings from '../../../i18n/localization';
import Separator from '../../Separator/Separator';
import { useFeedbackTypeLabels } from '../../../context/selectors/feedback';

import useStyles from './FeedbackItem.styles';

const FeedbackItem = ({
  showTooltip,
  closeActiveUserDetailDialog,
  setActiveUserDetailDialog,
  handleFeedbackClick,
  disabled,
  feedback: { id, feedbackDescription, feedbackType, avatar, creationTimestamp, resolved, firstName, lastName, mail },
}) => {
  const classes = useStyles();
  const feedbackTypeLabels = useFeedbackTypeLabels();
  const creationDate = new Date(creationTimestamp);
  const formattedDate = `${creationDate.getDate()}/${creationDate.getMonth() + 1}/${creationDate.getFullYear()}`;
  return (
    <li className={classnames(classes.feedbackItem, { [classes.resolved]: resolved })}>
      <div className={classes.feedbackTitle}>
        <div
          className={classnames(classes.tooltipOverlay, {
            [classes.show]: showTooltip,
          })}
        >
          <div className={classes.feedbackTitle}>
            <div className={classes.avatarContainer}>
              <Avatar name={avatar} />
            </div>
            <div>
              <Typography uppercase>{firstName}</Typography>
              <Typography uppercase>{lastName}</Typography>
              <Typography color="primary">{mail}</Typography>
            </div>
          </div>
          <div>
            <IconButton onClick={closeActiveUserDetailDialog} aria-label="close" data-cy="close-tooltip">
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          </div>
        </div>
        <button type="button" className={classes.avatarContainer} onClick={() => setActiveUserDetailDialog({ id })}>
          <div className={classes.pointerCapture} />
          <Avatar name={avatar} />
        </button>

        <div>
          <Typography variant="h5">{feedbackTypeLabels[feedbackType]}</Typography>
          <Typography>{formattedDate}</Typography>
        </div>
      </div>
      <Typography>{feedbackDescription}</Typography>
      <FormControlLabel
        classes={{
          root: classes.checkboxContainer,
        }}
        control={
          <Checkbox
            onChange={() => handleFeedbackClick(id)}
            checked={resolved}
            disabled={resolved || disabled}
            classes={{ root: classes.checkbox, disabled: 'checked' }}
            data-cy={`resolve-${id}`}
          />
        }
        label={<Typography>{strings.admin.feedbackDialog.resolved}</Typography>}
      />
      {resolved && <Separator />}
    </li>
  );
};

export default FeedbackItem;
