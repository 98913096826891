import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  main: {
    backgroundColor: theme.palette.primary.main,
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.text.secondary,
  },
  padding: {
    padding: theme.spacing(4, 7, 4, 4),
  },
  hideCheckmark: {
    '& div': {
      display: 'none',
    },
  },
  icon: {
    color: theme.palette.text.secondary,
    width: 'auto',
    margin: `${theme.spacing(4)}px 12px`,
  },
  list: {
    '& .Mui-selected': {
      backgroundColor: `${theme.palette.primary.main} !important`,
      color: `${theme.palette.text.secondary} !important`,
    },
    '& .MuiListItem-button:hover': {
      color: theme.palette.primary.main,
    },
  },
  listItem: {
    minHeight: 0,
    paddingLeft: 24,
  },
  checkmark: {
    color: theme.palette.text.secondary,
    marginLeft: -theme.spacing(4),
    marginRight: theme.spacing(4),
    minWidth: theme.spacing(5),
  },
}));

export default useStyles;
