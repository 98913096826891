import React from 'react';
import { useTheme } from '@material-ui/core';
import { ReactComponent as Icon } from './assets/close.svg';

const CloseIcon = ({ name, color, ...rest }) => {
  const theme = useTheme();
  const diameter = '30px';
  return <Icon {...rest} width={diameter} height={diameter} fill={color || theme.palette.grey.c500} />;
};

export default CloseIcon;
