import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import FullScreenDialog from '../../FullScreenDialog/FullScreenDialog';
import strings from '../../../i18n/localization';
import Fieldset from '../../FormElements/Fieldset/Fieldset';
import TextArea from '../../FormElements/TextArea/TextArea';
import Select from '../../FormElements/Select/Select';
import Button from '../../Button/Button';
import useStyles from './FeedbackDialog.styles';
import useFeedbackSubmit from '../../../hooks/api/feedback/useFeedbackSubmit';
import { useFeedbackTypeLabels } from '../../../context/selectors/feedback';
import { useAddMessage } from '../../../context/MessageContext/MessageContext';

const FEEDBACK_TYPE = 'type';
const DESCRIPTION = 'description';
const DESCRIPTION_MAX_LENGTH = 4000;

const registerCustomField = (register, name) => root =>
  root !== null && register(root.querySelector(`input[name=${name}]`));

const FeedbackDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const { addSuccessMessage } = useAddMessage();
  const { submitFeedback, error, data, pending } = useFeedbackSubmit();
  const onSubmit = formData => {
    submitFeedback({ data: { ...formData, type: parseInt(formData[FEEDBACK_TYPE], 10) } });
  };
  const items = useFeedbackTypeLabels();
  const { register, handleSubmit, errors: validationErrors, formState } = useForm({
    mode: 'onBlur',
    defaultValues: {
      [FEEDBACK_TYPE]: '1',
      [DESCRIPTION]: '',
    },
  });

  useEffect(() => {
    if (!pending && !error && data) {
      onClose();
      addSuccessMessage(strings.questionFeedback.messages.feedbackSent);
    }
  }, [data, error, pending, onClose, addSuccessMessage]);
  const feedbackTypeField = registerCustomField(register, FEEDBACK_TYPE);
  return (
    <FullScreenDialog open={open} onCloseButton={onClose} title={strings.questionFeedback.title}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={classes.container}>
          <Fieldset disabled={pending} aria-busy={pending}>
            <Select ref={feedbackTypeField} name={FEEDBACK_TYPE} items={items} error={validationErrors[FEEDBACK_TYPE]}>
              {strings.questionFeedback.feedbackType}
            </Select>

            <TextArea
              name={DESCRIPTION}
              ref={register({
                maxLength: {
                  value: DESCRIPTION_MAX_LENGTH,
                  message: strings.questionFeedback.messages.descriptionTooLong,
                },
              })}
              placeholder={strings.questionFeedback.descriptionPlaceholder}
              error={validationErrors[DESCRIPTION]}
            >
              {strings.questionFeedback.description}
            </TextArea>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              data-cy="button-submit-feedback"
              disabled={!formState.isValid}
            >
              {strings.questionFeedback.submit}
            </Button>
          </Fieldset>
        </div>
      </form>
    </FullScreenDialog>
  );
};

export default FeedbackDialog;
