import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  center: {
    alignSelf: 'center',
    textDecoration: 'none',
  },
  centerSpacedDown: {
    marginTop: 20,
    alignSelf: 'center',
    textDecoration: 'none',
  },
}));

export default useStyles;
