import React from 'react';
import useStyles from './SelfRegistration.styles';
import axaLogo from './assets/axa_logo.png';
import oneconsultLogo from './assets/oneconsult_logo.png';
import zuehlkeLogo from './assets/zuehlke_logo.png';

export const FooterLogos = () => {
  const classes = useStyles();
  return (
    <div className={classes.logoSpace}>
      <img alt="OneConsult" src={oneconsultLogo} className={classes.oneConsultLogo} />
      <img alt="Zuehlke" src={zuehlkeLogo} className={classes.zuehlkeLogo} />
      <img alt="Powered by AXA" src={axaLogo} className={classes.axaLogo} />
    </div>
  );
};
