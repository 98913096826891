import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { oidcClient } from '../../oidc';
import routes from '../../routes';
import useStyles from './SignUpCallback.styles';
import strings from '../../i18n/localization';

const SignUpCallback = () => {
  const history = useHistory();
  const classes = useStyles();
  const [error, setError] = useState();
  const [errorKey, setErrorKey] = useState();

  const ERROR_EXPIRED_KEY = 'The+provided+id_token_hint+parameter+is+expired';
  const ERROR_EXPIRED = 'TOKEN_EXPIRED';

  useEffect(() => {
    oidcClient
      .processSigninResponse()
      .then(() => history.push(routes.OVERVIEW))
      .catch(e => {
        const errorDescription = history.location.hash
          .replace('#', '')
          .split('&')
          .find(item => item.startsWith('error_description='));

        if (errorDescription) {
          setError(`${errorDescription},exception=${e}`);
          if (errorDescription.includes(ERROR_EXPIRED_KEY)) {
            setErrorKey(ERROR_EXPIRED);
          }
        } else {
          setError(`${e}`);
        }
      });
  }, [error, history, setError]);

  return (
    <div className={classes.container}>
      {error ? (
        <>
          <h1>
            <span className={classes.error}>&#9938;</span> Error loading site
          </h1>
          <p>
            {errorKey === ERROR_EXPIRED
              ? strings.error.expiredRegistrationToken
              : 'An error occurred - try to close the tab and open it again.'}
          </p>
          <details>
            <summary>Technical Information</summary>
            <p>{error}</p>
          </details>
        </>
      ) : null}
    </div>
  );
};

export default SignUpCallback;
