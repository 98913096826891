import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes';
import { useQuestionSubmission } from '../../../../context/QuestionContext/QuestionContext';
import { useSixInARow } from '../../../../context/SixInARow/SixInARowContext';
import QuitOverlay from './QuitOverlay/QuitOverlay';
import FailOverlay from './FailOverlay/FailOverlay';
import WinOverlay from './WinOverlay/WinOverlay';

const useDialogHandler = shouldOpen => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (shouldOpen) {
      setIsOpen(true);
    }
  }, [shouldOpen]);

  const closeDialog = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  return [isOpen, closeDialog];
};

const Overlay = () => {
  const { status, lastQuestionSubmission, quitSubmission, quitSixInARow, hideQuitConfirmation } = useSixInARow();
  const { pending, error, data } = quitSubmission;
  const { submission } = useQuestionSubmission();
  const history = useHistory();

  const [isFailedDialogOpen, closeFailedDialog] = useDialogHandler(status.hasFailedGame);

  useEffect(() => {
    if (!pending && !error && data) {
      history.push(routes.OVERVIEW);
    }
  }, [data, error, pending, history]);

  if (status.isAboutToQuit) {
    return (
      <QuitOverlay
        onClose={hideQuitConfirmation}
        onContinue={() => quitSixInARow() && hideQuitConfirmation()}
        onNewGame={() => quitSixInARow() && history.push(routes.PLAY_SIX_START)}
        submission={lastQuestionSubmission}
        isOpen
      />
    );
  }

  if (status.hasFailedGame) {
    return <FailOverlay onClose={closeFailedDialog} isOpen={isFailedDialogOpen} />;
  }

  if (status.hasWonGame) {
    return <WinOverlay submission={submission} isOpen />;
  }

  if (status.hasRunOutOfTime && !submission.pending) {
    return <FailOverlay onClose={() => null} showSolution={false} isOpen isFullScreen />;
  }

  return null;
};

export default Overlay;
