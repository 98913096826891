import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  fullWidthContainer: {
    width: `calc(100% + ${theme.padding.horizontal.xs * 2}px)`,
    margin: `0 ${-theme.padding.horizontal.xs}px`,
    padding: `0 ${theme.padding.horizontal.xs}px 50px ${theme.padding.horizontal.xs}px`,
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      width: `calc(100% + ${theme.padding.horizontal.sm * 2}px)`,
      margin: `0 ${-theme.padding.horizontal.sm}px`,
      padding: `0 ${theme.padding.horizontal.sm}px 50px ${theme.padding.horizontal.sm}px`,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      width: `calc(100% + ${theme.padding.horizontal.md * 2}px)`,
      margin: `0 ${-theme.padding.horizontal.md}px`,
      padding: `0 ${theme.padding.horizontal.md}px 50px ${theme.padding.horizontal.md}px`,
    },
  },
}));

export default useStyles;
