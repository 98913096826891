import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from './SelfRegistration.styles';
import strings from '../../i18n/localization';

export const SelfRegistrationIntroText = () => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h4" gutterBottom component="div" className={classes.verticalSpacing}>
        {strings.registration.selfRegistration}
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div" className={classes.verticalSpacing}>
        <b>{strings.registration.boldPredescriptionText}</b> {strings.registration.preDescriptionText}{' '}
        <b>{strings.registration.boldPostPredescriptionText}</b>
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div" className={classes.verticalSpacingDescriptionText}>
        {strings.registration.descriptionText}
      </Typography>
      <Typography variant="subtitle1" gutterBottom component="div" className={classes.verticalSpacingDescriptionText}>
        {strings.registration.postDescriptionText}
      </Typography>
    </>
  );
};
