import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as videoIcon } from './assets/i_video.svg';

const sizeLookupMap = {
  S: '40px',
  M: '50px',
  L: '80px',
};

const VideoIcon = ({ size, ...rest }) => {
  const Component = videoIcon;
  return <Component {...rest} width={sizeLookupMap[size]} height={sizeLookupMap[size]} />;
};

VideoIcon.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeLookupMap)),
};

export default VideoIcon;
