import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as FeedbackIconSvg } from './assets/i_feedback.svg';

const sizeLookupMap = {
  S: '28px',
};

const FeedbackIcon = ({ size = 'S', ...rest }) => (
  <FeedbackIconSvg {...rest} width={sizeLookupMap[size]} height={sizeLookupMap[size]} />
);

FeedbackIcon.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeLookupMap)),
};

export default FeedbackIcon;
