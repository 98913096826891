import React from 'react';
import { Check as CheckIcon, Close as CloseIcon } from '@material-ui/icons';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import useStyles from './AnswerContainer.styles';
import { useQuestionSubmission, useQuestionState } from '../../../../context/QuestionContext/QuestionContext';
import Typography from '../../../Typography/Typography';

export default function AnswerContainer({ question }) {
  const classes = useStyles();
  const { submission } = useQuestionSubmission();
  const { selectAnswers } = useQuestionState();
  const { answers } = question;

  const toggleAnswer = selectedIndex => () => {
    const currentAnswers = answers.map((answer, index) =>
      selectedIndex === index ? { ...answer, isChecked: !answer.isChecked } : answer,
    );
    const givenAnswers = currentAnswers
      .filter(answer => answer.isChecked)
      .map(a => a.id)
      .sort();

    selectAnswers({ givenAnswers, currentAnswers });
  };

  const isCurrentlyAnsweringQuestions = isEmpty(submission);

  if (isCurrentlyAnsweringQuestions) {
    return (
      <>
        <form className={classes.answerList} data-cy="answer-list-form">
          {answers.map((answer, index) => (
            <label
              key={answer.id}
              htmlFor={answer.id}
              className={classnames(classes.answerContainer, { [classes.selectedAnswer]: answer.isChecked })}
            >
              <div className={classes.feedbackContainer}>
                <input
                  type="checkbox"
                  id={answer.id}
                  onChange={toggleAnswer(index)}
                  value={answer.text}
                  checked={answer.isChecked || false}
                  className={classes.checkbox}
                />
              </div>
              <Typography component="span" className={answer.isChecked ? classes.selectedText : classes.unselectedText}>
                {answer.text}
              </Typography>
            </label>
          ))}
        </form>
      </>
    );
  }

  return (
    <>
      <ul className={classnames(classes.answerList, classes.answerUnorderedList)}>
        {answers.map(answer => {
          const isAnswerCorrect = submission.idCorrectAnswers.includes(answer.id);
          const isAnswerSelected = answer.isChecked;
          const isSelectionCorrect = isAnswerCorrect && isAnswerSelected;
          const isSelectionMismatched =
            (isAnswerCorrect && !isAnswerSelected) || (!isAnswerCorrect && isAnswerSelected);

          const iconClassName =
            (isSelectionCorrect && classes.selectedCorrectIcon) || (isSelectionMismatched && classes.selectedWrongIcon);

          return (
            <li
              key={answer.id}
              className={classnames(classes.answerContainer, classes.answerQuestionedContainer, {
                [classes.correctAnswer]: isSelectionCorrect,
                [classes.wrongAnswer]: isSelectionMismatched,
              })}
            >
              <div className={classes.feedbackContainer}>
                {(isAnswerCorrect && <CheckIcon className={iconClassName} />) ||
                  (isAnswerSelected && !isAnswerCorrect && <CloseIcon className={iconClassName} />) || (
                    <div className={classes.iconFiller} />
                  )}
              </div>
              <span
                className={classnames({
                  [classes.selectedText]: isAnswerSelected,
                  [classes.unselectedText]: !isAnswerSelected,
                })}
              >
                {answer.text}
              </span>
            </li>
          );
        })}
      </ul>
    </>
  );
}
