import { useCallback, useEffect } from 'react';
import { userManager as oidcUserManager, oidcClient } from '../../../oidc';

const AuthStateInitializer = ({ children, useIdToken, useSetUserManager }) => {
  const { setIdToken } = useIdToken();
  const setUserManager = useSetUserManager();

  const { href, hash } = window.location;
  const signupTokenRegex = /signup=([A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*)/;

  const newUserWithSignupToken = hash.match(signupTokenRegex);
  const newUserWithB2CHash = hash.includes('token=');

  useEffect(() => {
    setUserManager(oidcUserManager);
  }, [setUserManager]);

  useEffect(() => {
    // Register event, which is called once the user is updated
    // Update token when B2C returns new token
    oidcUserManager.events.addUserLoaded(user => {
      setIdToken(user.id_token);
    });
  }, [setIdToken]);

  const loadProcessToken = useCallback(async () => {
    try {
      if (process.env.REACT_APP_USE_OIDC === 'false') {
        setIdToken(process.env.REACT_APP_FAKED_OIDC_TOKEN);
        // User comes from email invitation link
      } else if (newUserWithSignupToken) {
        const { url } = await oidcClient.createSigninRequest();
        window.location = `${url}&id_token_hint=${newUserWithSignupToken[1]}&prompt=login`;

        // User comes from b2c login: Parse the response and init the application
      } else if (newUserWithB2CHash) {
        const user = await oidcUserManager.signinRedirectCallback();
        setIdToken(user.id_token);
        if (user.state) {
          window.location = user.state;
        }

        // There is no hash in the URL
      } else {
        const user = await oidcUserManager.getUser();

        // No user in local storage. Fetch user and render the page
        if (user === null) {
          await oidcUserManager.signinRedirect({ state: href });

          // If token is expired, redirect and fetch a new token (preferably we would refresh silently)
        } else if (user.expired) {
          await oidcUserManager.signinRedirect({ state: href });

          // User is already in local storage an token is valid. Just render the page
        } else {
          setIdToken(user.id_token);
        }
      }
    } catch (e) {
      await oidcUserManager.signinRedirect();
    }
  }, [newUserWithSignupToken, newUserWithB2CHash, setIdToken, href]);

  useEffect(() => {
    loadProcessToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

export default AuthStateInitializer;
