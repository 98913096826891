import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  list: {
    listStyleType: 'none',
    padding: '0',
  },
  questionId: {
    margin: 'auto 0',
  },
  item: {
    backgroundColor: theme.palette.grey.c100,
    padding: theme.spacing(4, 5),
  },
  even: {
    backgroundColor: 'white',
  },
  questionHeader: {
    height: 33,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
