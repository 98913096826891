import { ENDPOINTS } from '../constants';
import useAxios from '../../useAxios';
import promiseCoordinator from '../../../service/promiseCoordinator';

const coordinator = promiseCoordinator();

const useVideos = () => {
  const config = {
    url: ENDPOINTS.VIDEOS,
  };

  const [{ data, pending }, getVideos] = useAxios(coordinator, config);

  return {
    data,
    pending,
    getVideos,
  };
};

export default useVideos;
