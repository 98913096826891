import React, { useEffect } from 'react';
import { FormControl } from '@material-ui/core';

import strings from '../../../../i18n/localization';
import useCsvExport from '../../../../hooks/api/csv/useCsvExport';
import Button from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import useStyles from './DownloadCsv.styles';
import downloadTextAsCsv from './downloadTextAsCsv';

const DownloadCsv = () => {
  const classes = useStyles();
  const { data, pending, error, exportCsv } = useCsvExport();
  useEffect(() => {
    if (data) {
      downloadTextAsCsv('secured-questions.csv', data);
    }
  }, [data]);

  return (
    <FormControl fullWidth>
      {error && <p>error</p>}
      {!pending && (
        <Button size="small" variant="contained" color="primary" onClick={exportCsv} className={classes.center}>
          {strings.admin.downloadFile}
        </Button>
      )}
      {!error && pending && <Loader />}
    </FormControl>
  );
};

export default DownloadCsv;
