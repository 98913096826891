import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: theme.fixedWidth,
  closeIcon: {
    position: 'absolute',
    right: theme.spacing(3),
    top: theme.spacing(6),
  },
  titlePadding: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(8),
  },
  primaryPaper: {
    backgroundColor: theme.palette.primary.main,
  },
}));

export default useStyles;
