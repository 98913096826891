import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    marginTop: theme.spacing(7),
    padding: theme.spacing(0, 6),
    overflowY: 'auto',
  },
  explanationText: {
    paddingTop: theme.spacing(6),
    color: theme.palette.text.primaryLight,
    wordBreak: 'break-word',
  },
  flipCardButton: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.main,
    fontWeight: '400',
    backfaceVisibility: 'hidden',
  },
}));

export default useStyles;
