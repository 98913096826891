import React from 'react';
import useStyles from './CardinalNumberTick.styles';

const CardinalNumberTick = ({ x, y, payload }) => {
  const classes = useStyles();
  return (
    <text x={x} y={y} textAnchor="middle" dy="16" className={classes.textNode}>
      {payload.value}
    </text>
  );
};

export default CardinalNumberTick;
