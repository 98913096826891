import React from 'react';
import classnames from 'classnames';
import useStyles from './FullWidthContainer.styles';

const FullWidthContainer = ({ children, className, component = 'div' }) => {
  const classes = useStyles();
  const ParentComponent = component;

  return <ParentComponent className={classnames(classes.fullWidthContainer, className)}>{children}</ParentComponent>;
};

export default FullWidthContainer;
