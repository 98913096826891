import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as dat } from './assets/i_data.svg';
import { ReactComponent as ema } from './assets/i_email.svg';
import { ReactComponent as inc } from './assets/i_incident.svg';
import { ReactComponent as mob } from './assets/i_mobile.svg';
import { ReactComponent as pwd } from './assets/i_password.svg';
import { ReactComponent as web } from './assets/i_web.svg';

const icons = {
  dat,
  ema,
  inc,
  mob,
  pwd,
  web,
};

const sizeLookupMap = {
  S: '40px',
  M: '50px',
  L: '80px',
};

const CategoryIcon = ({ name, size, ...rest }) => {
  const Component = icons[name] || dat;
  return <Component {...rest} width={sizeLookupMap[size]} height={sizeLookupMap[size]} />;
};

CategoryIcon.propTypes = {
  name: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.oneOf(Object.keys(sizeLookupMap)),
};

export default CategoryIcon;
