import React from 'react';
import PropTypes from 'prop-types';

import { LEVELS } from '../../service/awards';
import bronzePng from './assets/bronze.png';
import silverPng from './assets/silver.png';
import goldPng from './assets/gold.png';
import inactiveSvg from './assets/inactive.svg';

import activitySvg from './assets/awards/activity.svg';
import dataSvg from './assets/awards/data.svg';
import emailSvg from './assets/awards/email.svg';
import incidentSvg from './assets/awards/incident.svg';
import mobileSvg from './assets/awards/mobile.svg';
import passwordSvg from './assets/awards/password.svg';
import strikeSvg from './assets/awards/strike.svg';
import totalAnsweredSvg from './assets/awards/totalAnswered.svg';
import totalScoreSvg from './assets/awards/totalScore.svg';
import webSvg from './assets/awards/web.svg';

import useStyles from './AwardIcon.styles';

const sizeLookupMap = {
  S: 80,
  L: 200,
};

const levelLookupMap = {
  [LEVELS.BRONZE]: bronzePng,
  [LEVELS.SILVER]: silverPng,
  [LEVELS.GOLD]: goldPng,
  unclaimed: inactiveSvg,
};

const awardLookupMap = {
  totalAnsweredQuestions: { svg: totalAnsweredSvg, ratio: 0.53 },
  totalScore: { svg: totalScoreSvg, ratio: 0.53 },
  answerStrike: { svg: strikeSvg, ratio: 0.38 },
  activityStrike: { svg: activitySvg, ratio: 0.48 },
  datScore: { svg: dataSvg, ratio: 0.53 },
  webScore: { svg: webSvg, ratio: 0.53 },
  mobScore: { svg: mobileSvg, ratio: 0.33 },
  emaScore: { svg: emailSvg, ratio: 0.48 },
  pwdScore: { svg: passwordSvg, ratio: 0.53 },
  incScore: { svg: incidentSvg, ratio: 0.43 },
};

const AwardIcon = ({ size, level, award, ...rest }) => {
  const classes = useStyles();
  const dimension = sizeLookupMap[size];
  const dimensionPx = `${dimension}px`;
  const src = levelLookupMap[level];
  const awardIcon = awardLookupMap[award];
  return (
    <div className={classes.root}>
      <img src={src} width={dimensionPx} height={dimensionPx} alt="award" {...rest} data-cy="award-badge" />
      <img
        className={classes.awardIcon}
        width={`${dimension * awardIcon.ratio}px`}
        src={awardIcon.svg}
        alt="award icon"
        data-cy="award-badge-icon"
      />
    </div>
  );
};

AwardIcon.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeLookupMap)),
  level: PropTypes.oneOf(Object.keys(levelLookupMap)),
  award: PropTypes.oneOf(Object.keys(awardLookupMap)),
};

export default AwardIcon;
