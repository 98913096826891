import React from 'react';
import classnames from 'classnames';
import useStyles from './Widget.styles';
import Typography from '../Typography/Typography';

const Widget = ({ children, title, noBodyPadding, bodyClass, className }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.widgetWrapper, className)}>
      <div className={classes.paper}>
        <Typography className={classes.widgetHeader} variant="h4">
          {title}
        </Typography>
        <div className={classnames(classes.widgetBody, { [classes.noPadding]: noBodyPadding, [bodyClass]: bodyClass })}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Widget;
