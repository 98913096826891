import React, { useState, forwardRef } from 'react';
import { FormControl, InputBase, Select, MenuItem } from '@material-ui/core';
import classnames from 'classnames';
import CheckIcon from '@material-ui/icons/Check';
import { ReactComponent as ArrowDownIcon } from './assets/arrow-down.svg';
import useStyles from './Dropdown.styles';
import Typography from '../Typography/Typography';

const Dropdown = forwardRef(({ items, onItemSelected, className, selected, ...rest }, ref) => {
  const classes = useStyles();
  const keys = Object.keys(items);
  const [current, setCurrent] = useState(selected || keys[0]);

  const handleChange = evt => {
    const newKey = evt.target.value;
    setCurrent(newKey);

    if (typeof onItemSelected === 'function') {
      onItemSelected(newKey);
    }
  };

  const BootstrapInput = <InputBase classes={{ root: classes.main }} />;

  return (
    <FormControl className={className}>
      <Select
        ref={ref}
        value={current}
        onChange={handleChange}
        IconComponent={ArrowDownIcon}
        MenuProps={{
          classes: { paper: classes.main, list: classes.list },
        }}
        input={BootstrapInput}
        classes={{ icon: classes.icon, root: classnames(classes.padding, classes.hideCheckmark) }}
        {...rest}
      >
        {keys.map(key => (
          <MenuItem
            dense
            component="div"
            key={key}
            value={key}
            classes={{
              root: classes.listItem,
            }}
            data-cy={`dropdown-item-${key}`}
          >
            <div className={classes.checkmark}>{key === current && <CheckIcon fontSize="inherit" />}</div>
            <Typography color="" component="span" uppercase>
              {items[key]}
            </Typography>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
});

export default Dropdown;
