const GET = 'GET';
const POST = 'POST';
const PUT = 'PUT';
const DELETE = 'DELETE';

const API_URL = window.securedConfig ? window.securedConfig.BE_URL : 'BE_URL_NOT_SET';

const CYAP_SYNC_API_URL = window.securedConfig?.CYARP_INVITE_API_URL;

const ENDPOINTS = {
  SIX_IN_A_ROW_QUESTIONS_START: 'questions/six-in-a-row/start',
  SIX_IN_A_ROW_QUESTIONS: 'questions/six-in-a-row',
  SIX_IN_A_ROW_QUIT: 'questions/six-in-a-row/quit',
  QUESTIONS: 'questions',
  QUESTIONS_EXPORT: 'questions/export',
  QUESTIONS_IMPORT: 'questions/upload',
  SCORE: 'scores',
  RANKING: 'rankings',
  AWARDS: 'awards',
  INVITE: 'user/invite',
  INVITE_TENANT: 'tenant',
  USER_CURRENT: 'user/current',
  LANGUAGE: 'user/languages',
  USER: 'user',
  ADMIN_QUESTIONS: 'admin/questions',
  ADMIN_FEEDBACK: 'admin/feedback',
  ADMIN_RESET_CACHE: 'admin/reset/cache',
  CHECKLIST_PREFIX: 'protectedResources/quizee/checklist',
  VIDEOS: 'protectedResources/quizee/videos.json',
  LOAD_TENANT_BOARD: 'admin/tenant/board',
  DELETE_TENANT: 'admin/tenant/delete',
  DISABLE_TENANT: 'admin/tenant/disable',
  ENABLE_TENANT: 'admin/tenant/enable',
  TENANTS_EXPORT: 'admin/tenant/export',
  SELF_REGISTRATION: 'tenant/register',
};

export { ENDPOINTS, GET, PUT, POST, DELETE, API_URL, CYAP_SYNC_API_URL };
