import React from 'react';
import isEmpty from 'lodash/isEmpty';
import { BarChart, Bar, XAxis, Cell, ResponsiveContainer } from 'recharts';
import { useTheme } from '@material-ui/core';
import useStyles from './TabPanelRanking.styles';
import RenderAvatarTick from '../RenderAvatarTick/RenderAvatarTick';
import NameTick from '../NameTick/NameTick';
import OrdinalNumberTick from '../OrdinalNumberTick/OrdinalNumberTick';
import Typography from '../../../components/Typography/Typography';
import strings from '../../../i18n/localization';
import Button from '../../../components/Button/Button';
import ArrowIcon from '../../../components/ArrowIcon/ArrowIcon';
import CardinalNumberTick from '../CardinalNumberTick/CardinalNumberTick';
import { useUser } from '../../../context/UserContext/UserContext';
import { RankingGuard } from '../../../context/RankingContext/RankingContext';

const getRankForChart = (ranks, nickName) => {
  // ex. 32, 1, 2 or 1 2 3 or 2 1 3
  const myRank = ranks.find(rank => rank.nickName === nickName);
  const allTheRest = ranks.filter(rank => rank.nickName !== nickName);
  if (isEmpty(myRank)) return [];
  return [myRank, ...allTheRest.slice(0, 2)];
};

const TabPanelRanking = ({ height, onSwitchTabClick, ranks }) => {
  const classes = useStyles();
  const theme = useTheme();
  const {
    user: { nickName },
  } = useUser();

  const barColors = [theme.palette.bars.first, theme.palette.bars.second, theme.palette.bars.third];

  return (
    <>
      <Typography variant="h2" color="primary">
        {strings.score.yourRanking}
      </Typography>
      <div className={classes.spacing} />
      <Button
        variant="link"
        onClick={onSwitchTabClick}
        data-cy="show-mixedscore"
        iconRight={<ArrowIcon className={classes.arrowPaddingLeft} />}
      >
        {strings.score.goToYourMixedScore}
      </Button>
      <div style={{ minHeight: height }}>
        <RankingGuard>
          <ResponsiveContainer height={height} className={classes.chartsContainer}>
            <BarChart data={getRankForChart(ranks, nickName)}>
              <XAxis
                dataKey="rank"
                axisLine={false}
                tickLine={false}
                tick={<OrdinalNumberTick fillList={barColors} />}
                height={50}
              />
              <XAxis
                dataKey="avatar"
                xAxisId="avatar"
                axisLine={false}
                tickLine={false}
                tick={RenderAvatarTick}
                height={50}
              />
              <XAxis dataKey="score" axisLine={false} tickLine={false} xAxisId="score" tick={<CardinalNumberTick />} />
              <XAxis
                dataKey="nickName"
                axisLine={false}
                tickLine={false}
                xAxisId="nickName"
                interval={0}
                onClick={onSwitchTabClick}
                tick={<NameTick activeUser={nickName} />}
              />

              <Bar dataKey="score" className={classes.bar} barSize={90}>
                {getRankForChart(ranks, nickName).map((entry, i) => (
                  <Cell
                    onClick={entry.nickName === nickName ? onSwitchTabClick : null}
                    key={entry.nickName}
                    fill={barColors.length > i ? barColors[i] : barColors[barColors.length - 1]}
                  />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </RankingGuard>
      </div>
    </>
  );
};

export default TabPanelRanking;
