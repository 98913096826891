import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  textNode: {
    fontSize: '2rem', // 32px
    fontWeight: '900',
  },
}));

export default useStyles;
