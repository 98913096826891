import React from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { succeed } from '../../service/reducer';
import strings from '../../i18n/localization';
import { useAddMessage } from '../MessageContext/MessageContext';

const initialContext = {
  data: null,
  pending: true,
  error: null,
};

const USER_LOADED = 'USER_LOADED';
const USER_ERROR = 'USER_ERROR';
const USER_PENDING = 'USER_PENDING';
const LANGUAGE_CHANGED = 'LANGUAGE_CHANGED';

const userLoadedAction = createAction(USER_LOADED);
const userFailedAction = createAction(USER_ERROR);
const userPendingAction = createAction(USER_PENDING);
const languageChangedAction = createAction(LANGUAGE_CHANGED);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case LANGUAGE_CHANGED:
      draft.data.language = action.payload;
      break;
    case USER_ERROR:
      draft.pending = false;
      draft.error = action.payload;
      break;
    case USER_PENDING:
      draft.pending = true;
      break;
    case USER_LOADED:
      return succeed(action.payload);
    default:
      break;
  }
});

const [UserContextProvider, useUserContext] = createContext(reducer, initialContext);

const UserContext = props => <UserContextProvider {...props} />;
const userCoordinator = promiseCoordinator();
const UserGuard = createGuard(useUserContext);

const useUser = () => {
  const { data: user, pending, dispatch } = useUserContext();
  const onboarded = user?.onboarded;

  const { addSuccessMessage } = useAddMessage();

  const fetch = useFetch(userCoordinator);

  const loadUser = useDataLoad(
    fetch,
    {
      url: ENDPOINTS.USER_CURRENT,
    },
    dispatch,
    userLoadedAction,
    userFailedAction,
    userPendingAction,
  );

  const updateUserData = useDataLoad(
    fetch,
    {
      method: onboarded ? 'PUT' : 'POST',
      url: ENDPOINTS.USER,
    },
    dispatch,
    userLoadedAction,
    userFailedAction,
  );

  const updateUser = async data => {
    const { success } = await updateUserData({ data });
    if (success) {
      addSuccessMessage(strings.forms.submitSuccess);
    }
  };

  return {
    user,
    loadUser,
    updateUser,
    pending,
  };
};

const useLanguage = () => {
  const { dispatch } = useUserContext();

  const fetch = useFetch(userCoordinator);

  const submitLanguage = useDataLoad(
    fetch,
    {
      method: 'PUT',
      url: ENDPOINTS.LANGUAGE,
    },
    dispatch,
  );

  const changeLanguage = lang => {
    dispatch(languageChangedAction(lang));
    submitLanguage({ data: { language: lang } });
  };

  return {
    changeLanguage,
  };
};

export default UserContext;
export { useUser, useLanguage, UserGuard };
