const routes = {
  APP_SWITCH: '/app',
  INVITE: '/manager/invite',
  ADMIN_DASHBOARD: '/admin/dashboard',
  ADMIN_TENANT_BOARD: '/admin/tenant-board',
  OVERVIEW: '/app/overview',
  CATEGORIES: '/app/categories',
  QUESTIONS: '/app/questions',
  PLAY_SIX: '/app/play/six',
  PLAY_SIX_START: '/app/play/six/getting-started',
  PROFILE: '/app/profile',
  LEARN: '/app/learn',
  AWARDS: '/app/awards',
  ONBOARDING: '/app/onboarding',
  ADMIN_QUESTIONS: '/admin/questions',
  LANDING_PAGE: '/',
  SELF_REGISTRATION: '/self-registration',
  SIGNUP_CALLBACK: '/signup-callback',
  SILENT_CALLBACK: '/silent-callback',
  TENANT_DISABLED: '/disabled',
};

export const dashboard = {
  HOME: '/cyarp',
};

export default routes;
