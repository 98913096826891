import React, { useEffect, useRef } from 'react';
import { CircularProgress } from '@material-ui/core';
import MailIcon from '@material-ui/icons/EmailTwoTone';
import Button from '../../../components/Button/Button';
import { useAddMessage } from '../../../context/MessageContext/MessageContext';
import strings from '../../../i18n/localization';
import useTenantInvite from '../../../hooks/api/users/useTenantInvite';

export const InviteTenantAdmin = ({ userTenantAdmin, tenant }) => {
  const { pending: userPending, error: userError, submit: userSubmit } = useTenantInvite();
  const { addSuccessMessage } = useAddMessage();

  const componentDidMount = useRef(false);

  const sendUserInvite = () => {
    userSubmit({
      data: {
        tenantId: tenant.tenantName,
        contractId: tenant.contractId,
        userAdmin: {
          firstName: userTenantAdmin.firstName,
          lastName: userTenantAdmin.lastName,
          email: userTenantAdmin.emailAddress,
        },
      },
    });
  };
  useEffect(() => {
    if (componentDidMount.current && !userPending && !userError) {
      addSuccessMessage(strings.forms.submitSuccess);
    } else {
      componentDidMount.current = true;
    }
  }, [userPending, userError, addSuccessMessage]);

  return (
    <>
      <span>
        {' '}
        <Button size="small" variant="contained" color="primary" onClick={sendUserInvite}>
          <MailIcon />
        </Button>
        {userPending ? <CircularProgress style={{ marginBottom: '-17px' }} /> : null}
      </span>
    </>
  );
};
