import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import strings from '../../../i18n/localization';
import useStyles from '../TenantWidget.styles';

export const DisableTenantPopup = ({ showConfirmationText, disableFunction, hideDisableConfirmation, tenant }) => {
  const classes = useStyles();

  return showConfirmationText ? (
    <Typography className={classes.content}>
      <span style={{ flex: 4 }}>
        {strings.admin.tenantBoard.disableConfirmation} {tenant.tenantName}?
      </span>
      <span style={{ flex: 1 }}>
        <Button className={classes.deleteButton} variant="contained" onClick={disableFunction}>
          {strings.admin.tenantBoard.yes}
        </Button>
      </span>
      <span style={{ flex: 1 }}>
        <Button className={classes.cancelButton} variant="contained" onClick={hideDisableConfirmation}>
          {strings.admin.tenantBoard.no}
        </Button>
      </span>
    </Typography>
  ) : null;
};
