import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  checkboxPositive: {
    color: `${theme.palette.success.main} !important`,
  },
  checkboxNegative: {
    color: `${theme.palette.error.main} !important`,
  },
  checkbox: {
    color: theme.palette.grey.c500,
    paddingLeft: 0,
  },
  spacing: {
    marginBottom: theme.spacing(5),
  },
  answerOptions: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
