import React from 'react';

import QuestionCardLayout from '../../../components/QuestionCardLayout/QuestionCardLayout';
import strings from '../../../i18n/localization';
import FullScreenDialog from '../../../components/FullScreenDialog/FullScreenDialog';
import useStyles from './ReviewLayoutDialog.styles';

const ReviewLayoutDialog = ({ question, open, flipCard, onCloseButton }) => {
  const classes = useStyles();

  const handleClose = () => {
    if (onCloseButton) {
      onCloseButton();
    }
  };

  return (
    <FullScreenDialog
      fullScreen
      open={open}
      onCloseButton={handleClose}
      className={classes.maxWidth}
      title={strings.admin.layoutPreview}
      subTitle="iPhone X"
    >
      <div className={classes.iphoneLayout}>
        <QuestionCardLayout question={question} flipCard={flipCard} />
      </div>
    </FullScreenDialog>
  );
};

export default ReviewLayoutDialog;
