import React from 'react';
import PropTypes from 'prop-types';
import objectSize from 'lodash/size';

import { ReactComponent as Avatar1 } from './assets/avatar_1.svg';
import { ReactComponent as Avatar2 } from './assets/avatar_2.svg';
import { ReactComponent as Avatar3 } from './assets/avatar_3.svg';
import { ReactComponent as Avatar4 } from './assets/avatar_4.svg';
import { ReactComponent as Avatar5 } from './assets/avatar_5.svg';
import { ReactComponent as Avatar6 } from './assets/avatar_6.svg';
import { ReactComponent as Avatar7 } from './assets/avatar_7.svg';
import { ReactComponent as Avatar8 } from './assets/avatar_8.svg';
import { ReactComponent as Avatar9 } from './assets/avatar_9.svg';
import { ReactComponent as Avatar10 } from './assets/avatar_10.svg';
import { ReactComponent as Avatar11 } from './assets/avatar_11.svg';
import { ReactComponent as Avatar12 } from './assets/avatar_12.svg';
import { ReactComponent as Avatar13 } from './assets/avatar_13.svg';
import { ReactComponent as Avatar14 } from './assets/avatar_14.svg';
import { ReactComponent as Avatar15 } from './assets/avatar_15.svg';
import { ReactComponent as Avatar16 } from './assets/avatar_16.svg';
import { ReactComponent as Avatar17 } from './assets/avatar_17.svg';
import { ReactComponent as Avatar18 } from './assets/avatar_18.svg';
import { ReactComponent as Avatar19 } from './assets/avatar_19.svg';
import { ReactComponent as Avatar20 } from './assets/avatar_20.svg';
import { ReactComponent as Avatar21 } from './assets/avatar_21.svg';
import { ReactComponent as Avatar22 } from './assets/avatar_22.svg';
import { ReactComponent as Avatar23 } from './assets/avatar_23.svg';
import useStyles from './Avatar.styles';

const avatarLookupMap = {
  Avatar1,
  Avatar2,
  Avatar3,
  Avatar4,
  Avatar5,
  Avatar6,
  Avatar7,
  Avatar8,
  Avatar9,
  Avatar10,
  Avatar11,
  Avatar12,
  Avatar13,
  Avatar14,
  Avatar15,
  Avatar16,
  Avatar17,
  Avatar18,
  Avatar19,
  Avatar20,
  Avatar21,
  Avatar22,
  Avatar23,
};

const sizeLookupMap = {
  S: '40px',
  M: '50px',
  L: '80px',
};

const avatarCount = objectSize(avatarLookupMap);

const AvatarPlaceholder = ({ diameter }) => {
  const classes = useStyles();
  return <span style={{ height: diameter, width: diameter }} className={classes.placeholder} />;
};

const Avatar = ({ name, size, ...rest }) => {
  const diameter = sizeLookupMap[size];
  if (!name) {
    return <AvatarPlaceholder diameter={diameter} />;
  }
  const Component = avatarLookupMap[name] || Avatar1;
  return <Component {...rest} width={diameter} height={diameter} />;
};

Avatar.propTypes = {
  name: PropTypes.oneOf(Object.keys(avatarLookupMap)),
  size: PropTypes.oneOf(Object.keys(sizeLookupMap)),
};

export { avatarCount };
export default Avatar;
