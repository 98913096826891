import React from 'react';

import Typography from '../../../../Typography/Typography';
import { useUser } from '../../../../../context/UserContext/UserContext';
import useStyles from './AppSwitchLink.styles';
import { ReactComponent as ArrowIcon } from './assets/arrow-left.svg';
import { ReactComponent as SecurityDashboardIcon } from './assets/security-dashboard-icon.svg';

const AppSwitchLink = ({ path, label, onClick, allowedRole, ...rest }) => {
  const classes = useStyles();
  const {
    user: { roles },
  } = useUser();

  if (!roles.includes(allowedRole)) {
    return null;
  }

  return (
    <button type="button" className={classes.link} onClick={onClick} {...rest}>
      <div className={classes.leftMostIcon}>
        <ArrowIcon />
      </div>
      <div className={classes.middleIcon}>
        <SecurityDashboardIcon />
      </div>
      <Typography uppercase color="textPrimary" className={classes.label}>
        {label}
      </Typography>
    </button>
  );
};

export default AppSwitchLink;
