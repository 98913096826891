import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  header: {
    padding: theme.spacing(0, 6, 4, 6),
    wordBreak: 'break-word',
  },
  badge: {
    color: theme.palette.secondary.main,
    borderRadius: 21,
    height: 42,
    minWidth: 42,
    padding: '5px 10px',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: -18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `4px solid ${theme.palette.secondary.main}`,
    fontWeight: '700',
    backfaceVisibility: 'hidden',
    fontSize: 21,
  },
  content: {
    marginTop: theme.spacing(6),
    flexGrow: 1,
    overflowY: 'auto',
  },
  flipCardButton: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.primary.main,
    fontWeight: '400',
    backfaceVisibility: 'hidden',
  },
}));

export default useStyles;
