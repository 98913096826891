import React, { lazy, Suspense, useEffect, useState } from 'react';
import { BrowserRouter, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ThemeProviderCustom } from './ThemeProviderCustom';
import routes from './routes';
import FeedbackSnackbar from './components/FeedbackSnackbar/FeedbackSnackbar';
import Loader from './components/Loader/Loader';
import AppRoute from './AppRoute';
import SignUpCallback from './pages/Callback/SignUpCallback';
import SilentCallback from './pages/Callback/SilentCallback';
import { SelfRegistration } from './pages/SelfRegistration/SelfRegistration';

const Landing = lazy(() => import('./pages/Landing/Landing'));

const RootHandler = () => {
  const location = useLocation();
  const [componentToRender, setComponentToRender] = useState(null);
  useEffect(() => {
    (async () => {
      if (location.hash && location.hash.length > 0) {
        setComponentToRender(<Redirect to={`${routes.APP_SWITCH}${location.hash}`} />);
      } else {
        setComponentToRender(<Landing />);
      }
    })();
  }, [location]);

  return componentToRender;
};
const App = () => (
  <BrowserRouter>
    <ThemeProviderCustom>
      <Suspense fallback={<Loader />}>
        <Switch>
          <Route path={routes.LANDING_PAGE} exact render={() => <RootHandler />} />
          <Route path={routes.SELF_REGISTRATION} exact component={SelfRegistration} />
          <Route path={routes.SIGNUP_CALLBACK} exact component={SignUpCallback} />
          <Route path={routes.SILENT_CALLBACK} exact component={SilentCallback} />
          <AppRoute />
        </Switch>

        <FeedbackSnackbar />
      </Suspense>
    </ThemeProviderCustom>
  </BrowserRouter>
);

export default App;
