import React from 'react';
import useStyles from './Filter.styles';
import Typography from '../../../../components/Typography/Typography';
import Dropdown from '../../../../components/Dropdown/Dropdown';

const Filter = ({ name, ...rest }) => {
  const minDropdownWidth = 180;
  const classes = useStyles({ minDropdownWidth });

  return (
    <div className={classes.filter}>
      <Typography extraBold className={classes.verticalAlign}>
        {name}
      </Typography>
      <Dropdown className={classes.dropdown} {...rest} />
    </div>
  );
};

export default Filter;
