import { UserManager, OidcClient } from 'oidc-client';
import { AUTH_SETTINGS } from './constants';
import routes from './routes';

const { origin } = window.location;

const commonSettings = {
  client_id: AUTH_SETTINGS.CLIENT_ID,
  scope: AUTH_SETTINGS.SCOPE,
  loadUserInfo: false,
  response_type: 'id_token token',
};

const settingsOidcClient = {
  ...commonSettings,
  authority: 'https://securedzuehlke.b2clogin.com/securedzuehlke.onmicrosoft.com/B2C_1A_signup_invitationV2/v2.0/',
  redirect_uri: `${origin}${routes.SIGNUP_CALLBACK}`,
};

const settingsUserManager = {
  ...commonSettings,
  authority: AUTH_SETTINGS.AUTHORITY,
  redirect_uri: `${origin}${routes.APP_SWITCH}`,

  post_logout_redirect_uri: `${origin}${routes.LANDING_PAGE}`,
  silent_redirect_uri: `${origin}${routes.SILENT_CALLBACK}`,
  filterProtocolClaims: true,
  automaticSilentRenew: true,
  // in case of Azure Active Directory an additional query param is required
  extraQueryParams: AUTH_SETTINGS.IS_AAD
    ? {
        resource: AUTH_SETTINGS.CLIENT_ID,
      }
    : undefined,
};

const oidcClient = new OidcClient(settingsOidcClient);
const userManager = new UserManager(settingsUserManager);

export { oidcClient, userManager };
