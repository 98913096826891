/**
 * enables downloading CSV
 * @param filename
 * @param text
 */
function downloadTextAsCsv(filename, text) {
  const element = document.createElement('a');
  // force proper BOM to prevent error when opening CSV in Excel
  const universalBOM = '\uFEFF';
  element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(universalBOM + text)}`);
  element.setAttribute('download', filename);

  element.click();
}

export default downloadTextAsCsv;
