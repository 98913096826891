import React from 'react';
import AuthenticationContext, { AuthenticationGuard } from '../../context/AuthenticationContext/AuthenticationContext';
import UserContext from '../../context/UserContext/UserContext';
import RankingContext from '../../context/RankingContext/RankingContext';
import ScoreContext from '../../context/ScoreContext/ScoreContext';
import AwardContext from '../../context/AwardContext/AwardContext';

const AuthWrapper = ({ children }) => (
  <AuthenticationContext>
    <AuthenticationGuard allowOldData={false}>
      <UserContext>
        <RankingContext>
          <ScoreContext>
            <AwardContext>{children}</AwardContext>
          </ScoreContext>
        </RankingContext>
      </UserContext>
    </AuthenticationGuard>
  </AuthenticationContext>
);

export default AuthWrapper;
