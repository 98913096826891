import React, { useCallback, useMemo, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { useFieldArray, useForm } from 'react-hook-form';
import AddIcon from '@material-ui/icons/Add';
import classnames from 'classnames';
import strings from '../../i18n/localization';
import TextArea from '../FormElements/TextArea/TextArea';
import Button from '../Button/Button';
import useStyles from './QuestionForm.styles';
import LanguageBar from '../../pages/AdminQuestionDetail/LanguageBar/LanguageBar';
import Select from '../FormElements/Select/Select';
import { useCategoryLabels } from '../../context/selectors/categories';
import Typography from '../Typography/Typography';
import Answer from './Answer/Answer';
import { AVAILABLE_LANGUAGES } from '../../constants';
import { useAdminQuestion } from '../../context/AdminQuestionContext/AdminQuestionContext';
import QuestionIdInput from './QuestionIdInput/QuestionIdInput';

const DIFFICULTIES = {
  1: 1,
  2: 2,
  3: 3,
  5: 5,
  8: 8,
  13: 13,
  21: 21,
};

const QuestionForm = ({ submitQuestion, isCreate }) => {
  const classes = useStyles();
  const { question: adminQuestion } = useAdminQuestion();
  const [activeLanguage, setActiveLanguage] = useState(strings.getLanguage());

  const registerCustomField = (register, name) => root =>
    root !== null && register(root.querySelector(`input[name="${name}"]`));

  const { register, control, handleSubmit, errors, getValues } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    validateCriteriaMode: 'all',
    defaultValues: {
      ...adminQuestion,
    },
  });

  const countErrorOccurrences = useCallback(
    substring => {
      let counter = 0;

      counter += errors[`questionText${substring}`] ? 1 : 0;
      counter += errors[`explanation${substring}`] ? 1 : 0;

      if (errors?.answers) {
        counter += errors.answers.filter(answer => answer[`text${substring}`]).length;
      }
      return counter;
    },
    [errors],
  );

  const languageErrors = useMemo(() => {
    const obj = {};
    AVAILABLE_LANGUAGES.forEach(lang => {
      obj[lang] = countErrorOccurrences(capitalize(lang));
    });
    return obj;
  }, [countErrorOccurrences]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'answers',
  });
  const categoryLabels = useCategoryLabels();

  const onSubmit = formData => {
    submitQuestion({ data: formData });
  };

  const textFieldValidation = {
    required: strings.admin.question.errors.fieldRequired,
    maxLength: {
      value: 1024,
      message: strings.questionFeedback.messages.descriptionTooLong,
    },
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={classes.form} data-cy="edit-question-form">
      {isCreate && <QuestionIdInput register={register} errors={errors} />}
      <Select
        ref={registerCustomField(register, 'category.categoryId')}
        name="category.categoryId"
        items={categoryLabels}
        selected={adminQuestion?.category?.categoryId || null}
      >
        {strings.admin.questionDetail.category}
      </Select>
      <Select
        ref={registerCustomField(register, 'difficulty')}
        name="difficulty"
        items={DIFFICULTIES}
        selected={DIFFICULTIES[adminQuestion.difficulty]}
      >
        {strings.admin.questionDetail.difficulty}
      </Select>

      <LanguageBar
        onLanguageSelected={lang => setActiveLanguage(lang)}
        selectedLanguage={activeLanguage}
        className={classes.spacing}
        errorMap={languageErrors}
      />

      {AVAILABLE_LANGUAGES.map(id => {
        const capLanguage = capitalize(id);
        const isActiveLanguage = activeLanguage === id;
        return (
          <div key={id} className={classnames({ [classes.hidden]: !isActiveLanguage })}>
            <Typography extraBold variant="h4">
              {strings.admin.questionDetail.text}
            </Typography>
            <TextArea
              name={`questionText${capLanguage}`}
              ref={register({
                ...textFieldValidation,
              })}
              placeholder={strings.questionFeedback.descriptionPlaceholder}
              error={errors?.[`questionText${capLanguage}`]}
              className={classes.spacing}
            />
            {fields
              .map((answer, i) => ({
                ...answer,
                artificialId: i,
              }))
              .map(answer => (
                <Answer
                  key={answer.id}
                  answer={answer}
                  language={capLanguage}
                  isActiveLanguage={isActiveLanguage}
                  errors={errors}
                  register={register}
                  control={control}
                  getValues={getValues}
                  remove={remove}
                  textFieldValidation={textFieldValidation}
                />
              ))}

            <Button
              variant="link"
              onClick={() => {
                const languageTexts = {};
                AVAILABLE_LANGUAGES.forEach(lang => {
                  languageTexts[`text${capitalize(lang)}`] = '';
                });
                append({
                  answerId: null,
                  isCorrect: false,
                  ...languageTexts,
                });
              }}
              className={classes.addAnswerButton}
              data-cy={`add-answer-${id}`}
            >
              <AddIcon /> {strings.admin.question.addAnswer}
            </Button>

            <Typography extraBold variant="h5">
              {strings.admin.questionDetail.explanation}
            </Typography>

            <TextArea
              name={`explanation${capLanguage}`}
              ref={register({
                ...textFieldValidation,
              })}
              placeholder={strings.questionFeedback.descriptionPlaceholder}
              error={errors?.[`explanation${capLanguage}`]}
              className={classes.spacing}
            />
          </div>
        );
      })}
      <Button variant="contained" type="submit" data-cy="submit-question">
        {strings.forms.save}
      </Button>
    </form>
  );
};

export default QuestionForm;
