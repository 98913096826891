import React from 'react';
import { makeStyles } from '@material-ui/styles';
import isEmpty from 'lodash/isEmpty';
import confetti from 'canvas-confetti';
import Typography from '../components/Typography/Typography';
import Loader from '../components/Loader/Loader';
import strings from '../i18n/localization';

const useStyles = makeStyles(() => ({
  congratulationContainer: {
    paddingTop: '15%',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

const confettiBlast = () => {
  confetti({
    particleCount: 200,
    spread: 100,
    origin: { y: 0.6 },
  });
};

const AllQuestionsDoneMessage = () => {
  const classes = useStyles();
  return (
    <div className={classes.congratulationContainer}>
      <Typography variant="h3">{strings.card.allQuestionsDone}</Typography>
    </div>
  );
};

const createQuestionGuard = useContext => ({ children, fallback = <Loader />, allowOldData = true }) => {
  const { data, pending, error } = useContext();

  const emptyData = isEmpty(data);

  if (pending) {
    if (allowOldData && !emptyData) {
      return children;
    }
    return fallback;
  }
  if (!emptyData) {
    return children;
  }
  if (error) {
    confettiBlast();
    return <AllQuestionsDoneMessage />;
  }
  return null;
};

export default createQuestionGuard;
