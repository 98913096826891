import React from 'react';
import strings from '../../i18n/localization';
import FullScreenDialog from '../FullScreenDialog/FullScreenDialog';
import QuestionForm from '../QuestionForm/QuestionForm';
import { useAdminQuestionMutation } from '../../context/AdminQuestionContext/AdminQuestionContext';

const AdminQuestionDialog = ({ open, onCloseButton, isCreate = false, afterMutation }) => {
  const { question: adminQuestion, updateQuestion } = useAdminQuestionMutation({
    isCreate,
  });

  const submitQuestion = async ({ data: formData }) => {
    const data = await updateQuestion({
      data: {
        questionId: isCreate ? undefined : adminQuestion.questionId,
        enabled: true,
        ...formData,
      },
    });
    if (afterMutation) {
      afterMutation(data);
    }
  };

  return (
    <FullScreenDialog
      open={open}
      onCloseButton={onCloseButton}
      title={isCreate ? strings.admin.question.new : `#${adminQuestion.questionId}`}
    >
      {open && <QuestionForm isCreate={isCreate} submitQuestion={submitQuestion} />}
    </FullScreenDialog>
  );
};

export default AdminQuestionDialog;
