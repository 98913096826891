import React from 'react';
import { Grid } from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import strings from '../../../i18n/localization';
import routes from '../../../routes';
import { getAwardId, getLevel } from '../../../service/awards';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import AwardBadge from '../../../components/AwardBadge/AwardBadge';

const Awards = ({ awards }) => {
  const recentAwards = orderBy(awards, ['obtainedOn'], ['desc']).slice(0, 3);

  return (
    <>
      <Typography variant="h2" gutterBottom="m">
        {strings.awards.recent}
      </Typography>
      <Grid container justify="center" spacing={6}>
        {recentAwards.map(({ id }) => (
          <Grid item key={id} data-cy="award-badge-item">
            <AwardBadge id={getAwardId(id)} level={getLevel(id)} obtained />
          </Grid>
        ))}
        <Grid container item>
          <Button variant="link" size="large" to={routes.AWARDS} data-cy="go-to-awards-btn">
            {strings.awards.seeAll}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Awards;
