import React from 'react';
import { Grid, Container } from '@material-ui/core';
import useStyles from './QuestionLayout.styles';

const QuestionLayout = ({ children }) => {
  const classes = useStyles();

  return (
    <Grid container direction="column" className={classes.layout} justify="center" alignItems="center">
      <Grid item className={classes.quizGrid}>
        <Container className={classes.quizContainer}>{children}</Container>
      </Grid>
    </Grid>
  );
};

export default QuestionLayout;
