import React from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import routes from '../../routes';
import useStyles from './AppLayout.styles';
import Header from './Header/Header';
import Footer from './Footer/Footer';
import Loader from '../Loader/Loader';
import Typography from '../Typography/Typography';
import { UserGuard } from '../../context/UserContext/UserContext';
import strings from '../../i18n/localization';
import BackgroundBar from '../BackgroundBar/BackgroundBar';

const LoadingUser = ({ className }) => (
  <div className={className}>
    <Loader /> <Typography>{strings.userProfile.loadingProfile}</Typography>
  </div>
);

const AppLayout = ({ children }) => {
  const { pathname } = useLocation();
  const classes = useStyles();

  const isDashboard = pathname.includes(routes.OVERVIEW);

  const isSixInARow = pathname.includes(routes.PLAY_SIX) && !pathname.includes(routes.PLAY_SIX_START);
  const isQuestions = pathname.includes(routes.QUESTIONS) || isSixInARow;

  return (
    <UserGuard allowOldData={false} fallback={<LoadingUser className={classes.loadingUser} />}>
      <div className={classes.root}>
        <Header isDashboard={isDashboard} isQuestions={isQuestions} />
        {!isDashboard && !isQuestions && <BackgroundBar />}
        <div
          className={classnames(
            { [classes.lessPadding]: isQuestions, [classes.zIndex]: !isQuestions },
            classes.content,
          )}
        >
          {children}

          {!isQuestions && <Footer />}
        </div>
      </div>
    </UserGuard>
  );
};

export default AppLayout;
