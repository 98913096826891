import React from 'react';
import useStyles from './VerticalAxisTick.styles';

const VerticalAxisTick = ({ x, y, payload }) => {
  const classes = useStyles();
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={6} textAnchor="end" className={classes.textNode}>
        {payload.value}
      </text>
    </g>
  );
};

export default VerticalAxisTick;
