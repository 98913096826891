import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ListItem } from '@material-ui/core';
import classnames from 'classnames';

import Typography from '../../../../Typography/Typography';
import useStyles from './SideBarLink.styles';
import { useUser } from '../../../../../context/UserContext/UserContext';

const SideBarLink = ({ path, label, onClick, allowedRole = [], ...rest }) => {
  const classes = useStyles();
  const location = useLocation();
  const {
    user: { roles },
  } = useUser();

  if (!roles.some(actualUserRole => allowedRole.includes(actualUserRole))) {
    return null;
  }

  const isLinkActive = location.pathname.includes(path);

  return (
    <ListItem
      component={path && Link}
      to={path}
      classes={{
        root: classnames(classes.link, {
          [classes.linkActive]: isLinkActive,
        }),
      }}
      onClick={onClick}
      {...rest}
    >
      <Typography uppercase color="textPrimary">
        {label}
      </Typography>
    </ListItem>
  );
};

export default SideBarLink;
