import React, { useEffect, useState } from 'react';
import { Paper } from '@material-ui/core';
import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { GAME_STATE, QUESTIONS } from '../../../../pages/Play/SixInARow/config';
import { useQuestion, useQuestionSubmission } from '../../../../context/QuestionContext/QuestionContext';
import useStyles from './QuestionStepper.styles';

const QuestionStepper = ({ className, steps = QUESTIONS, ...rest }) => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [hasFailed, setHasFailed] = useState(false);
  const { pending } = useQuestion();
  const { submission } = useQuestionSubmission();

  useEffect(() => {
    if (activeStep <= QUESTIONS) {
      if (submission.state === GAME_STATE.FAILED) {
        setHasFailed(true);
      } else if (submission.state === GAME_STATE.DONE) {
        setActiveStep(activeStep + 1);
      } else if (submission.state === GAME_STATE.IN_PROGRESS && !pending) {
        setActiveStep(activeStep + 1);
      }
    }
  }, [submission, setActiveStep, activeStep, setHasFailed, pending]);

  return (
    <Paper square elevation={0} className={classnames(classes.root, className)} {...rest}>
      <div className={classes.dots}>
        {[...new Array(steps)].map((_, index) => (
          <div
            key={uniqueId()}
            className={classnames(classes.dot, {
              [classes.dotActive]: index === activeStep,
              [classes.dotPast]: index < activeStep,
              [classes.dotFailed]: index === activeStep && hasFailed,
            })}
          />
        ))}
      </div>
    </Paper>
  );
};

export default QuestionStepper;
