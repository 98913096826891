import React from 'react';
import classnames from 'classnames';
import useStyles from './AppBar.styles';

const AppBar = ({ isDashboard, isQuestions, children }) => {
  const classes = useStyles();

  return (
    <>
      <header
        className={classnames(
          { [classes.bgColorDashboard]: isDashboard, [classes.bgColor]: !isDashboard, [classes.noBg]: isQuestions },
          classes.appBar,
        )}
      >
        {children}
      </header>
      <div className={classes.appBarPseudoElement} />
    </>
  );
};

export default AppBar;
