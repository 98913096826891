import React from 'react';
import Pagination from '@material-ui/lab/Pagination';
import { Divider, Box } from '@material-ui/core';
import useStyles from './Paginator.styles';

const Paginator = ({ noOfPages, page, onChange, defaultPage }) => {
  const classes = useStyles();

  return (
    <>
      <Divider />
      <Box component="span">
        <Pagination
          count={noOfPages}
          page={page}
          onChange={onChange}
          defaultPage={defaultPage}
          color="primary"
          size="large"
          showFirstButton
          showLastButton
          classes={{ ul: classes.paginator }}
          data-cy="pagination"
        />
      </Box>
    </>
  );
};

export default Paginator;
