import { makeStyles } from '@material-ui/core/styles';

export default makeStyles(theme => ({
  errorFeedback: {
    color: theme.palette.error.main,
  },
  button: {
    marginTop: theme.spacing(6),
  },
}));
