import React from 'react';
import { Container } from '@material-ui/core';
import strings from '../../i18n/localization';
import Typography from '../Typography/Typography';
import useStyles from './FeedbackContentPositive.styles';
import ScoreBadge from './ScoreBadge/ScoreBadge';

const FeedbackContentPositive = ({ submission }) => {
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <Typography variant="h1" color="textSecondary" data-cy="title-positive">
        {strings.feedback.bravo}
      </Typography>
      <ScoreBadge
        submission={submission}
        subtitle={
          <Typography variant="h1" color="primary">
            {`${submission.rank}. ${strings.feedback.place}`}
          </Typography>
        }
      />
    </Container>
  );
};

export default FeedbackContentPositive;
