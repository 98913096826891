import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  goButtonContainer: {
    flexGrow: 1,
    marginTop: 'auto',
  },
  goButton: {
    width: '100%',
  },
}));

export default useStyles;
