import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  textField: {
    backgroundColor: theme.palette.text.secondary,
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(3, 0, 4),
  },
  spacing: {
    margin: theme.spacing(2),
  },
  roleDropdown: {
    flexGrow: 1,
    minWidth: 166,
  },
  tenantText: {
    margin: theme.spacing(0, 4, 2),
    flexGrow: 1,
    '&& input': { padding: 10 },
    transition: '0.2s ease-in',
  },
  contractIdText: {
    margin: theme.spacing(0, 4, 2),
    flexGrow: 1,
    '&& input': { padding: 10 },
    transition: '0.2s ease-in',
  },
  roleContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(3, 0),
  },
  roleInput: {
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
  },
}));

export default useStyles;
