import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  avatar: {
    margin: `0 ${-theme.spacing(4)}px`,
  },
  avatarContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

export default useStyles;
