import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  inputField: {
    marginBottom: theme.spacing(7),
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
  },
  error: {
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
