import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  container: {
    maxWidth: 600,
    margin: '0 auto',
  },
  error: {
    color: 'crimson',
  },
}));

export default useStyles;
