import React, { forwardRef } from 'react';
import classnames from 'classnames';
import { TextareaAutosize } from '@material-ui/core';

import useStyles from './TextArea.styles';
import Typography from '../../Typography/Typography';

const TextArea = forwardRef(({ children, error, className, ...rest }, ref) => {
  const classes = useStyles();
  return (
    <div className={classnames(classes.inputField, className)}>
      <Typography variant="h5" component="label" className={classes.label}>
        {children}
        <TextareaAutosize ref={ref} className={classes.input} {...rest} />
      </Typography>
      {error && (
        <Typography color="error" className={classes.error}>
          {error.message}
        </Typography>
      )}
    </div>
  );
});

export default TextArea;
