import promiseCoordinator from '../../../service/promiseCoordinator';
import { CYAP_SYNC_API_URL, POST } from '../constants';
import useAxios from '../../useAxios';

const coordinator = promiseCoordinator();

const useOnboardUserOnCyarp = () => {
  const config = {
    method: POST,
    url: CYAP_SYNC_API_URL,
    headers: { 'Content-Type': 'application/json' },
  };

  const [{ data, pending, error }, submit] = useAxios(coordinator, config);

  return {
    data,
    pending,
    error,
    submit,
  };
};
export default useOnboardUserOnCyarp;
