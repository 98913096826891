import React from 'react';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import RankingTableRow from './RankingTableRow/RankingTableRow';

const RankingTable = ({ user, ranking, className }) => (
  <div className={className}>
    <AutoSizer>
      {({ height, width }) => (
        <List height={height} width={width} itemCount={ranking.length} itemSize={67}>
          {({ index, style }) => {
            const rank = ranking[index];
            const isHighlighted = user.nickName === rank.nickName;
            return (
              <div style={style}>
                <RankingTableRow rank={rank} highlighted={isHighlighted} />
              </div>
            );
          }}
        </List>
      )}
    </AutoSizer>
  </div>
);

export default RankingTable;
