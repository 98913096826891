import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

const popupKeys = {
  RANKING: 'ranking',
  AVATARS: 'avatars',
  ADMIN_FEEDBACK: 'adminFeedback',
  ADMIN_PREVIEW: 'adminPreview',
  ADMIN_EDIT: 'adminEdit',
  ADMIN_CREATE: 'adminCreate',
  TENANT_DELETE: 'tenantDelete',
  TENANT_DISABLE: 'tenantDisable',
};

const historyStateKey = 'secured-open-dialogs_';

const usePopupControl = popupKey => {
  const history = useHistory();
  const { state } = history.location;
  const [popupIsOpen, setIsOpen] = useState(false);
  const combinedPopupKey = `${historyStateKey}${popupKey}`;

  const stateObject = state?.[combinedPopupKey];

  useEffect(() => {
    setIsOpen(stateObject || false);
  }, [stateObject]);

  const setPopupState = isOpen => {
    if (isOpen) {
      history.push(history.location.pathname, { ...state, [combinedPopupKey]: isOpen });
    } else {
      history.goBack();
    }
  };

  const openPopup = () => setPopupState(true);

  const closePopup = () => setPopupState(false);

  return { popupIsOpen, openPopup, closePopup };
};

export default usePopupControl;
export { popupKeys };
