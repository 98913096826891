import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  link: {
    padding: theme.spacing(4, 6),
    cursor: 'pointer',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey.c100,
    },
  },
  linkActive: {
    backgroundColor: theme.palette.grey.c100,
  },
  linkText: {
    padding: theme.spacing(4, 6),
    color: theme.palette.text.primary,
    transition: theme.transitions.create(['color']),
  },
  linkTextActive: {
    color: theme.palette.text.primary,
  },
}));

export default useStyles;
