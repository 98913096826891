import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  layout: {
    flexWrap: 'nowrap',
    position: 'fixed',
    top: theme.height.header,
    zIndex: theme.layers.questionCard,

    bottom: theme.height.questionFooter + theme.padding.vertical.xs * 2,
    width: `calc(100% - ${theme.padding.horizontal.xs}px)`,
    maxWidth: `${theme.fixedWidth.maxWidth - theme.padding.horizontal.xs}px`,

    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      bottom: theme.height.questionFooter + theme.padding.vertical.sm * 2,
      width: `calc(100% - ${theme.padding.horizontal.sm}px)`,
      maxWidth: `${theme.fixedWidth.maxWidth - theme.padding.horizontal.sm}px`,
    },

    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      bottom: theme.height.questionFooter + theme.padding.vertical.md * 2,
      width: `calc(100% - ${theme.padding.horizontal.md * 2}px)`,
      maxWidth: `${theme.fixedWidth.maxWidth - theme.padding.horizontal.md * 2}px`,
    },
  },
  scoreGrid: {
    height: 70,
    width: '100%',
    alignContent: 'center',
    justifyContent: 'center',
  },
  quizGrid: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    zIndex: theme.layers.questionCard,
  },
  quizContainer: {
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(4),
    height: '100%',
    width: '100%',
  },
  congratulationContainer: {
    paddingTop: '15%',
    alignContent: 'center',
    justifyContent: 'center',
  },
}));

export default useStyles;
