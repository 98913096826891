import React from 'react';
import { Link } from 'react-router-dom';
import { Button as ButtonBase } from '@material-ui/core';
import PropTypes from 'prop-types';
import Typography from '../Typography/Typography';
import useStyles from './Button.styles';

const buttonVariantLookup = { link: 'link', text: 'text', outlined: 'outlined', contained: 'contained' };

const Button = ({ children, variant, to, onClick, color = 'primary', iconLeft, iconRight, ...rest }) => {
  const classes = useStyles();
  const buttonVariant = buttonVariantLookup[variant] || buttonVariantLookup.text;

  if (buttonVariant === buttonVariantLookup.link) {
    return (
      <Typography
        type={onClick && 'button'}
        classes={{ root: classes.link }}
        component={to ? Link : 'button'}
        color={color}
        to={to}
        onClick={onClick}
        {...rest}
      >
        {iconLeft}
        <span className={classes.underline}>{children}</span>
        {iconRight}
      </Typography>
    );
  }

  return (
    <ButtonBase color={color} variant={buttonVariant} onClick={onClick} component={to && Link} to={to} {...rest}>
      {children}
    </ButtonBase>
  );
};

Button.propTypes = {
  variant: PropTypes.oneOf(Object.keys(buttonVariantLookup)),
};

export default Button;
