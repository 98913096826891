import React, { useEffect, useState } from 'react';
import strings from '../../../i18n/localization';
import useStyles from './RankingTableDialog.styles';
import FullScreenDialog from '../../../components/FullScreenDialog/FullScreenDialog';
import Typography from '../../../components/Typography/Typography';
import { useCategoryLabels } from '../../../context/selectors/categories';
import Filter from './Filter/Filter';
import usePopupControl, { popupKeys } from '../../../hooks/popup/usePopupControl';
import { useUser } from '../../../context/UserContext/UserContext';
import { useRanking, RankingGuard } from '../../../context/RankingContext/RankingContext';
import RankingTable from './RankingTable/RankingTable';

const RankingTableDialog = () => {
  const classes = useStyles();
  const { user } = useUser();

  const [timeframe, setTimeframe] = useState();
  const [category, setCategory] = useState();
  const { popupIsOpen, closePopup } = usePopupControl(popupKeys.RANKING);

  const timeframes = {
    0: strings.ranking.ofAllTime,
    30: strings.ranking.thisMonth,
  };
  const noCategory = { 0: strings.category.all };

  const categoryLabels = useCategoryLabels();
  const categories = { ...noCategory, ...categoryLabels };

  const { ranking, loadRanking } = useRanking();

  useEffect(() => {
    if (popupIsOpen) {
      loadRanking(category, timeframe);
    }
  }, [popupIsOpen, category, timeframe, loadRanking]);

  const onChangeTimeframe = tf => {
    const tfKey = tf === '0' ? null : tf;
    setTimeframe(tfKey);
  };

  const onChangeCategory = cat => {
    const catKey = cat === '0' ? null : cat;
    setCategory(catKey);
  };

  return (
    <FullScreenDialog
      open={popupIsOpen}
      title={strings.ranking.ranking}
      subTitle={strings.ranking.company}
      onCloseButton={closePopup}
    >
      <div className={classes.filterBox}>
        <Filter
          name={strings.ranking.filterCategory}
          items={categories}
          selected={category}
          onItemSelected={onChangeCategory}
        />
        <Filter
          name={strings.ranking.filterRange}
          items={timeframes}
          selected={timeframe}
          onItemSelected={onChangeTimeframe}
        />
      </div>

      <div className={classes.tableHead}>
        <div className={classes.tableRow}>
          <Typography
            component="div"
            align="left"
            extraBold
            uppercase
            className={classes.headerPadding}
            data-cy="title-ranking"
          >
            {strings.ranking.position}
          </Typography>

          <Typography component="div" align="right" extraBold className={classes.headerPadding}>
            {strings.ranking.score}
          </Typography>
        </div>
      </div>
      <RankingGuard>
        <RankingTable user={user} ranking={ranking} className={classes.tableBody} />
      </RankingGuard>
    </FullScreenDialog>
  );
};

export default RankingTableDialog;
