import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: '60px !important', // overrides inline styles from mui
    ...theme.fixedWidth,
  },
  rootFullScreen: {
    ...theme.fixedWidth,
  },
  shape: {
    borderBottomRightRadius: theme.spacing(7),
    borderBottomLeftRadius: theme.spacing(7),
  },
  footer: {
    marginBottom: theme.spacing(5),
  },
  container: {
    backgroundColor: theme.palette.text.primary,
    textAlign: 'center',
    padding: theme.spacing(4, 7, 5, 7),
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  iconContainer: {
    padding: '100px 20px 20px 20px',
  },
  spacer: {
    flexGrow: 2,
  },
  buttonContainer: {
    maxWidth: theme.spacing(8) * 5,
    margin: '0 auto',
    marginBottom: theme.spacing(7),
  },
  button: {
    width: '100%',
  },
  icon: {
    marginBottom: theme.spacing(5),
  },
  description: {
    padding: theme.spacing(4),
  },
}));

export default useStyles;
