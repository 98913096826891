import React from 'react';

import CardContent from '@material-ui/core/CardContent';
import { Divider, Typography } from '@material-ui/core';
import useStyles from '../TenantWidget.styles';

const OnboardedTenantAdmins = ({ onboardedTenantAdmins }) => {
  const classes = useStyles();

  return (
    <>
      <Divider className={classes.divider} />
      <Typography>
        <span className={classes.tenantAdmins}>
          <b>Onboarded</b>
        </span>
      </Typography>

      {onboardedTenantAdmins.onboardedAdmins.map(onboardedAdmin => (
        <div key={onboardedAdmin.creationTime}>
          <CardContent className={classes.tenantInfo}>
            <Typography className={classes.infoContent}>
              {onboardedAdmin.firstName && (
                <span>
                  <b>First Name: </b> <span>{`${onboardedAdmin.firstName} `}</span>
                </span>
              )}
            </Typography>
            <Typography className={classes.infoContent}>
              {onboardedAdmin.lastName && (
                <span>
                  <b>Last Name: </b> <span>{onboardedAdmin.lastName}</span>
                </span>
              )}
            </Typography>
            <Typography className={classes.infoContent}>
              {onboardedAdmin.creationTime && (
                <span>
                  <b>First Login: </b>
                  <span>{new Date(onboardedAdmin.creationTime).toLocaleDateString()}</span>
                </span>
              )}
            </Typography>
          </CardContent>
        </div>
      ))}
    </>
  );
};

export default OnboardedTenantAdmins;
