import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  link: {
    ...theme.typography.link,
    color: theme.palette.primary.main,
  },
  underline: {
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
}));

export default useStyles;
