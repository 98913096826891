import { useEffect, useState } from 'react';
import LocalizedStrings from 'react-localization';

import translationEn from './locales/en/translations.json';
import translationDe from './locales/de/translations.json';
import translationFr from './locales/fr/translations.json';
import translationIt from './locales/it/translations.json';

const strings = new LocalizedStrings({
  en: translationEn,
  de: translationDe,
  fr: translationFr,
  it: translationIt,
});

// used because the library cannot handle updates itself and relies on frequent components re-renders instead
// This is only a quick fix and no proper implementation.
// This can be dropped when react-localization has been replaced with a proper i18n library
export const languageChangeNotifier = {
  ref: 0,
  listeners: {},
  on: cb => {
    languageChangeNotifier.ref += 1;
    const { ref } = languageChangeNotifier;
    languageChangeNotifier.listeners[ref] = cb;
    return ref;
  },
  off: ref => {
    delete languageChangeNotifier.listeners[ref];
  },
  trigger: () => {
    Object.values(languageChangeNotifier.listeners).forEach(cb => cb());
  },
};

// only used due to localization library issue
export const useManualLanguageRerender = () => {
  const [, setManualChangeListener] = useState(null);
  useEffect(() => {
    const id = languageChangeNotifier.on(() => {
      setManualChangeListener(Math.random());
    });
    return () => {
      languageChangeNotifier.off(id);
    };
  }, []);
};

export default strings;
