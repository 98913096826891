import { useReducer } from 'react';
import { produce } from 'immer';
import { fail, succeed } from '../service/reducer';
import createAction from '../service/createAction';
import useDataLoad from '../service/useDataLoad';
import useFetchNoAuth from '../service/useFetchNoAuth';

const defaultContext = {
  data: null,
  error: null,
  pending: false,
};

const LOADED = 'LOADED';
const FAILED = 'FAILED';
const PENDING = 'PENDING';

const loaded = createAction(LOADED);
const failed = createAction(FAILED);
const pending = createAction(PENDING);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case LOADED:
      return succeed(action.payload);
    case FAILED:
      return fail(action.payload);
    case PENDING:
      draft.pending = true;
      break;
    default:
      break;
  }
});

const useAxiosNoAuth = (coordinator, fetchConfig) => {
  const [contextData, dispatch] = useReducer(reducer, defaultContext);
  const fetch = useFetchNoAuth(coordinator);
  const load = useDataLoad(fetch, fetchConfig, dispatch, loaded, failed, pending);
  return [contextData, load];
};

export default useAxiosNoAuth;
