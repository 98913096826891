import { makeStyles } from '@material-ui/styles';

const checkboxSize = 18;
const checkboxRadius = 1;
const checkboxCheckedInnerCircleCrop = 4;
const transition = '0.1s ease-in-out';

const useStyles = makeStyles(theme => ({
  answerList: {
    display: 'flex',
    flexDirection: 'column',
  },
  answerUnorderedList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  answerContainer: {
    display: 'flex',
    padding: theme.spacing(5, 6),
    transition: `background ${transition}`,
    wordBreak: 'break-word',
  },
  answerQuestionedContainer: {
    marginBottom: theme.spacing(3),
  },
  selectedAnswer: {
    backgroundColor: theme.palette.primary.c100,
  },
  correctAnswer: {
    backgroundColor: theme.palette.success.extraLight,
  },
  selectedCorrectIcon: {
    color: theme.palette.success.main,
  },
  selectedWrongIcon: {
    color: theme.palette.error.main,
  },
  iconFiller: {
    width: '1.5em',
  },
  wrongAnswer: {
    backgroundColor: theme.palette.error.extraLight,
  },
  selectedText: {
    fontStyle: 'italic',
  },
  unselectedText: {
    color: theme.palette.text.primaryLight,
  },
  feedbackContainer: {
    paddingRight: theme.spacing(4),
    flexShrink: 0,
  },
  checkbox: {
    border: 'none',
    appearance: 'none',
    borderRadius: checkboxSize / 2,
    height: checkboxSize,
    width: checkboxSize,
    background: theme.palette.primary.main,
    transition: `box-shadow ${transition}`,
    '&:checked': {
      boxShadow: `0px 0px 0px ${checkboxRadius}px ${theme.palette.primary.main}, inset 0px 0px 0px ${checkboxCheckedInnerCircleCrop}px white`,
    },
  },
}));

export default useStyles;
