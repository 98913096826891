export const LEVELS = {
  BRONZE: 'Bronze',
  SILVER: 'Silver',
  GOLD: 'Gold',
};

export const getAwardId = id =>
  Object.values(LEVELS).reduce((aggregate, level) => aggregate.replace(level, ''), id || '');

export const getLevel = id => {
  if (!id) {
    return LEVELS.BRONZE;
  }
  return Object.values(LEVELS).find(levelId => id.includes(levelId));
};
