import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(3),
  },
  iphoneLayout: {
    width: 375,
    height: 675, // ~iPhone X height
    border: '2px solid',
    margin: '0 auto',
  },
  maxWidth: theme.fixedWidth,
}));

export default useStyles;
