import React from 'react';

import useStyles from './Separator.styles';

const Separator = () => {
  const classes = useStyles();
  return <hr className={classes.line} />;
};

export default Separator;
