import { ENDPOINTS, POST } from '../constants';
import useAxios from '../../useAxios';
import promiseCoordinator from '../../../service/promiseCoordinator';

const coordinator = promiseCoordinator();

const useUserInvite = () => {
  const config = {
    method: POST,
    url: ENDPOINTS.INVITE,
  };

  const [{ data, pending, error }, submit] = useAxios(coordinator, config);

  return { data, pending, error, submit };
};

export default useUserInvite;
