import React, { useEffect } from 'react';
import { UserManager } from 'oidc-client';

const userManager = new UserManager();

const SilentCallback = () => {
  useEffect(() => {
    userManager.signinSilentCallback();
  }, []);

  return <></>;
};

export default SilentCallback;
