import React from 'react';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Link } from 'react-router-dom';
import useSearchContext from '../../../context/SearchEngine/SearchContext';
import Typography from '../../../components/Typography/Typography';
import useStyles from './QuestionList.styles';
import routes from '../../../routes';
import strings from '../../../i18n/localization';
import PendingFeedbackBadge from '../../../components/PendingFeedbackBadge/PendingFeedbackBadge';
import FullWidthContainer from '../../../components/FullWidthContainer/FullWidthContainer';

const QuestionList = () => {
  const classes = useStyles();
  const { filteredItems } = useSearchContext();
  if (!filteredItems || isEmpty(filteredItems)) {
    return <Typography data-cy="no-items-found">No items found</Typography>;
  }

  return (
    <FullWidthContainer component="ul" className={classes.list}>
      {filteredItems.map((item, idx) => {
        const isEven = idx % 2 === 0;
        return (
          <li
            key={item.questionId}
            className={classnames(classes.item, { [classes.even]: isEven })}
            data-cy={`question-${item.questionId}`}
          >
            <Link to={`${routes.ADMIN_QUESTIONS}/${item.questionId}`} data-cy="link">
              <div className={classes.questionHeader}>
                <Typography variant="h5" className={classes.questionId}>
                  #{item.questionId}
                </Typography>
                {item.pendingFeedbackCount !== 0 && (
                  <PendingFeedbackBadge number={item.pendingFeedbackCount} className={classes.icon} />
                )}
              </div>
              <Typography>{item.questionText}</Typography>
              <Typography variant="body3" bold>
                {strings.adminQuestions.difficulty}: {item.difficulty}
              </Typography>
            </Link>
          </li>
        );
      })}
    </FullWidthContainer>
  );
};

export default QuestionList;
