import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  loadingUser: {
    textAlign: 'center',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    backgroundColor: theme.palette.background.default,
    zIndex: theme.layers.content,
    ...theme.fixedWidth,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    padding: `${theme.padding.vertical.xs}px ${theme.padding.horizontal.xs}px`,
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      padding: `${theme.padding.vertical.sm}px ${theme.padding.horizontal.sm}px`,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: `${theme.padding.vertical.sm}px ${theme.padding.horizontal.md}px`,
    },
  },
  zIndex: {
    zIndex: theme.layers.content,
  },
  lessPadding: {
    padding: `${theme.padding.vertical.xs}px ${theme.padding.horizontal.xs / 2}px`,
    [theme.breakpoints.up(theme.breakpoints.values.xs)]: {
      padding: `${theme.padding.vertical.sm}px ${theme.padding.horizontal.sm / 2}px`,
    },
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
      padding: `${theme.padding.vertical.sm}px ${theme.padding.horizontal.md}px`,
    },
  },
}));

export default useStyles;
