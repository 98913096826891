import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as goodIcon } from './assets/mascot_good.svg';
import { ReactComponent as badIcon } from './assets/mascot_bad.svg';

const sizeLookupMap = {
  S: '50px',
  M: '80px',
  L: '100px',
};
const icons = {
  positive: goodIcon,
  negative: badIcon,
};

const ReactionIcon = ({ icon, size, ...rest }) => {
  const Component = icons[icon];
  const selectedSize = sizeLookupMap[size] || sizeLookupMap.M;
  return <Component {...rest} width={selectedSize} height={selectedSize} />;
};

ReactionIcon.propTypes = {
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  size: PropTypes.oneOf(Object.keys(sizeLookupMap)),
};

export default ReactionIcon;
