import React from 'react';
import PropTypes from 'prop-types';

import { ReactComponent as LogoIconSvg } from './assets/i_logo.svg';

const sizeLookupMap = {
  S: '24px',
  L: '54px',
};

const LogoIcon = ({ size, ...rest }) => (
  <LogoIconSvg {...rest} width={sizeLookupMap[size]} height={sizeLookupMap[size]} />
);

LogoIcon.propTypes = {
  size: PropTypes.oneOf(Object.keys(sizeLookupMap)),
};

export default LogoIcon;
