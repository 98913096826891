import React from 'react';
import { Grid, Typography, Button, Box, Checkbox, Container, ThemeProvider, Divider, Avatar } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import ReCAPTCHA from 'react-google-recaptcha';
import Themes from '../../themes';
import useStyles from './SelfRegistration.styles';
import strings, { useManualLanguageRerender } from '../../i18n/localization';
import * as Validator from './validator';
import { ControlledRegistrationField } from './ControlledRegistrationField';
import useSelfRegistration from '../../hooks/api/registration/useSelfRegistration';
import { useAddMessage } from '../../context/MessageContext/MessageContext';
import LogoIcon from '../../components/LogoIcon/LogoIcon';
import { FooterLogos } from './FooterLogos';
import { TermsAndConditions } from './TermsAndConditions';
import { SelfRegistrationIntroText } from './SelfRegistrationIntroText';

export const SelfRegistration = () => {
  const [errorLengthFirstName, setErrorLengthFirstName] = React.useState(false);
  const [errorLengthLastName, setErrorLengthLastName] = React.useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
  const [errorLengthCompanyName, setErrorLengthCompanyName] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [policyNrError, setPolicyNrError] = React.useState(false);
  const [urlError, setUrlError] = React.useState(false);
  const [hasNotDefaultDomain, setHasNotDefaultDomain] = React.useState(false);
  const [isChecked, setIsChecked] = React.useState(false);
  const [checkedAtLeastOnce, setCheckedAtLeastOnce] = React.useState(false);
  const [validForm, setValidForm] = React.useState(false);
  const [storeToken, setStoreToken] = React.useState('');
  const [domains, setDomains] = React.useState('');
  const { pending: registrationPending, error: registrationError, submit: registrationSubmit } = useSelfRegistration();
  const classes = useStyles();
  const location = useLocation();
  const { addSuccessMessage, addErrorMessage } = useAddMessage();
  const history = useHistory();
  const componentDidMount = React.useRef(false);
  const token = new URLSearchParams(location.search).get('token');
  const [validCaptcha, setValidCaptcha] = React.useState(false);
  const { formState, control } = useForm();

  const siteCaptchaKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  useManualLanguageRerender();
  React.useEffect(() => {
    if (token) {
      setStoreToken(token);
      history.push('/self-registration');
    }
  }, [token, history]);

  const allInputFieldTouched = React.useCallback(() => {
    if (
      formState.touched.firstName &&
      formState.touched.lastName &&
      formState.touched.email &&
      formState.touched.companyName &&
      (formState.touched.domainName || hasNotDefaultDomain) &&
      formState.touched.policyNr
    ) {
      return true;
    }
    return false;
  }, [formState.touched, hasNotDefaultDomain]);

  React.useEffect(() => {
    if (!componentDidMount.current && registrationPending && registrationError) {
      componentDidMount.current = true;
      addErrorMessage(strings.forms.submitError);
    }
  }, [registrationPending, registrationError, addErrorMessage]);

  React.useEffect(() => {
    function isFormValid() {
      if (
        allInputFieldTouched() &&
        errorLengthFirstName === false &&
        errorLengthLastName === false &&
        errorLengthCompanyName === false &&
        emailError === false &&
        urlError === false &&
        policyNrError === false &&
        isChecked === true &&
        validCaptcha === true
      ) {
        return true;
      }
      return false;
    }
    if (isFormValid()) {
      setValidForm(true);
    } else {
      setValidForm(false);
    }
  }, [
    allInputFieldTouched,
    errorLengthFirstName,
    errorLengthLastName,
    errorLengthCompanyName,
    emailError,
    policyNrError,
    isChecked,
    urlError,
    validForm,
    validCaptcha,
  ]);

  const validateFirstName = firstName => {
    if (Validator.isWithinMaxLength(firstName)) {
      setErrorLengthFirstName(false);
    } else {
      setErrorLengthFirstName(true);
    }
  };
  const validateLastName = lastName => {
    if (Validator.isWithinMaxLength(lastName)) {
      setErrorLengthLastName(false);
    } else {
      setErrorLengthLastName(true);
    }
  };
  const validateEmail = email => {
    if (Validator.isValidEmail(email)) {
      setEmailError(false);
    } else {
      setEmailError(true);
    }
  };
  const validateCompanyName = companyName => {
    if (Validator.isWithinMaxLength(companyName)) {
      setErrorLengthCompanyName(false);
    } else {
      setErrorLengthCompanyName(true);
    }
  };
  const validatePolicyNr = policyNr => {
    if (Validator.isValidPolicyNr(policyNr)) {
      setPolicyNrError(false);
    } else {
      setPolicyNrError(true);
    }
  };
  const validateUrl = urls => {
    if (
      (hasNotDefaultDomain === true && !Validator.isValidURL(urls)) ||
      (hasNotDefaultDomain === false && Validator.isValidURL(urls)) ||
      (hasNotDefaultDomain === true && Validator.isValidURL(urls))
    ) {
      setUrlError(false);
    } else {
      setUrlError(true);
    }
  };

  const validateFields = data => {
    validateFirstName(data.get('firstName'));
    validateLastName(data.get('lastName'));
    validateEmail(data.get('email'));
    validateCompanyName(data.get('companyName'));
    validateUrl(domains);
    validatePolicyNr(data.get('policyNr'));
  };

  const handleSubmit = e => {
    if (submitButtonDisabled) {
      return;
    }
    setSubmitButtonDisabled({ disabled: true });
    e.preventDefault();
    const data = new FormData(e.currentTarget);
    if (storeToken) {
      registrationSubmit({
        data: {
          token: storeToken,
          firstName: data.get('firstName'),
          lastName: data.get('lastName'),
          email: data.get('email'),
          contractId: Validator.replaceDots(data.get('policyNr')),
          tenant: data.get('companyName'),
          website:
            hasNotDefaultDomain === true
              ? 'http://www.example.com'
              : data
                  .get('domainName')
                  .replaceAll(' ', '')
                  .replaceAll('\n', '')
                  .split(',')
                  .join(','),
        },
      }).then(res => {
        if (res.response.status === 200) {
          addSuccessMessage(strings.registration.dataSentSuccess);
          window.location.assign('/');
        }
        if (res.response.status === 400) {
          addErrorMessage(strings.forms.submitError);
        }
      });
    } else {
      addErrorMessage(strings.forms.submitError);
      setSubmitButtonDisabled({ disabled: false });
    }
    validateFields(data);
  };

  const handleFirstNameChange = e => {
    validateFirstName(e.target.value);
  };

  const handleLastNameChange = e => {
    validateLastName(e.target.value);
  };

  const handleEmailChange = e => {
    validateEmail(e.target.value);
  };

  const handleCompanyNameChange = e => {
    validateCompanyName(e.target.value);
  };

  const handleChangeDefaultDomain = event => {
    setHasNotDefaultDomain(event.target.checked);
  };

  const onChangeDomainsInput = e => {
    const val = e.target.value;
    setDomains(val);
    validateUrl(val);
  };

  const handlePolicyNrChange = e => {
    validatePolicyNr(e.target.value);
  };

  const handleChangeTermsAndConditions = e => {
    setIsChecked(e.target.checked);
    setCheckedAtLeastOnce(true);
  };

  const onRecaptchaChange = recaptchaToken => {
    setValidCaptcha(true);
    if (recaptchaToken === null) {
      setValidCaptcha(false);
    }
  };

  return (
    <ThemeProvider theme={Themes.tenant_board}>
      <Container maxWidth="lg">
        <Box>
          <div className={classes.buttonSpace}>
            <Avatar className={classes.logoContainer}>
              <LogoIcon size="S" className={classes.logoIcon} />
              <Typography variant="h6">SecurEd</Typography>
            </Avatar>{' '}
          </div>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <SelfRegistrationIntroText />
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ControlledRegistrationField
                  fieldName="firstName"
                  error={errorLengthFirstName}
                  control={control}
                  helperText={errorLengthFirstName === true ? strings.registration.requiredFields : null}
                  label={strings.registration.firstName}
                  onChange={handleFirstNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledRegistrationField
                  fieldName="lastName"
                  error={errorLengthLastName}
                  control={control}
                  helperText={errorLengthLastName === true ? strings.registration.requiredFields : null}
                  label={strings.registration.lastName}
                  onChange={handleLastNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledRegistrationField
                  fieldName="email"
                  error={emailError}
                  control={control}
                  helperText={emailError === true ? strings.registration.emailNotValid : null}
                  label={strings.registration.email}
                  onChange={handleEmailChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledRegistrationField
                  fieldName="companyName"
                  error={errorLengthCompanyName}
                  control={control}
                  helperText={errorLengthCompanyName === true ? strings.registration.requiredFields : null}
                  label={strings.registration.company}
                  onChange={handleCompanyNameChange}
                />
              </Grid>
              <Grid item xs={12}>
                <ControlledRegistrationField
                  fieldName="domainName"
                  error={urlError}
                  control={control}
                  helperText={urlError === true ? strings.registration.invalidUrl : null}
                  label={strings.registration.domainName}
                  placeholder="https://example.com"
                  onChange={onChangeDomainsInput}
                  defaultValue={hasNotDefaultDomain === true ? 'https://example.com' : null}
                  disabled={hasNotDefaultDomain}
                />
              </Grid>
              <Checkbox value="acceptFlag" color="primary" onChange={handleChangeDefaultDomain} required />
              <Typography variant="subtitle1" component="div" style={{ marginTop: '8px' }}>
                {strings.registration.noHomepage}
              </Typography>
              <Grid item xs={12}>
                <ControlledRegistrationField
                  fieldName="policyNr"
                  error={policyNrError}
                  control={control}
                  helperText={policyNrError === true ? strings.registration.policyNrRange : null}
                  label={strings.registration.policyNr}
                  placeholder={strings.registration.policyNrRange}
                  onChange={handlePolicyNrChange}
                />
              </Grid>
              <Typography variant="subtitle1" gutterBottom component="div" style={{ marginLeft: '12px' }}>
                {strings.registration.requiredFields}
              </Typography>
              <Grid item xs={12} className={classes.verticalSpacing}>
                <Checkbox value="acceptFlag" color="primary" onChange={handleChangeTermsAndConditions} />
                <TermsAndConditions />
                {!validForm && allInputFieldTouched() && checkedAtLeastOnce && validCaptcha ? (
                  <Typography variant="subtitle1" gutterBottom component="div">
                    <span style={{ color: 'red', marginLeft: '12px' }}>{strings.registration.formValid}</span>
                  </Typography>
                ) : null}
              </Grid>
              <span className={classes.verticalSpacing}>
                <ReCAPTCHA type="image" size="normal" sitekey={siteCaptchaKey} onChange={onRecaptchaChange} />
              </span>
            </Grid>
            <Grid item xs={12} className={classes.buttonSpace}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  window.location.assign('/');
                }}
                className={classes.buttonFixedSize}
              >
                {' '}
                {strings.registration.abort}
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={!validForm || !allInputFieldTouched() || submitButtonDisabled}
                className={classes.buttonFixedSize}
              >
                {strings.registration.send}
              </Button>
            </Grid>
            <Divider className={classes.dividerSpace} />
          </Box>
          <FooterLogos />
        </Box>
      </Container>
    </ThemeProvider>
  );
};
