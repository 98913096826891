import React from 'react';
import { Cached as FlipIcon } from '@material-ui/icons';

import strings from '../../../i18n/localization';
import Typography from '../../Typography/Typography';

import useStyles from './QuestionCardExplanation.styles';
import Button from '../../Button/Button';
import Card from '../../Card/Card';
import CardFooter from '../../CardFooter/CardFooter';
import { useQuestionSubmission } from '../../../context/QuestionContext/QuestionContext';

export default function QuestionCardExplanation({ showFeedback, flipCard }) {
  const classes = useStyles();
  const { submission } = useQuestionSubmission();

  return (
    <Card>
      <div className={classes.content}>
        <Typography variant="h4">{strings.card.explanation}</Typography>
        <Typography variant="body2" className={classes.explanationText} markdown>
          {submission && submission.explanation}
        </Typography>
      </div>

      <CardFooter onFeedbackClick={showFeedback}>
        <Button onClick={flipCard} classes={{ label: classes.flipCardButton }}>
          <FlipIcon />
          <Typography component="span" variant="h5">
            {strings.card.seeSolution}
          </Typography>
        </Button>
      </CardFooter>
    </Card>
  );
}
