import { useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useCategoryLabels } from './categories';
import { useScores } from '../ScoreContext/ScoreContext';

let isLoading = false;
const useLoadedScores = () => {
  const { scores, loadScores } = useScores();
  if (isEmpty(scores) && !isLoading) {
    loadScores();
    isLoading = true;
  }
  return scores;
};

const useLabeledScores = () => {
  const scores = useLoadedScores();
  const labelLookupMap = useCategoryLabels();
  const labeledScores = useMemo(
    () =>
      scores?.map(score => ({
        ...score,
        catLabel: labelLookupMap[score.catId],
      })),
    [scores, labelLookupMap],
  );
  return labeledScores;
};

const useScoresSum = () => {
  const scores = useLoadedScores();
  return useMemo(() => scores?.reduce((acc, score) => acc + score.value, 0), [scores]);
};

export { useLabeledScores, useScoresSum, useLoadedScores };
