import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    bottom: '60px !important', // overrides inline styles from mui
    ...theme.fixedWidth,
  },
  shape: {
    borderBottomRightRadius: theme.spacing(7),
    borderBottomLeftRadius: theme.spacing(7),
  },
  incorrect: {
    backgroundColor: theme.palette.text.primary,
  },
  correct: {
    backgroundColor: theme.palette.success.light,
  },
  footer: {
    marginBottom: theme.spacing(5),
  },
  container: {
    height: '100%',
    textAlign: 'center',
    padding: theme.spacing(4, 7, 5, 7),
    display: 'flex',
    flexDirection: 'column',
  },
  spacer: {
    flexGrow: 2,
  },
  button: {
    margin: `${theme.spacing(5)}px auto`,
    flexShrink: 0,
  },
}));

export default useStyles;
