import { useCallback } from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import createAction from '../../service/createAction';

const initialContext = {
  isOpen: false,
  currentMessage: null,
  messageQueue: [],
};

const messageVariant = {
  success: 'success',
  error: 'error',
};

const ADD_MESSAGE = 'ADD_MESSAGE';
const POP_MESSAGE = 'POP_MESSAGE';
const CLOSE_MESSAGES = 'CLOSE_MESSAGES';
const OPEN_MESSAGES = 'OPEN_MESSAGES';

const addMessageAction = createAction(ADD_MESSAGE);
const popMessageAction = createAction(POP_MESSAGE);
const closeMessagesAction = createAction(CLOSE_MESSAGES);
const openMessagesAction = createAction(OPEN_MESSAGES);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ADD_MESSAGE: {
      draft.isOpen = false;
      draft.messageQueue.push({ ...action.payload, key: new Date().getTime() });
      break;
    }
    case POP_MESSAGE: {
      const [currentMessage, ...messageQueue] = draft.messageQueue;
      draft.currentMessage = currentMessage;
      draft.messageQueue = messageQueue;
      break;
    }
    case CLOSE_MESSAGES:
      draft.isOpen = false;
      break;
    case OPEN_MESSAGES:
      draft.isOpen = true;
      break;
    default:
      break;
  }
});

const [MessageContext, useMessageContext] = createContext(reducer, initialContext);

const useAddMessage = () => {
  const { dispatch } = useMessageContext();

  const addSuccessMessage = useCallback(
    message => {
      dispatch(addMessageAction({ message, variant: messageVariant.success }));
    },
    [dispatch],
  );

  const addErrorMessage = useCallback(
    message => {
      dispatch(addMessageAction({ message, variant: messageVariant.error }));
    },
    [dispatch],
  );

  return {
    addSuccessMessage,
    addErrorMessage,
  };
};

const useDisplayMessage = () => {
  const { messageQueue: messages, isOpen, currentMessage, dispatch } = useMessageContext();

  const popMessage = useCallback(() => {
    dispatch(popMessageAction());
  }, [dispatch]);

  const openMessage = useCallback(() => {
    dispatch(openMessagesAction());
  }, [dispatch]);

  const closeMessage = useCallback(() => {
    dispatch(closeMessagesAction());
  }, [dispatch]);
  return {
    messages,
    currentMessage,
    isOpen,
    popMessage,
    openMessage,
    closeMessage,
  };
};

export default MessageContext;
export { useAddMessage, useDisplayMessage };
