import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    padding: '100px 20px 20px 20px',
    textAlign: 'center',
  },
  icon: {
    marginBottom: theme.spacing(5),
  },
  description: {
    padding: theme.spacing(4),
  },
}));

export default useStyles;
