import React, { useEffect } from 'react';
import { FormControl } from '@material-ui/core';
import { CloudDownloadSharp } from '@material-ui/icons';

import strings from '../../../../i18n/localization';
import Button from '../../../../components/Button/Button';
import Loader from '../../../../components/Loader/Loader';
import useStyles from '../../../AdminDashboard/CsvWidget/DownloadCsv/DownloadCsv.styles';
import downloadTextAsCsv from '../../../AdminDashboard/CsvWidget/DownloadCsv/downloadTextAsCsv';
import useCsvExportTenantBoard from '../../../../hooks/api/csv/useCsvExportTenantBoard';

const DownloadTenantCsv = () => {
  const classes = useStyles();
  const { data, pending, error, exportCsv } = useCsvExportTenantBoard();
  useEffect(() => {
    if (data) {
      downloadTextAsCsv('tenant-board.csv', data);
    }
  }, [data]);

  return (
    <FormControl fullWidth>
      {!pending && (
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={exportCsv}
          className={classes.centerSpacedDown}
          data-cy="download-csv"
        >
          <CloudDownloadSharp />
          {strings.admin.downloadTenantFile}
        </Button>
      )}
      {!error && pending && <Loader />}
    </FormControl>
  );
};

export default DownloadTenantCsv;
