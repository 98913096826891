import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '8px',
  },
  tile: {
    padding: '24px',
  },
  videoContainer: {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    top: '-99999px',
    left: '-99999px',
  },
  downloadLink: {
    marginTop: theme.spacing(5),
    cursor: 'pointer',
  },
  downloadIcon: {
    transform: 'translateY(3px)',
    marginRight: theme.spacing(3),
  },
}));

export default useStyles;
