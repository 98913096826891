import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  gridList: {
    margin: 'auto',
    cellHeight: 10,
  },
  container: {
    margin: 'auto',
  },
  grid: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '8px',
  },
  fullRowTile: {
    gridColumn: 'span 2',
    padding: '32px',
  },
  tile: {
    padding: '24px',
  },
}));

export default useStyles;
