import React, { useState, useEffect } from 'react';
import { CloudUpload as CloudUploadIcon } from '@material-ui/icons';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';

import { FormControl } from '@material-ui/core';

import strings from '../../../../i18n/localization';
import useCsvImport from '../../../../hooks/api/csv/useCsvImport';
import Button from '../../../../components/Button/Button';
import Typography from '../../../../components/Typography/Typography';

import useStyles from './UploadCsv.styles';

const UploadCsv = ({ onResponse }) => {
  const classes = useStyles();
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { error, response, uploadCsv } = useCsvImport();

  useEffect(() => {
    if (error) {
      onResponse({ error });
    } else if (response && onResponse) {
      onResponse(response);
    }
  }, [response, error, onResponse]);

  const uploadFile = () => {
    const formData = new FormData();
    formData.append('file', file);
    uploadCsv({ data: formData });
  };

  const onDrop = React.useCallback(acceptedFiles => {
    const acceptedFile = acceptedFiles[0];

    if (acceptedFile.name.includes('.csv')) {
      setFile(acceptedFile);
      setErrorMessage('');
    } else {
      setErrorMessage(strings.admin.errorWrongFileExtension);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <FormControl fullWidth>
      <Typography variant="body2" extraBold>
        {strings.admin.uploadFile}
      </Typography>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <div className={classnames(classes.container, { [classes.isDragActive]: isDragActive })}>
          <CloudUploadIcon fontSize="large" />
          <Typography>{file ? file.name : strings.admin.dropzone}</Typography>
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
        </div>
      </div>
      <Button
        size="small"
        className={classnames(classes.uploadButton, 'px-2')}
        variant="contained"
        onClick={uploadFile}
        disabled={!file}
      >
        {strings.admin.uploadFile}
      </Button>
    </FormControl>
  );
};

export default UploadCsv;
