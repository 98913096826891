import { useEffect } from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createQuestionGuard from '../../service/createQuestionGuard';
import useDataLoad, { useQuestionDataLoad } from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { succeed, fail } from '../../service/reducer';
import { useUser } from '../UserContext/UserContext';
import { RANDOM_CATEGORY } from '../../constants';

const initialContext = {
  question: {
    data: {},
    pending: true,
    error: null,
  },
  submission: {
    data: {},
    pending: false,
    error: null,
  },
  state: {
    isFlipped: false,
    givenAnswers: [],
  },
  isSixInARowMode: false,
};

const SET_QUESTION_CONTEXT = 'SET_QUESTION_CONTEXT';
const QUESTION_LOADED = 'QUESTION_LOADED';
const QUESTION_FAILED = 'QUESTION_FAILED';
const QUESTION_PENDING = 'QUESTION_PENDING';
const QUESTION_SUBMITTED = 'QUESTION_SUBMITTED';
const QUESTION_FLIPPED = 'QUESTION_FLIPPED';
const QUESTION_ANSWER_SELECTED = 'QUESTION_ANSWER_SELECTED';
const SUBMISSION_PENDING = 'SUBMISSION_PENDING';

const setQuestionContext = createAction(SET_QUESTION_CONTEXT);
const questionLoaded = createAction(QUESTION_LOADED);
const questionFailed = createAction(QUESTION_FAILED);
const questionPending = createAction(QUESTION_PENDING);
const questionSubmitted = createAction(QUESTION_SUBMITTED);
const questionFlipped = createAction(QUESTION_FLIPPED);
const selectGivenAnswers = createAction(QUESTION_ANSWER_SELECTED);
const submissionPending = createAction(SUBMISSION_PENDING);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case QUESTION_LOADED:
      draft.question = succeed(action.payload);
      draft.submission = initialContext.submission;
      draft.state = initialContext.state;
      break;
    case QUESTION_FAILED:
      draft.question = fail(action.payload);
      break;
    case QUESTION_PENDING:
      draft.question.pending = true;
      break;
    case QUESTION_FLIPPED:
      draft.state.isFlipped = !draft.state.isFlipped;
      return;
    case QUESTION_SUBMITTED:
      draft.submission = succeed(action.payload);
      break;
    case SUBMISSION_PENDING:
      draft.submission.pending = true;
      break;
    case QUESTION_ANSWER_SELECTED:
      draft.state.givenAnswers = action.payload.givenAnswers;
      draft.question.data.answers = action.payload.currentAnswers;
      break;
    case SET_QUESTION_CONTEXT:
      return action.payload;
    default:
      break;
  }
});

const [QuestionContext, useQuestionContext] = createContext(reducer, initialContext);

const questionCoordinator = promiseCoordinator();
const useQuestionQuestionContext = () => useQuestionContext().question;
const useSubmissionDataContext = () => useQuestionContext().submission;
const QuestionGuard = createQuestionGuard(useQuestionQuestionContext);

const useQuestion = (category = RANDOM_CATEGORY) => {
  const {
    user: { language },
  } = useUser();
  const { isSixInARowMode, dispatch } = useQuestionContext();
  const { data: question, pending } = useQuestionQuestionContext();

  const fetch = useFetch(questionCoordinator);

  const loadQuestion = useQuestionDataLoad(
    fetch,
    {
      url: isSixInARowMode ? ENDPOINTS.SIX_IN_A_ROW_QUESTIONS : ENDPOINTS.QUESTIONS,
      headers: { language },
      params: { category },
    },
    dispatch,
    questionLoaded,
    questionFailed,
    questionPending,
  );

  return {
    isSixInARowMode,
    question,
    pending,
    loadQuestion,
  };
};

const useQuestionCategory = () => {
  const questionCtx = useQuestionContext();
  return questionCtx?.question?.data?.category;
};

const useQuestionSubmission = () => {
  const {
    user: { language },
  } = useUser();
  const { state, isSixInARowMode, dispatch } = useQuestionContext();
  const { data: question } = useQuestionQuestionContext();
  const { data: submission, pending } = useSubmissionDataContext();

  const fetch = useFetch(questionCoordinator);

  const submitQuestion = useDataLoad(
    fetch,
    {
      method: 'POST',
      url: isSixInARowMode
        ? `${ENDPOINTS.SIX_IN_A_ROW_QUESTIONS}/${question?.questionId}`
        : `${ENDPOINTS.QUESTIONS}/${question?.questionId}`,
      data: { givenAnswers: state?.givenAnswers },
      headers: { language },
    },
    dispatch,
    questionSubmitted,
    null,
    submissionPending,
  );

  return {
    submission,
    submitQuestion,
    pending,
  };
};

const useQuestionState = () => {
  const {
    state: { isFlipped },
    dispatch,
  } = useQuestionContext();

  const flipQuestion = () => {
    dispatch(questionFlipped());
  };

  const selectAnswers = answerObj => {
    dispatch(selectGivenAnswers(answerObj));
  };
  return {
    isFlipped,
    flipQuestion,
    selectAnswers,
  };
};

const FakeQuestionContext = ({ children, state }) => {
  const { dispatch } = useQuestionContext();
  useEffect(() => {
    dispatch(setQuestionContext(state));
  }, [state, dispatch]);
  return children;
};

export default QuestionContext;
export {
  useQuestion,
  useQuestionCategory,
  useQuestionSubmission,
  useQuestionState,
  QuestionGuard,
  FakeQuestionContext,
};
