import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  placeholder: {
    backgroundColor: theme.palette.grey.c300,
    borderRadius: '50%',
  },
}));

export default useStyles;
