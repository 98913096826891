import React, { useCallback, useEffect, useState } from 'react';
import { CircularProgress, IconButton } from '@material-ui/core';
import { Replay as ReplayIcon } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { useQuestion, useQuestionSubmission } from '../../../../context/QuestionContext/QuestionContext';
import { useSixInARow } from '../../../../context/SixInARow/SixInARowContext';
import TopHalfCircle from '../../../../components/AppLayout/Header/TopHalfCircle/TopHalfCircle';
import Typography from '../../../../components/Typography/Typography';
import { SECONDS } from '../config';
import useStyles from './CountdownTimer.styles';
import routes from '../../../../routes';

const [LOWER_LIMIT, UPPER_LIMIT] = [30, 70];

const CountdownTimer = () => {
  const classes = useStyles();
  const { timeoutSixInARow, status } = useSixInARow();
  const { submission } = useQuestionSubmission();
  const { pending } = useQuestion();
  const [time, setTime] = useState(SECONDS);
  const history = useHistory();

  useEffect(() => {
    if (time > 0 && !status.isAboutToQuit) {
      const interval = setInterval(() => {
        setTime(time - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [status.isAboutToQuit, time]);

  useEffect(() => {
    if (time <= 0 && !pending) {
      timeoutSixInARow();
    }

    if (pending) {
      setTime(SECONDS);
    }
  }, [time, pending, timeoutSixInARow]);

  useEffect(() => {
    if (status.hasFailedGame || status.hasWonGame) {
      setTime(0);
    }
  }, [status.hasFailedGame, status.hasWonGame]);

  const progress = LOWER_LIMIT + ((UPPER_LIMIT - LOWER_LIMIT) * time) / SECONDS;

  const formatTimer = useCallback(seconds => {
    const date = new Date(0);
    date.setSeconds(seconds);
    return date.toISOString().substr(14, 5);
  }, []);

  return (
    <TopHalfCircle>
      <CircularProgress variant="static" thickness={2} size={150} value={progress} className={classes.progress} />
      {isEmpty(submission) || submission.correct ? (
        <Typography noWrap variant="body3" className={classes.time}>
          {formatTimer(time)}
        </Typography>
      ) : (
        <IconButton
          aria-label="delete"
          className={classes.iconButton}
          onClick={() => history.push(routes.PLAY_SIX_START)}
        >
          <ReplayIcon fontSize="inherit" />
        </IconButton>
      )}
    </TopHalfCircle>
  );
};

export default CountdownTimer;
