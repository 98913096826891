import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import routes from './routes';
import Themes from './themes';

export const ThemeProviderCustom = ({ children }) => {
  const [CustomTheme, setTheme] = useState(Themes.default);
  const location = useLocation();
  const isTenantBoardPage = location.pathname === routes.ADMIN_TENANT_BOARD;

  useEffect(() => {
    if (isTenantBoardPage) {
      setTheme(Themes.tenant_board);
    } else {
      setTheme(Themes.default);
    }
  }, [location.pathname, isTenantBoardPage]);

  return <ThemeProvider theme={CustomTheme}>{children}</ThemeProvider>;
};
