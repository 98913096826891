import { makeStyles } from '@material-ui/core/styles';

// Make sure the logo container and headerNav have the same width
// This ensures proper alignment of a potential element in the middle.
const headerItemsWidth = 90;

const useStyles = makeStyles(theme => ({
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: theme.palette.text.primary,
    width: headerItemsWidth,
  },
  logoIcon: {
    color: theme.palette.primary.main,
    marginRight: theme.spacing(4),
  },
  menuButton: {
    padding: 0,
    margin: 0,
  },
  headerNav: {
    width: headerItemsWidth,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
}));

export default useStyles;
