import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import routes from '../../../../routes';
import strings from '../../../../i18n/localization';
import CutoutButton from '../../../../components/CutoutButton/CutoutButton';
import useStyles from '../../../Question/DrawNewCard/DrawNewCard.styles';
import { useQuestion } from '../../../../context/QuestionContext/QuestionContext';
import { useSixInARow } from '../../../../context/SixInARow/SixInARowContext';
import { ENDPOINTS } from '../../../../hooks/api/constants';

const QuitButton = () => {
  const classes = useStyles();
  const history = useHistory();
  const { showQuitConfirmation, status, lastQuestionSubmission } = useSixInARow();
  const { loadQuestion } = useQuestion();
  const { hasFailedGame, hasWonGame } = status;

  const onClick = useCallback(() => {
    if (hasWonGame || hasFailedGame || !lastQuestionSubmission) {
      history.push(routes.OVERVIEW);
    } else {
      showQuitConfirmation();
    }
  }, [hasFailedGame, hasWonGame, history, showQuitConfirmation, lastQuestionSubmission]);

  useEffect(() => {
    loadQuestion({ url: ENDPOINTS.SIX_IN_A_ROW_QUESTIONS_START });
  }, [loadQuestion]);

  return (
    <div className={classes.buttonContainer}>
      <CutoutButton onClick={onClick} data-cy="quit-six-in-row">
        {strings.sixInARow.quit}
      </CutoutButton>
    </div>
  );
};

export default QuitButton;
