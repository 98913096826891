import React from 'react';
import { useTheme } from '@material-ui/styles';
import useStyles from './BackgroundBar.styles';
import { useQuestionCategory } from '../../context/QuestionContext/QuestionContext';

const BackgroundBar = () => {
  const category = useQuestionCategory();
  const theme = useTheme();
  const color = theme.palette[category]?.main || theme.palette.primary.c200;
  const classes = useStyles({ color });

  return (
    <div className={classes.backgroundBarContainer}>
      <div className={classes.backgroundBarColor} />
      <div className={classes.backgroundBarCutout} />
    </div>
  );
};

export default BackgroundBar;
