import React from 'react';
import { Grid, Container } from '@material-ui/core';

import PageTitle from '../../components/PageTitle/PageTitle';
import strings from '../../i18n/localization';
import ImportExportWidget from './ImportExportWidget/ImportExportWidget';
import ResetCacheWidget from './ResetCacheWidget/ResetCacheWidget';

const AdminDashboard = () => (
  <Container>
    <PageTitle title={strings.menu.adminDashboard} />
    <Grid item xs={12}>
      <ImportExportWidget />
      <ResetCacheWidget />
    </Grid>
  </Container>
);

export default AdminDashboard;
