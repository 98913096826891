import React, { useEffect, useState } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import useStyles from '../TenantWidget.styles';
import { useUser } from '../../../context/UserContext/UserContext';
import { ROLES } from '../../../constants';

export const DeleteIconForAdmins = ({ showDeleteConfirmation, tenantEnabled }) => {
  const classes = useStyles();
  const [isSecuredAdmin, setisSecuredAdmin] = useState(false);
  const {
    user: { roles },
  } = useUser();

  useEffect(() => {
    if (roles.some(actualUserRole => ROLES.SECURED_ADMIN.includes(actualUserRole))) {
      setisSecuredAdmin(true);
    }
  }, [roles]);

  return (
    <>
      {isSecuredAdmin ? (
        <Tooltip title="Delete">
          <span>
            <Button disabled={tenantEnabled} style={{ flex: 1 }} onClick={showDeleteConfirmation}>
              {tenantEnabled ? <DeleteIcon className={classes.disabled} /> : <DeleteIcon className={classes.delete} />}
            </Button>
          </span>
        </Tooltip>
      ) : null}
    </>
  );
};
