import { useMemo } from 'react';
import strings from '../i18n/localization';
import { LEVELS } from '../service/awards';
import { useCategoryLabels } from '../context/selectors/categories';

const buildBasicMultiLevelAward = label => [
  `${label}${LEVELS.BRONZE}`,
  `${label}${LEVELS.SILVER}`,
  `${label}${LEVELS.GOLD}`,
];

export const useAllAwards = () => [
  buildBasicMultiLevelAward('totalAnsweredQuestions'),
  buildBasicMultiLevelAward('totalScore'),
  buildBasicMultiLevelAward('answerStrike'),
  buildBasicMultiLevelAward('activityStrike'),
  buildBasicMultiLevelAward('datScore'),
  buildBasicMultiLevelAward('webScore'),
  buildBasicMultiLevelAward('mobScore'),
  buildBasicMultiLevelAward('emaScore'),
  buildBasicMultiLevelAward('pwdScore'),
  buildBasicMultiLevelAward('incScore'),
];

const buildAwardData = (name, description, n = undefined) => ({
  name,
  description,
  params: { n },
});

const buildBasicMultiLevelAwardInfo = (
  name,
  description,
  [nBronze, nSilver, nGold] = [undefined, undefined, undefined],
) => ({
  [LEVELS.BRONZE]: buildAwardData(name, description, nBronze),
  [LEVELS.SILVER]: buildAwardData(name, description, nSilver),
  [LEVELS.GOLD]: buildAwardData(name, description, nGold),
});

export const useAwardMap = () => {
  const currentLanguage = strings.getLanguage();

  return useMemo(
    () => ({
      totalAnsweredQuestions: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.totalAnsweredQuestions.name,
        strings.awards.reference.totalAnsweredQuestions.description,
        [10, 50, 150],
      ),
      totalScore: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.totalScore.name,
        strings.awards.reference.totalScore.description,
        [80, 200, 400],
      ),
      answerStrike: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.answerStrike.name,
        strings.awards.reference.answerStrike.description,
        [5, 10, 20],
      ),
      activityStrike: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.activityStrike.name,
        strings.awards.reference.activityStrike.description,
        [2, 5, 7],
      ),
      datScore: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.catScore.name,
        strings.awards.reference.catScore.description,
        [50, 100, 150],
      ),
      webScore: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.catScore.name,
        strings.awards.reference.catScore.description,
        [50, 100, 150],
      ),
      mobScore: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.catScore.name,
        strings.awards.reference.catScore.description,
        [50, 100, 150],
      ),
      emaScore: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.catScore.name,
        strings.awards.reference.catScore.description,
        [50, 100, 150],
      ),
      pwdScore: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.catScore.name,
        strings.awards.reference.catScore.description,
        [50, 100, 150],
      ),
      incScore: buildBasicMultiLevelAwardInfo(
        strings.awards.reference.catScore.name,
        strings.awards.reference.catScore.description,
        [50, 100, 150],
      ),
    }),
    // strings will automatically update based on selected language
    // therefore upon language change the labels must be re-calculated.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLanguage],
  );
};

export const useCategoryPrefix = (id = '') => {
  const categoryLabels = useCategoryLabels();
  return Object.entries(categoryLabels).find(([catId]) => id.startsWith(catId))?.[1];
};

export const useAwardName = (id, level) => {
  const awardMap = useAwardMap();
  const prefix = useCategoryPrefix(id);

  const award = awardMap?.[id]?.[level];
  if (award === undefined) {
    return '';
  }
  return (award?.name).replace('{cat}', prefix);
};

export const useAwardDescription = (id, level) => {
  const awardMap = useAwardMap();
  const prefix = useCategoryPrefix(id);

  const award = awardMap?.[id]?.[level];
  if (award === undefined) {
    return '';
  }

  const description = award.description.replace('{cat}', prefix);
  return Object.entries(award.params).reduce((agg, [key, value]) => agg.replace(`{${key}}`, value), description);
};
