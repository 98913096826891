import { makeStyles } from '@material-ui/core/styles';

// width is increased due to French and Italian labels
const drawerWidth = 255;

const useStyles = makeStyles(theme => ({
  drawerPaper: {
    width: drawerWidth,
    position: 'absolute',
  },
  root: theme.fixedWidth,
  menuWrapper: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  menuTop: {
    marginBottom: theme.spacing(5),
    padding: theme.spacing(7, 0, 5),
    backgroundColor: theme.palette.primary.c200,
  },
  infoSection: {
    margin: theme.spacing(0, 6),
  },
  menuBottom: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-end',
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
    marginBottom: theme.spacing(6),
  },
  userDetails: {
    marginLeft: theme.spacing(4),
  },
  listItem: {
    padding: theme.spacing(5, 0),
  },
}));

export default useStyles;
