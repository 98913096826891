import { makeStyles } from '@material-ui/core';

const CIRCLE_DIAMETER = 150;
const CONTAINER_HEIGHT = 50;

const useStyles = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    right: 0,
    zIndex: theme.layers.appBar + 1,
  },

  halfCircle: {
    position: 'absolute',
    width: CIRCLE_DIAMETER,
    height: CONTAINER_HEIGHT,
    overflow: 'hidden',
    textAlign: 'left',
    left: `calc(50% - ${CIRCLE_DIAMETER / 2}px)`,

    '&:before': {
      position: 'absolute',
      width: CIRCLE_DIAMETER,
      height: CIRCLE_DIAMETER,
      bottom: 0,
      borderRadius: '50%',
      background: theme.palette.grey.c300,
      content: '""',
    },
  },

  content: {
    position: 'absolute',
    width: CIRCLE_DIAMETER,
    height: CONTAINER_HEIGHT,
    left: `calc(50% - ${CIRCLE_DIAMETER / 2}px)`,
    textAlign: 'center',
  },
}));

export default useStyles;
