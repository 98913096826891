import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(5),
  },
  dots: {
    display: 'flex',
    flexDirection: 'row',
  },
  dot: {
    backgroundColor: theme.palette.action.disabled,
    borderRadius: '50%',
    width: 10,
    height: 10,
    margin: '0 3px',
  },
  dotActive: {
    backgroundColor: theme.palette.primary.main,
  },
  dotPast: {
    backgroundColor: theme.palette.success.main,
  },
  dotFailed: {
    backgroundColor: theme.palette.error.main,
  },
}));

export default useStyles;
