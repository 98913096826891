import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  appBar: {
    position: 'fixed',
    height: theme.height.header,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    zIndex: theme.layers.appBar,
    ...theme.fixedWidth,
  },
  bgColor: {
    color: theme.palette.text.secondary,
    background: `linear-gradient(-22deg, ${theme.palette.primary.c200} 80px, ${theme.palette.background.default} 80px)`,
  },
  bgColorDashboard: {
    color: theme.palette.text.primary,
    background: theme.palette.background.default,
  },
  noBg: {
    background: 'transparent',
  },
  appBarPseudoElement: {
    position: 'relative',
    height: theme.height.header,
    flexShrink: 0,
  },
}));

export default useStyles;
