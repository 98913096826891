import { useEffect } from 'react';
import { useUser } from '../../context/UserContext/UserContext';

const FetchInitialData = ({ children }) => {
  // fetch user info
  const { user, loadUser } = useUser();

  useEffect(() => {
    if (!user) {
      loadUser();
    }
  }, [user, loadUser]);

  // TODO: add Error state. This can only be done after silent refresh is in place. (maybe also consider adding the logout button)
  // if (error) {
  //   return 'Sorry something went wrong on our end, please try to reload the page';
  // }

  return children;
};

export default FetchInitialData;
