import React from 'react';

import useStyles from './Fieldset.styles';

const Fieldset = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <fieldset {...props} className={classes.fieldset}>
      {children}
    </fieldset>
  );
};

export default Fieldset;
