import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  container: {
    paddingBottom: theme.spacing(9),
  },
  arrowIcon: {
    paddingBottom: theme.spacing(4),
    marginRight: theme.spacing(4),
    display: 'initial',
  },
  footer: {
    ...theme.fixedWidth,
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
    backgroundColor: 'white',
    width: '100%',
    padding: theme.spacing(6, 5),
    color: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'space-between',
    boxShadow: theme.customShadows.lightTop,
  },
  iconContainer: {
    color: 'black',
  },
}));

export default useStyles;
