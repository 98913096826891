import React from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';

const initialContext = {
  data: [],
  pending: true,
  error: null,
};

const AWARDS_LOADED = 'AWARDS_LOADED';
const AWARDS_ERROR = 'AWARDS_ERROR';
const AWARDS_PENDING = 'AWARDS_PENDING';

const awardsLoadedAction = createAction(AWARDS_LOADED);
const awardsFailedAction = createAction(AWARDS_ERROR);
const awardsPendingAction = createAction(AWARDS_PENDING);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case AWARDS_ERROR:
      return fail(action.payload);
    case AWARDS_PENDING:
      draft.pending = true;
      break;
    case AWARDS_LOADED:
      return succeed(action.payload);
    default:
      break;
  }
});

const [RankingContextProvider, useRankingContext] = createContext(reducer, initialContext);

const AwardContext = props => <RankingContextProvider {...props} />;
const awardCoordinator = promiseCoordinator();
const AwardGuard = createGuard(useRankingContext);

const useAwards = () => {
  const { data: awards, pending, dispatch } = useRankingContext();
  const fetch = useFetch(awardCoordinator);

  const loadAwards = useDataLoad(
    fetch,
    {
      url: ENDPOINTS.AWARDS,
    },
    dispatch,
    awardsLoadedAction,
    awardsFailedAction,
    awardsPendingAction,
  );

  return {
    awards,
    loadAwards,
    pending,
  };
};

export default AwardContext;
export { useAwards, AwardGuard };
