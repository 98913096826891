import React from 'react';
import { Container } from '@material-ui/core';

import useStyles from './Footer.styles';
import Typography from '../../Typography/Typography';

import strings, { useManualLanguageRerender } from '../../../i18n/localization';
import { useUser } from '../../../context/UserContext/UserContext';
import { INSTANCE_TYPE } from '../../../constants';

const TERMS = {
  en: '/files/gtc_en.pdf',
  de: '/files/gtc_de.pdf',
  fr: '/files/gtc_fr.pdf',
  it: '/files/gtc_it.pdf',
};

const SME_TERMS = {
  en: '/files/sme_gtc_en.pdf',
  de: '/files/sme_gtc_de.pdf',
  fr: '/files/sme_gtc_en.pdf',
  it: '/files/sme_gtc_en.pdf',
};

const Footer = () => {
  const classes = useStyles();
  const {
    user: { language },
  } = useUser();

  useManualLanguageRerender();

  return (
    <Container className={classes.root}>
      <footer className={classes.footer}>
        {INSTANCE_TYPE === 'sme' ? (
          <Typography component="a" href={SME_TERMS[language]} className={classes.text} target="_blank">
            {strings.footer.termsAndConditions}
          </Typography>
        ) : (
          <Typography component="a" href={TERMS[language]} className={classes.text} target="_blank">
            {strings.footer.termsAndConditions}
          </Typography>
        )}
        <Typography className={classes.text}>{strings.footer.copyright}</Typography>
      </footer>
    </Container>
  );
};

export default Footer;
