import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  awardIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translate(0, -65%)',
  },
}));

export default useStyles;
