import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  textNode: {
    fill: theme.palette.text.primaryLight,
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'uppercase',
  },
  activeUser: {
    fill: theme.palette.primary.main,
    fontWeight: 'bold',
  },
}));

export default useStyles;
