/* eslint-disable jsx-a11y/media-has-caption */
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import Plyr from 'plyr';
import once from 'lodash/once';

import { useCategoryLabels } from '../../context/selectors/categories';
import strings from '../../i18n/localization';

import useStyles from './Learn.styles';
import CategoryTile from '../../components/Categories/CategoryTile';
import VideoIcon from './Icons/VideoIcon';
import PageTitle from '../../components/PageTitle/PageTitle';
import Loader from '../../components/Loader/Loader';
import Button from '../../components/Button/Button';
import DemoInfoBox from '../../components/DemoInfoBox/DemoInfoBox';
import DownloadIcon from './DownloadIcon/DownloadIcon';
import { AVAILABLE_CATEGORIES, IS_DEMO } from '../../constants';
import { useUser } from '../../context/UserContext/UserContext';
import useChecklist from '../../hooks/api/learn/useChecklist';
import useVideos from '../../hooks/api/learn/useVideos';

const LearnPage = () => {
  const player = useRef(null);
  const video = useRef(null);
  const [videoId, setVideoId] = useState(null);
  const [isVideoLoading, setVideoLoading] = useState(false);
  const { data: videosPerLanguageAndCategory, pending: isVideoUrlsPending, getVideos } = useVideos();
  const classes = useStyles();
  const {
    user: { language },
  } = useUser();
  const categoryLabels = useCategoryLabels();
  const { pending, downloadChecklist } = useChecklist('checklist.pdf');

  useEffect(() => {
    getVideos();
  }, [getVideos]);

  useEffect(() => {
    // eslint-disable-next-line
    import('./plyr.css');
  }, []);
  useLayoutEffect(() => {
    if (videoId !== null) {
      setVideoLoading(true);
      player.current = new Plyr(video.current, {
        settings: [],
        controls: ['play-large', 'play', 'progress', 'current-time', 'volume', 'fullscreen'],
      });
      player.current.once('ready', () => {
        const exitFullScreenCallback = once(() => {
          player.current.destroy();
          setVideoId(null);
          setVideoLoading(false);
        });
        player.current.on('exitfullscreen', exitFullScreenCallback);
        // required for iOS Safari
        video.current.addEventListener('webkitendfullscreen', exitFullScreenCallback);

        player.current.fullscreen.enter();
        player.current.play().then(() => {
          setVideoLoading(false);
        });
      });
      return () => {
        player.current.destroy();
      };
    }
    return undefined;
  }, [videoId]);

  return (
    <>
      {isVideoUrlsPending && isVideoLoading && <Loader isFullScreen />}
      <div className={classes.videoContainer} key={videoId}>
        <video ref={video} src={videoId} />
      </div>
      {IS_DEMO ? <DemoInfoBox /> : null}
      <PageTitle title={strings.learn.title} />
      <div className={classes.grid}>
        {AVAILABLE_CATEGORIES.filter(category => !IS_DEMO || (IS_DEMO && category === 'pwd')).map(category => (
          <CategoryTile
            key={category}
            className={classes.tile}
            title={categoryLabels[category]}
            icon={<VideoIcon size="S" />}
            category={category}
            onClick={() => {
              setVideoId(videosPerLanguageAndCategory[language][category]);
            }}
          />
        ))}
        {AVAILABLE_CATEGORIES.filter(category => IS_DEMO && category !== 'pwd').map(category => (
          <CategoryTile
            key={category}
            className={classes.tile}
            title={categoryLabels[category]}
            icon={<VideoIcon size="S" />}
            category={category}
            disabled
          />
        ))}
      </div>
      <Button
        component="a"
        variant="link"
        size="large"
        className={classes.downloadLink}
        onClick={pending ? undefined : downloadChecklist}
        disabled={pending}
      >
        <DownloadIcon variant="left" className={classes.downloadIcon} />
        {strings.learn.checklist}
      </Button>
    </>
  );
};

export default LearnPage;
