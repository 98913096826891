import React, { useEffect, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';

import routes from '../../routes';
import strings from '../../i18n/localization';
import useStyles from './UserDashboard.styles';
import AvatarGallery from './AvatarGallery/AvatarGallery';
import Button from '../../components/Button/Button';
import Typography from '../../components/Typography/Typography';
import TabPanelRanking from './TabPanelRanking/TabPanelRanking';
import TabPanelScore from './TabPanelScore/TabPanelScore';
import FullWidthContainer from '../../components/FullWidthContainer/FullWidthContainer';
import usePopupControl, { popupKeys } from '../../hooks/popup/usePopupControl';
import { useUser } from '../../context/UserContext/UserContext';
import { RankingGuard, useRanking } from '../../context/RankingContext/RankingContext';
import axaLogo from './axa_logo.png';
import { SHOW_BANNER } from '../../constants';
import { AwardGuard, useAwards } from '../../context/AwardContext/AwardContext';
import Awards from './Awards/Awards';

const Tabs = {
  ranking: 0,
  mixedScore: 1,
};

const CHART_HEIGHT = 400;

const UserDashboard = ({ keepData = false }) => {
  const classes = useStyles();
  const {
    user: { firstName },
  } = useUser();

  const { openPopup } = usePopupControl(popupKeys.RANKING);

  const [currentTab, setCurrentTab] = useState(0);

  const { ranking, loadRanking } = useRanking();
  useEffect(() => {
    if (!keepData) {
      loadRanking();
    }
  }, [keepData, loadRanking]);

  const { awards, loadAwards } = useAwards();
  useEffect(() => {
    if (!keepData) {
      loadAwards();
    }
  }, [keepData, loadAwards]);

  const handleChangeIndex = index => {
    setCurrentTab(index);
  };

  const setCurrentTabCurry = newTab => () => setCurrentTab(newTab);

  return (
    <div>
      <Typography variant="h2" color="primaryLight">
        {strings.score.welcomeBack} {firstName}
      </Typography>
      <SwipeableViews index={currentTab} onChangeIndex={handleChangeIndex}>
        <TabPanelRanking height={CHART_HEIGHT} onSwitchTabClick={setCurrentTabCurry(Tabs.mixedScore)} ranks={ranking} />
        <TabPanelScore height={CHART_HEIGHT} onSwitchTabClick={setCurrentTabCurry(Tabs.ranking)} />
      </SwipeableViews>

      <Typography variant="h2">{strings.score.myCompanyRanking}</Typography>
      <Typography variant="h4">{strings.score.compareToOthers}</Typography>

      <div className={classes.alignCenter}>
        <RankingGuard>
          <AvatarGallery users={ranking} maxAvatars="10" />
        </RankingGuard>
      </div>

      <FullWidthContainer className={classes.backgroundBar}>
        <Button variant="link" size="large" className={classes.marginBottom} onClick={openPopup} data-cy="show-ranking">
          {strings.score.seeBest}
        </Button>

        <AwardGuard>
          <Awards awards={awards} />
          <div className={classes.marginBottom} />
        </AwardGuard>

        <Button size="large" variant="contained" className={classes.startButton} to={routes.CATEGORIES}>
          {strings.dashboard.start}
        </Button>
        <Button variant="link" size="large" className={classes.startButton} to={routes.LEARN}>
          {strings.dashboard.learn}
        </Button>
        {SHOW_BANNER ? <img alt="Powered by AXA" src={axaLogo} className={classes.axaLogo} /> : null}
      </FullWidthContainer>
    </div>
  );
};

export default UserDashboard;
