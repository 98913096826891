import Muli400Woff from './fonts/muli-v20-latin-regular.woff';
import Muli400Woff2 from './fonts/muli-v20-latin-regular.woff2';
import Muli700Woff from './fonts/muli-v20-latin-700.woff';
import Muli700Woff2 from './fonts/muli-v20-latin-700.woff2';
import Muli900Woff from './fonts/muli-v20-latin-900.woff';
import Muli900Woff2 from './fonts/muli-v20-latin-900.woff2';

const muli400 = {
  fontFamily: 'Muli',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Muli'),
    url(${Muli400Woff}) format('woff'),
    url(${Muli400Woff2}) format('woff2')
  `,
};

const muli700 = {
  fontFamily: 'Muli',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 700,
  src: `
    local('Muli'),
    url(${Muli700Woff}) format('woff'),
    url(${Muli700Woff2}) format('woff2')
  `,
};

const muli900 = {
  fontFamily: 'Muli',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 900,
  src: `
    local('Muli'),
    url(${Muli900Woff}) format('woff'),
    url(${Muli900Woff2}) format('woff2')
  `,
};

const typography = {
  typography: {
    fontFamily: [
      'Muli',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h1: {
      fontSize: '3rem', // 48px
      fontWeight: 900,
      lineHeight: 1.3,
    },
    h2: {
      fontSize: '1.8rem', // 32px
      fontWeight: 900,
      lineHeight: 1.3,
    },
    h3: {
      fontSize: '1.625rem', // 26px
      fontWeight: 900,
      lineHeight: 1.4,
    },
    h4: {
      fontSize: '1.125rem', // 18px
      fontWeight: 900,
      lineHeight: 1.4,
    },
    h5: {
      fontSize: '0.875rem', // 14px
      fontWeight: 900,
      lineHeight: 1.4,
    },
    h6: {
      fontSize: '0.6875rem', // 11px
      fontWeight: 900,
      lineHeight: 1.4,
      letterSpacing: '0.05em',
    },
    body1: {
      fontSize: '1.2rem', // 19.2px
    },
    body2: {
      fontSize: '1rem', // 16px
    },
    body3: {
      fontSize: '0.875rem', // 14px
      lineHeight: 1.4,
      letterSpacing: '0.05em',
    },
    button: {
      fontSize: '1.125rem', // 18px
      textTransform: 'uppercase',
      lineHeight: 1.3,
    },
    button2: {
      fontSize: '1rem', // 16px
      textTransform: 'uppercase',
      lineHeight: 1.4,
    },
    link: {
      fontSize: '1rem', // 16px
      fontWeight: 700,
      lineHeight: 1.4,
      textTransform: 'uppercase',
      letterSpacing: '0.05em',
    },
  },
};

export { muli400, muli700, muli900 };
export default typography;
