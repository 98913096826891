import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextField, Container, CircularProgress } from '@material-ui/core';
import classnames from 'classnames';

import strings from '../../i18n/localization';
import { DISABLE_INVITATION, ENABLE_CYARP_SYNC, ROLES } from '../../constants';
import useUserInvite from '../../hooks/api/users/useUserInvite';
import useTenantInvite from '../../hooks/api/users/useTenantInvite';
import useOnboardUserOnCyarp from '../../hooks/api/cyarp/useOnboardUserOnCyarp';
import { useAddMessage } from '../../context/MessageContext/MessageContext';
import { useUser } from '../../context/UserContext/UserContext';
import routes from '../../routes';

import Button from '../../components/Button/Button';
import Dropdown from '../../components/Dropdown/Dropdown';
import PageTitle from '../../components/PageTitle/PageTitle';
import Typography from '../../components/Typography/Typography';
import EmailTableFormatter from './EmailTableFormatter/EmailTableFormatter';
import useStyles from './InviteUser.styles';

const InviteUser = () => {
  const classes = useStyles();
  const {
    user: { roles },
  } = useUser();
  const userCanInviteTenant = roles.includes(ROLES.SECURED_ADMIN);
  const { addSuccessMessage } = useAddMessage();
  const { pending: userPending, error: userError, submit: userSubmit } = useUserInvite();
  const { pending: tenantPending, error: tenantError, submit: tenantSubmit } = useTenantInvite();
  const { pending: cyarpPending, error: cyarpError, submit: cyarpSubmit } = useOnboardUserOnCyarp();

  const [input, setInput] = useState('');
  const [tenant, setTenant] = useState('');
  const [contractId, setContractId] = useState('');
  const [domains, setDomains] = useState('');
  const [parsedAddresses, setParsedAddresses] = useState([]);
  const [isTenantInvite, setIsTenantInvite] = useState(false);
  const history = useHistory();

  const componentDidMount = useRef(false);

  useEffect(() => {
    if (
      componentDidMount.current &&
      !userPending &&
      !userError &&
      !tenantPending &&
      !tenantError &&
      !cyarpPending &&
      !cyarpError
    ) {
      addSuccessMessage(strings.forms.submitSuccess);
      setInput('');
      setTenant('');
      setContractId('');
    } else {
      componentDidMount.current = true;
    }
  }, [userPending, tenantPending, cyarpPending, userError, tenantError, cyarpError, addSuccessMessage]);

  useEffect(() => {
    if (DISABLE_INVITATION) {
      history.push(routes.OVERVIEW);
    }
  }, [history]);

  const onChangeMailInput = evt => {
    const val = evt.target.value;
    setInput(val);
  };

  const onChangeTenantInput = evt => {
    const val = evt.target.value;
    setTenant(val);
  };

  const onChangeContractIdInput = evt => {
    const val = evt.target.value;
    setContractId(val);
  };

  const onChangeDomainsInput = evt => {
    const val = evt.target.value;
    setDomains(val);
  };

  const sendTenantInvite = () => {
    tenantSubmit({
      data: {
        tenantId: tenant,
        contractId,
        userAdmin: parsedAddresses[0],
      },
    });

    if (ENABLE_CYARP_SYNC) {
      const domainList = domains
        .replaceAll(' ', '')
        .replaceAll('\n', '')
        .split(',');
      const finalDomainList = [];
      domainList.forEach(value => {
        if (value.startsWith('http')) {
          finalDomainList.push(value);
        }
      });
      if (finalDomainList.length !== 0) {
        cyarpSubmit({
          data: {
            tenantId: tenant,
            contractNr: contractId,
            domains: finalDomainList,
            contact: {
              name: `${parsedAddresses[0].firstName} ${parsedAddresses[0].lastName}`,
              email: parsedAddresses[0].email,
              lang: 'de',
            },
          },
        });
      }
    }
  };

  const sendUserInvite = () => {
    userSubmit({ data: parsedAddresses });
  };

  const dropdownRoles = ['Quizzee', 'Tenant Admin'];
  const onRoleSelected = index => {
    const tenantSelected = index === '1';
    setIsTenantInvite(tenantSelected);
  };

  const first = strings.invite.sampleFirstname;
  const last = strings.invite.sampleLastname;
  const isRequestPending = userPending || tenantPending;
  const isSubmitDisabled = !input || parsedAddresses.length === 0 || (isTenantInvite && !tenant) || isRequestPending;

  return (
    <Container maxWidth="sm">
      <PageTitle title={strings.invite.title} />
      {userCanInviteTenant ? (
        <div className={classes.roleContainer}>
          <Typography>{strings.invite.role}</Typography>
          <div className={classes.roleInput}>
            <Dropdown
              className={classes.roleDropdown}
              items={dropdownRoles}
              onItemSelected={onRoleSelected}
              selected={+isTenantInvite}
              data-cy="invite-role"
              disabled={isRequestPending}
            />
            <TextField
              placeholder={strings.invite.tenantName}
              className={classnames(classes.textField, classes.tenantText)}
              value={tenant}
              onChange={onChangeTenantInput}
              margin="normal"
              variant="outlined"
              data-cy="tenant-field"
              style={{
                visibility: isTenantInvite ? 'visible' : 'none',
                opacity: isTenantInvite ? 1 : 0,
              }}
              disabled={isRequestPending}
            />
            <Dropdown
              className={classes.roleDropdown}
              items={['Tenant Admin']}
              style={{ visibility: 'hidden' }}
              disabled={isRequestPending}
            />
            <TextField
              placeholder={strings.invite.contractId}
              className={classnames(classes.textField, classes.tenantText)}
              value={contractId}
              onChange={onChangeContractIdInput}
              margin="normal"
              data-cy="contract-id-field"
              variant="outlined"
              style={{
                visibility: isTenantInvite ? 'visible' : 'hidden',
                opacity: isTenantInvite ? 1 : 0,
              }}
              disabled={isRequestPending}
            />
            <Dropdown
              className={classes.roleDropdown}
              items={['Tenant Admin']}
              style={{
                visibility: 'hidden',
                display: ENABLE_CYARP_SYNC && isTenantInvite ? 'inline-flex' : 'none',
              }}
              disabled={isRequestPending}
            />
            <TextField
              placeholder={strings.invite.domains}
              className={classnames(classes.textField, classes.tenantText)}
              value={domains}
              multiline
              rows={4}
              onChange={onChangeDomainsInput}
              data-cy="domains-field"
              margin="normal"
              variant="outlined"
              style={{
                display: ENABLE_CYARP_SYNC && isTenantInvite ? 'inline-flex' : 'none',
                opacity: isTenantInvite ? 1 : 0,
              }}
              disabled={isRequestPending}
            />
          </div>
        </div>
      ) : null}
      <div>
        {strings.invite.formatExamples}:
        <ul>
          <li>{`${first}.${last}@sample.com, ...`}</li>
          <li>{`"${first} ${last}" <${first}.${last}@sample.com>, ...`}</li>
          <li>{`${last}, ${first} <${first}.${last}@sample.com>; ...`}</li>
        </ul>
      </div>
      <Typography bold>{isTenantInvite ? strings.invite.tenantAdmin : strings.invite.users}</Typography>
      <TextField
        id="input-mail-addresses"
        className={classes.textField}
        onChange={onChangeMailInput}
        value={input}
        multiline={!isTenantInvite}
        rows={isTenantInvite ? 1 : 4}
        placeholder={isTenantInvite ? strings.invite.placeholderSingular : strings.invite.placeholderPlural}
        variant="outlined"
        fullWidth
        data-cy="invite-mails-field"
        disabled={isRequestPending}
      />
      <Button
        id="invite-user-submit"
        disabled={isSubmitDisabled}
        className={classes.spacing}
        onClick={isTenantInvite ? sendTenantInvite : sendUserInvite}
        variant="contained"
        color="primary"
        size="large"
        data-cy="submit-invites"
      >
        {strings.invite.send}
      </Button>
      {isRequestPending ? <CircularProgress style={{ marginBottom: '-17px' }} /> : null}

      {input ? (
        <EmailTableFormatter
          input={input}
          maxEntries={isTenantInvite ? 1 : undefined}
          onAddressUpdate={setParsedAddresses}
        />
      ) : null}
    </Container>
  );
};

export default InviteUser;
