import React from 'react';
import ScoreHeader from '../../components/AppLayout/Header/ScoreHeader/ScoreHeader';
import BackgroundBar from '../../components/BackgroundBar/BackgroundBar';
import QuestionCardLayout from '../../components/QuestionCardLayout/QuestionCardLayout';
import QuestionLayout from '../../components/QuestionLayout/QuestionLayout';
import QuestionContext, { QuestionGuard } from '../../context/QuestionContext/QuestionContext';
import DrawNewCard from './DrawNewCard/DrawNewCard';
import FeedbackOverlay from './FeedbackOverlay/FeedbackOverlay';

const Question = () => (
  <QuestionContext>
    <ScoreHeader />
    <BackgroundBar />
    <FeedbackOverlay />
    <QuestionLayout>
      <QuestionGuard>
        <QuestionCardLayout />
      </QuestionGuard>
      <DrawNewCard />
    </QuestionLayout>
  </QuestionContext>
);

export default Question;
