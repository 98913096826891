import React, { useCallback } from 'react';
import { useTheme } from '@material-ui/core';
import confetti from 'canvas-confetti';
import Typography from '../../../../components/Typography/Typography';
import strings from '../../../../i18n/localization';
import AwardIcon from '../../../../components/AwardIcon/AwardIcon';
import Button from '../../../../components/Button/Button';
import routes from '../../../../routes';
import FullScreenDialog from '../../../../components/FullScreenDialog/FullScreenDialog';
import useStyles from './AwardOverlay.styles';
import { useAwardDescription, useAwardName } from '../../../../hooks/awards';
import { getAwardId, getLevel } from '../../../../service/awards';

const AwardOverlay = ({ isOpen, onClose, award }) => {
  const classes = useStyles();
  const awardId = getAwardId(award?.id);
  const awardLevel = getLevel(award?.id);
  const theme = useTheme();

  const popConfetti = useCallback(() => {
    confetti({
      particleCount: 300,
      spread: 360,
      startVelocity: 30,
      zIndex: theme.zIndex.modal + 1,
      origin: { y: 0 },
    });
  }, [theme.zIndex.modal]);

  const awardDescription = useAwardDescription(awardId, awardLevel);
  const awardName = useAwardName(awardId, awardLevel);
  return (
    <FullScreenDialog
      open={isOpen}
      onCloseButton={() => {
        onClose();
      }}
      onEntered={() => {
        popConfetti();
      }}
      primary
    >
      {award ? (
        <div className={classes.awardOverlay}>
          <Typography variant="h2" gutterBottom="l" color="textSecondary" center>
            {strings.awards.overlay.congrats}
          </Typography>
          <AwardIcon size="L" level={awardLevel} award={awardId} />
          <Typography bold color="textSecondary" center>
            {strings.awards.overlay.newAward}
          </Typography>
          <Typography variant="h2" gutterBottom="m" color="textSecondary" center>
            {awardName}
          </Typography>
          <Typography gutterBottom="m" color="textSecondary" center>
            {awardDescription}
          </Typography>
          <Button variant="outlined" to={routes.AWARDS} className={classes.button} color="secondary">
            {strings.awards.seeAll}
          </Button>
        </div>
      ) : null}
    </FullScreenDialog>
  );
};

export default AwardOverlay;
