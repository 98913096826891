import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  highlightShadow: {
    boxShadow: theme.customShadows.medium,
  },
  highlightFontSize: {
    fontSize: '1.2rem',
  },
  highlightColor: {
    color: theme.palette.primary.dark,
  },
  avatarWrapper: {
    display: 'flex',
    margin: 0,
  },
  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderBottomStyle: 'solid',
    borderBottomWidth: theme.spacing(1),
    borderBottomColor: theme.palette.grey.c100,
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(0, 3),
  },
  tableCell: {
    padding: theme.spacing(4),
    flexBasis: '15%',
  },
  nameCell: {
    flexGrow: 1,
    textAlign: 'start',
    paddingLeft: theme.spacing(5),
  },
  hideOverflow: {
    overflow: 'hidden',
  },
}));

export default useStyles;
