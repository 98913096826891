import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  card: {
    height: '100%',
    boxShadow: theme.customShadows.card,
    borderRadius: theme.shape.cardBorderRadius,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default useStyles;
