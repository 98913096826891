import { useCallback } from 'react';
import axios from 'axios';
import { API_URL, GET } from '../hooks/api/constants';

const getConfig = (config = {}) => ({
  method: config.method || GET,
  baseURL: API_URL,
  url: config.url,
  headers: {
    ...config.headers,
  },
  params: config.params,
  data: config.data,
});

const useFetchNoAuth = (coordinator, config) => {
  const load = useCallback(
    async customConfig => {
      const response = await axios(
        getConfig({
          ...config,
          ...customConfig,
        }),
      );
      return response;
    },
    [config],
  );
  return load;
};

export default useFetchNoAuth;
