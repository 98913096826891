import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: 65,
    height: 47,
    marginLeft: -9,
    padding: `${theme.spacing(5)}px 10px`,
  },
  icon: {
    fontSize: 34,
    marginTop: -3,
    marginLeft: -5,
  },
  track: {
    border: '2px solid',
    borderRadius: 3,
    borderColor: theme.palette.text.primaryLight,
    backgroundColor: 'inherit',
  },
  switchBase: {
    color: theme.palette.grey.c500,
    transition: '0.2s ease',
    '&$checked': {
      color: theme.palette.success.main,
    },
  },
  checked: {
    // this is needed for the switchBase class
  },
}));

export default useStyles;
