import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  awardWall: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  award: {
    flexBasis: '30%',
    padding: theme.spacing(4, 0),
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '5%',
  },
}));

export default useStyles;
