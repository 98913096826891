import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  line: {
    margin: theme.spacing(5, 0),
    width: '100%',
    border: 'none',
    backgroundImage: `linear-gradient(to right, ${theme.palette.grey.c500} 80%, rgba(0,0,0,0) 0%)`,
    backgroundPosition: 'bottom',
    backgroundSize: '14px 1px',
    backgroundRepeat: 'repeat-x',
    height: 1,
  },
}));

export default useStyles;
