import React from 'react';
import { Grid, useTheme } from '@material-ui/core';
import strings from '../../../../../i18n/localization';
import ScoreBadge from '../../../../../components/FeedbackContentPositive/ScoreBadge/ScoreBadge';
import FullScreenDialog from '../../../../../components/FullScreenDialog/FullScreenDialog';
import Typography from '../../../../../components/Typography/Typography';
import Button from '../../../../../components/Button/Button';
import useStyles from './QuitOverlay.styles';

const QuitOverlay = ({ isOpen, onContinue, onNewGame, submission }) => {
  const classes = useStyles();
  const theme = useTheme();
  return (
    <FullScreenDialog open={isOpen} primary data-cy="six-in-a-row-quit-overlay">
      <div className={classes.root}>
        <ScoreBadge
          color={theme.palette.primary.main}
          submission={{ ...submission, score: submission.potentialScore }}
          subtitle={
            <Typography variant="h1" color="textSecondary">
              {strings.sixInARow.congrats}
            </Typography>
          }
        />
        <Typography variant="h3" color="textSecondary" className={classes.description}>
          {strings.sixInARow.confirmCancel}
        </Typography>
        <div className={classes.spacer} />

        <Grid container direction="column" spacing={5} className={classes.buttonContainer}>
          <Grid item>
            <Button variant="outlined" onClick={onNewGame} className={classes.button} color="secondary">
              {strings.sixInARow.playAgain}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              onClick={onContinue}
              className={classes.button}
              color="secondary"
              data-cy="quit-continue"
            >
              {strings.sixInARow.exit}
            </Button>
          </Grid>
        </Grid>
      </div>
    </FullScreenDialog>
  );
};

export default QuitOverlay;
