import React, { useEffect, useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { useQuestionSubmission } from '../../../context/QuestionContext/QuestionContext';
import ResponseStateOverlay from './ResponseStateOverlay/ResponseStateOverlay';
import AwardOverlay from './AwardOverlay/AwardOverlay';

const FeedbackOverlay = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isAwardOverlayOpen, setAwardOverlayOpen] = useState(false);
  const { submission } = useQuestionSubmission();

  const { correct: isCorrect, obtainedUserAwards } = submission;

  const hasNewAward = obtainedUserAwards?.length > 0;
  const newAward = hasNewAward ? obtainedUserAwards[0] : null;

  useEffect(() => {
    if (!isEmpty(submission)) {
      setIsOpen(true);
      if (hasNewAward) {
        setAwardOverlayOpen(true);
      }
    }
  }, [hasNewAward, submission]);

  const onClose = () => {
    setIsOpen(false);
  };

  const responseStateOverlay = (
    <ResponseStateOverlay submission={submission} isOpen={isOpen} isCorrect={isCorrect} onClose={onClose} />
  );

  // ClickAwayListener must only be registered when the award overlay is not open, otherwise it will mistrigger
  const successDialogWrapper = isAwardOverlayOpen ? (
    responseStateOverlay
  ) : (
    <ClickAwayListener onClickAway={onClose}>{responseStateOverlay}</ClickAwayListener>
  );

  return (
    <>
      {successDialogWrapper}
      <AwardOverlay
        isOpen={isAwardOverlayOpen}
        onClose={() => {
          setAwardOverlayOpen(false);
        }}
        award={newAward}
      />
    </>
  );
};

export default FeedbackOverlay;
