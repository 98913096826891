import React, { useCallback } from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';

const initialContext = {
  data: [],
  pending: true,
  error: null,
};

const RANKING_LOADED = 'RANKING_LOADED';
const RANKING_ERROR = 'RANKING_ERROR';
const RANKING_PENDING = 'RANKING_PENDING';

const rankingLoadedAction = createAction(RANKING_LOADED);
const rankingFailedAction = createAction(RANKING_ERROR);
const rankingPendingAction = createAction(RANKING_PENDING);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case RANKING_ERROR:
      return fail(action.payload);
    case RANKING_PENDING:
      draft.pending = true;
      break;
    case RANKING_LOADED:
      return succeed(action.payload);
    default:
      break;
  }
});

const [RankingContextProvider, useRankingContext] = createContext(reducer, initialContext);

const RankingContext = props => <RankingContextProvider {...props} />;
const rankingCoordinator = promiseCoordinator();
const RankingGuard = createGuard(useRankingContext);

const useRanking = () => {
  const { data: ranking, pending, dispatch } = useRankingContext();
  const fetch = useFetch(rankingCoordinator);

  const loadRankingWithParams = useDataLoad(
    fetch,
    {
      url: ENDPOINTS.RANKING,
    },
    dispatch,
    rankingLoadedAction,
    rankingFailedAction,
    rankingPendingAction,
  );

  const loadRanking = useCallback(
    (category, days) => {
      loadRankingWithParams({ params: { category, days } });
    },
    [loadRankingWithParams],
  );

  return {
    ranking,
    loadRanking,
    pending,
  };
};

export default RankingContext;
export { useRanking, RankingGuard };
