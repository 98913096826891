import React from 'react';
import classnames from 'classnames';
import Button from '../../../components/Button/Button';
import useStyles from './LanguageBar.styles';
import Typography from '../../../components/Typography/Typography';
import { AVAILABLE_LANGUAGES } from '../../../constants';

const LanguageBar = ({ onLanguageSelected, selectedLanguage, className, errorMap }) => {
  const classes = useStyles();

  return (
    <div className={classnames(classes.languageBar, className)}>
      {AVAILABLE_LANGUAGES.map(language => {
        const isSelected = selectedLanguage === language;
        return (
          <div key={language} className={classes.language}>
            <Button
              onClick={() => onLanguageSelected(language)}
              variant={isSelected ? 'text' : 'contained'}
              className={classnames(classes.tab, { [classes.selectedTab]: isSelected })}
              data-cy={`languageBar-${language}`}
            >
              {language}
            </Button>
            {errorMap?.[language] ? (
              <div key={`${language}-error`} className={classes.errorIndicator}>
                <Typography variant="body3" bold>
                  {errorMap?.[language]}
                </Typography>
              </div>
            ) : null}
          </div>
        );
      })}
    </div>
  );
};

export default LanguageBar;
