import { ENDPOINTS, POST } from '../constants';
import useAxios from '../../useAxios';
import promiseCoordinator from '../../../service/promiseCoordinator';

const coordinator = promiseCoordinator();

const useCsvImport = () => {
  const config = {
    method: POST,
    url: ENDPOINTS.QUESTIONS_IMPORT,
    headers: { 'Content-Type': 'multipart/form-data;boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW' },
  };

  const [{ data: response, pending, error }, uploadCsv] = useAxios(coordinator, config);

  return { response, pending, error, uploadCsv };
};

export default useCsvImport;
