import React from 'react';
import BackgroundBar from '../../../components/BackgroundBar/BackgroundBar';
import QuestionLayout from '../../../components/QuestionLayout/QuestionLayout';
import QuestionContext, { QuestionGuard } from '../../../context/QuestionContext/QuestionContext';
import SixInARowContext from '../../../context/SixInARow/SixInARowContext';
import FeedbackOverlay from './Feedback/Overlay';
import CountdownTimer from './CountdownTimer/CountdownTimer';
import QuitButton from './QuitButton/QuitButton';

import Question from './Question';

const SixInARowPage = () => (
  <QuestionContext additionalContextData={{ isSixInARowMode: true }}>
    <SixInARowContext>
      <CountdownTimer />
      <BackgroundBar />
      <FeedbackOverlay />
      <QuestionLayout>
        <QuestionGuard>
          <Question />
        </QuestionGuard>
        <QuitButton />
      </QuestionLayout>
    </SixInARowContext>
  </QuestionContext>
);

export default SixInARowPage;
