import React, { useCallback, useEffect, useState } from 'react';
import { Zoom, IconButton, Paper, Typography } from '@material-ui/core';
import { Close as CloseIcon, InfoOutlined as InfoOutlinedIcon } from '@material-ui/icons';
import strings, { useManualLanguageRerender } from '../../i18n/localization';
import useStyles from './DemoInfoBox.styles';

const DemoInfoBox = () => {
  useManualLanguageRerender();

  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const closeInfoBox = useCallback(() => {
    setIsOpen(false);
  }, []);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  return (
    <Zoom in={isOpen}>
      <Paper role="alert" square elevation={0} className={classes.root}>
        <div className={classes.icon}>
          <InfoOutlinedIcon fontSize="inherit" />
        </div>

        <div className={classes.message}>
          <Typography gutterBottom component="div" className={classes.title}>
            {strings.demoInfoBox.title}
          </Typography>
          {strings.demoInfoBox.content}
        </div>
        <div className={classes.action}>
          <IconButton size="small" aria-label="Close" title="Close" color="inherit" onClick={closeInfoBox}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      </Paper>
    </Zoom>
  );
};

export default DemoInfoBox;
