import React from 'react';
import { TextField } from '@material-ui/core';

export const RegistrationInputField = ({
  fieldName,
  error,
  helperText,
  label,
  placeholder,
  defaultValue,
  disabled,
  onChange,
  autoFocus,
}) => (
  <TextField
    InputLabelProps={{
      style: { color: '#5C56CE' },
    }}
    name={fieldName}
    required
    fullWidth
    error={error}
    helperText={helperText}
    label={label}
    variant="outlined"
    margin="normal"
    placeholder={placeholder}
    defaultValue={defaultValue}
    disabled={disabled}
    onChange={onChange}
    autoFocus={autoFocus}
  />
);
