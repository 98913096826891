import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  closeIcon: {
    fontSize: '2.5em',
    color: theme.palette.grey.c500,
    padding: 0,
    marginTop: theme.spacing(3),
  },
  toolbar: {
    position: 'absolute',
    right: 0,
    flexDirection: 'row-reverse',
  },
  container: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
  button: {
    clipPath: 'circle(48%)',
    padding: `${theme.spacing(3)}px !important`,
  },
}));

export default useStyles;
