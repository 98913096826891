import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: theme.spacing(5),
    textAlign: 'center',
  },
}));

export default useStyles;
