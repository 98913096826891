import { makeStyles } from '@material-ui/styles';

const transition = '0.3s ease';

const useStyles = makeStyles(theme => ({
  button: {
    width: '100%',
    borderRadius: 0,
    padding: 0,
  },
  footerPadding: {
    width: '100%',
    transition,
    backgroundColor: props => props.color,
  },
  icon: {
    margin: '0 auto',
    fill: props => props.color,
    transition,
  },
  iconContainer: {
    margin: '0 -1px',
  },
  text: {
    position: 'absolute',
    bottom: 6,
  },
  content: {
    height: theme.height.questionFooter,
    width: '100%',
    display: 'flex',
  },
}));

export default useStyles;
