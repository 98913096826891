import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  progress: {
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  colorGrey: {
    color: theme.palette.grey.c300,
  },
  time: {
    fontSize: theme.spacing(6),
    fontWeight: 'bold',
  },
  iconButton: {
    padding: 6,
    color: theme.palette.primary.main,
    fontSize: theme.spacing(7),
  },
}));

export default useStyles;
