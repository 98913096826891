import { createMuiTheme } from '@material-ui/core';

import defaultTheme from './defaultTheme';
import tenantBoardTheme from './tenantBoardTheme';
import muiTableOverrides from './muiTableOverrides';
import typography from './typography';
import muiCssBaseline from './muiCssBaseline';

const theme = {
  default: createMuiTheme({ ...defaultTheme, ...typography, overrides: { ...muiCssBaseline, ...muiTableOverrides } }),
  tenant_board: createMuiTheme({
    ...tenantBoardTheme,
    ...typography,
    overrides: { ...muiCssBaseline, ...muiTableOverrides },
  }),
};

export default theme;
