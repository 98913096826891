import React, { useCallback, useState, useEffect } from 'react';
import { produce } from 'immer';
import createContext from '../createContext';
import createGuard from '../../service/createGuard';
import createAction from '../../service/createAction';
import Loader from '../../components/Loader/Loader';
import AuthStateInitializer from './AuthStateInitializer/AuthStateInitializer';

const initialContext = {
  data: {},
  pending: true,
  error: null,
};

const ID_TOKEN_LOADED = 'ID_TOKEN_LOADED';
const USER_MANAGER_LOADED = 'USER_MANAGER_LOADED';

const tokenLoadedAction = createAction(ID_TOKEN_LOADED);
const userManagerLoadedAction = createAction(USER_MANAGER_LOADED);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case ID_TOKEN_LOADED:
      draft.data.idToken = action.payload;
      draft.pending = false;
      break;
    case USER_MANAGER_LOADED:
      draft.data.userManager = action.payload;
      break;
    default:
      break;
  }
});

const [AuthenticationContextProvider, useAuthenticationContext] = createContext(reducer, initialContext);
const AuthenticationGuard = createGuard(useAuthenticationContext);

const useIdToken = () => {
  const {
    data: { idToken, userManager },
    dispatch,
  } = useAuthenticationContext();

  const setIdToken = useCallback(token => dispatch(tokenLoadedAction(token)), [dispatch]);

  const signOut = async () => {
    window.sessionStorage.clear();
    await userManager.signoutRedirect();
  };

  return {
    userManager,
    idToken,
    setIdToken,
    signOut,
  };
};

const useSetUserManager = () => {
  const { dispatch } = useAuthenticationContext();
  const setUserManager = useCallback(manager => dispatch(userManagerLoadedAction(manager)), [dispatch]);
  return setUserManager;
};

const AuthenticationContext = ({ children, isMocked = false }) => {
  const [hasToMock, setHasToMock] = useState(isMocked || process.env.REACT_APP_USE_AXIOS_MOCKS === 'true');
  useEffect(() => {
    if (hasToMock) {
      // We need this only for testing. Using dynamic imports we make sure to not load the code in production
      import('../../mocks/axios-mocks').then(({ initializeAxiosMocks }) => {
        initializeAxiosMocks();
        setHasToMock(false);
      });
    }
  }, [hasToMock]);

  return (
    <>
      {hasToMock ? <Loader /> : null}
      {!hasToMock && isMocked ? <AuthenticationContextProvider>{children}</AuthenticationContextProvider> : null}
      {!hasToMock && !isMocked ? (
        <AuthenticationContextProvider>
          <AuthStateInitializer useIdToken={useIdToken} useSetUserManager={useSetUserManager}>
            {children}
          </AuthStateInitializer>
        </AuthenticationContextProvider>
      ) : null}
    </>
  );
};

export default AuthenticationContext;
export { useIdToken, useAuthenticationContext, AuthenticationGuard };
