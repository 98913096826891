import capitalize from 'lodash/capitalize';
import { useAdminQuestion } from '../AdminQuestionContext';

const useAdminQuestionAsPreview = language => {
  const { question } = useAdminQuestion();
  const languageCase = capitalize(language);
  const correctAnswerIds = question.answers
    .map((answer, idx) => ({ ...answer, id: idx }))
    .filter(answer => answer.isCorrect)
    .map(answer => answer.id);
  return {
    question: {
      data: {
        questionId: question.questionId,
        questionText: question[`questionText${languageCase}`],
        answers: question.answers.map((answer, idx) => ({
          id: idx,
          text: answer[`text${languageCase}`],
          isChecked: true,
        })),
        difficulty: question.difficulty,
        category: question.category.categoryId,
      },
    },
    submission: {
      data: {
        explanation: question[`explanation${languageCase}`],
        idCorrectAnswers: correctAnswerIds,
      },
    },
    state: {
      isFlipped: false,
      givenAnswers: [],
    },
  };
};

export default useAdminQuestionAsPreview;
