/**
 * ensures multiple requests will only be executed once.
 *
 * @returns Promise
 */
const promiseCoordinator = () => {
  let promise = null;
  return callback => {
    if (promise === null) {
      promise = callback();
    }
    return promise;
  };
};

export default promiseCoordinator;
