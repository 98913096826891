import React, { useState } from 'react';
import { Divider } from '@material-ui/core';
import useStyles from './ImportExport.styles';
import Widget from '../../../components/Widget/Widget';
import strings from '../../../i18n/localization';
import UploadMessages from '../UploadMessages';
import UploadCsv from '../CsvWidget/UploadCsv/UploadCsv';
import DownloadCsv from '../CsvWidget/DownloadCsv/DownloadCsv';

const ImportExportWidget = () => {
  const classes = useStyles();

  const [feedback, setFeedback] = useState({});

  return (
    <Widget title={strings.admin.importExport}>
      <br />
      <UploadCsv onResponse={setFeedback} />
      <Divider className={classes.divider} />

      <UploadMessages response={feedback} />
      <Divider className={classes.divider} />

      <DownloadCsv />
    </Widget>
  );
};

export default ImportExportWidget;
