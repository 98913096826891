import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  textNode: {
    fill: theme.palette.primary.c900,
    fontSize: theme.typography.link.fontSize,
    fontWeight: theme.typography.link.fontWeight,
  },
}));

export default useStyles;
