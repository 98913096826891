import { produce } from 'immer';
import createContext from '../createContext';
import promiseCoordinator from '../../service/promiseCoordinator';
import { ENDPOINTS } from '../../hooks/api/constants';
import useFetch from '../../service/useFetch';
import createGuard from '../../service/createGuard';
import useDataLoad from '../../service/useDataLoad';
import createAction from '../../service/createAction';
import { fail, succeed } from '../../service/reducer';
import { useUser } from '../UserContext/UserContext';

const QUESTION_LIST_LOADED = 'QUESTION_LIST_LOADED';
const QUESTION_LIST_FAILED = 'QUESTION_LIST_FAILED';
const QUESTION_LIST_PENDING = 'QUESTION_LIST_PENDING';

const questionLoadedAction = createAction(QUESTION_LIST_LOADED);
const questionFailed = createAction(QUESTION_LIST_FAILED);
const questionPending = createAction(QUESTION_LIST_PENDING);

const reducer = produce((draft, action) => {
  switch (action.type) {
    case QUESTION_LIST_LOADED:
      return succeed(action.payload);
    case QUESTION_LIST_FAILED:
      return fail(action.payload);
    case QUESTION_LIST_PENDING:
      draft.pending = true;
      break;
    default:
      break;
  }
});

const [QuestionListContext, useQuestionListContext] = createContext(reducer);

const questionCoordinator = promiseCoordinator();
const QuestionListGuard = createGuard(useQuestionListContext);

const useQuestionList = () => {
  const { language } = useUser();
  const { data: questions, dispatch } = useQuestionListContext();

  const fetch = useFetch(questionCoordinator);

  const loadQuestions = useDataLoad(
    fetch,
    {
      url: ENDPOINTS.ADMIN_QUESTIONS,
      headers: {
        language,
      },
    },
    dispatch,
    questionLoadedAction,
    questionFailed,
    questionPending,
  );

  return {
    questions,
    loadQuestions,
  };
};

export default QuestionListContext;
export { useQuestionList, QuestionListGuard };
