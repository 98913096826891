import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  questionContainer: {
    paddingBottom: theme.spacing(5),
  },
  wrongAnswer: {
    color: theme.palette.error.main,
    fontWeight: 700,
  },
  rightAnswer: {
    color: theme.palette.success.main,
    fontWeight: 700,
  },
}));

export default useStyles;
