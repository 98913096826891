import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(7, 4, 2, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    color: '#fff',
  },
  dialog: {
    bottom: 60,
  },
  description: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
    paddingTop: theme.spacing(4),
    textAlign: 'center',
  },
  spacer: {
    flexGrow: 2,
  },
  buttonContainer: {
    maxWidth: theme.spacing(8) * 5,
    margin: '0 auto',
    marginBottom: theme.spacing(7),
  },
  button: {
    width: '100%',
  },
}));

export default useStyles;
