import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  link: {
    padding: theme.spacing(4, 6),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'left',
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey.c100,
    },
  },
  leftMostIcon: {},
  middleIcon: {
    padding: theme.spacing(0, 4, 0, 4),
    '& svg': {
      width: 16,
    },
  },
}));

export default useStyles;
