import React from 'react';
import { BarChart, Bar, XAxis, Cell, ResponsiveContainer } from 'recharts';
import useStyles from './TabPanelScore.styles';
import Typography from '../../../components/Typography/Typography';
import Button from '../../../components/Button/Button';
import ArrowIcon from '../../../components/ArrowIcon/ArrowIcon';
import VerticalAxisTick from '../VerticalAxisTick/VerticalAxisTick';
import CardinalNumberTick from '../CardinalNumberTick/CardinalNumberTick';
import { useLabeledScores, useLoadedScores } from '../../../context/selectors/scores';
import strings from '../../../i18n/localization';
import { useCategoryColors } from '../../../context/selectors/categories';
import { ScoreGuard } from '../../../context/ScoreContext/ScoreContext';

const TabPanelScore = ({ height, onSwitchTabClick }) => {
  const classes = useStyles();
  const labeledScores = useLabeledScores();
  const colors = useCategoryColors();
  const scores = useLoadedScores();

  return (
    <>
      <Typography variant="h2" color="primary" data-cy="title-mixed-score">
        {strings.score.yourMixedScore}
      </Typography>
      <Button
        variant="link"
        onClick={onSwitchTabClick}
        iconLeft={<ArrowIcon variant="left" className={classes.arrowPaddingRight} />}
      >
        {strings.score.goToYourRanking}
      </Button>
      <ScoreGuard>
        <ResponsiveContainer height={height} className={classes.chartsContainer}>
          <BarChart data={labeledScores}>
            <XAxis dataKey="value" axisLine={false} tickLine={false} tick={<CardinalNumberTick />} />
            <XAxis
              dataKey="catLabel"
              xAxisId="category"
              axisLine={false}
              tickLine={false}
              tick={<VerticalAxisTick />}
              height={150}
              interval={0}
            />
            <Bar dataKey="value" className={classes.bar} barSize={30}>
              {scores.map(entry => (
                <Cell key={entry.catId} fill={colors[entry.catId] || colors.default} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </ScoreGuard>
    </>
  );
};

export default TabPanelScore;
