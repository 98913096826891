import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  centralText: {
    textAlign: 'center',
  },
  score: {
    color: theme.palette.text.primary,
    marginTop: -theme.spacing(2),
  },
  message: {
    color: theme.palette.text.primaryLight,
    fontStyle: 'italic',
    paddingTop: theme.spacing(3),
  },
}));

export default useStyles;
