import { ENDPOINTS, POST } from '../constants';
import useAxiosNoAuth from '../../useAxiosNoAuth';
import promiseCoordinator from '../../../service/promiseCoordinator';

const coordinator = promiseCoordinator();

const useSelfRegistration = () => {
  const config = {
    method: POST,
    url: ENDPOINTS.SELF_REGISTRATION,
  };

  const [{ data, pending, error }, submit] = useAxiosNoAuth(coordinator, config);

  return { data, pending, error, submit };
};

export default useSelfRegistration;
