import React from 'react';

import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteForever';

import Typography from '../../Typography/Typography';
import strings from '../../../i18n/localization';
import TextArea from '../../FormElements/TextArea/TextArea';
import useStyles from './Answer.styles';

const Answer = ({
  answer,
  language,
  isActiveLanguage,
  errors,
  register,
  control,
  getValues,
  remove,
  textFieldValidation,
}) => {
  const classes = useStyles();
  return (
    <>
      <Typography extraBold variant="h5">
        {strings.admin.questionDetail.answer} {answer.artificialId + 1}
      </Typography>

      <TextArea
        name={`answers[${answer.artificialId}].text${language}`}
        ref={register({
          ...textFieldValidation,
        })}
        placeholder={strings.questionFeedback.descriptionPlaceholder}
        error={errors?.answers?.[answer.artificialId]?.[`text${language}`]}
        data-cy={`answer${language}-${answer.artificialId}`}
      />

      <div className={classnames(classes.spacing, classes.answerOptions)}>
        <div>
          {isActiveLanguage && (
            <Controller
              as={
                <Checkbox
                  color="secondary"
                  classes={{
                    root: classes.checkbox,
                    checked: classes.checkboxPositive,
                  }}
                  data-cy={`checkbox-${answer.artificialId}`}
                />
              }
              onChange={([, checked]) => checked}
              control={control}
              name={`answers[${answer.artificialId}].isCorrect`}
              defaultValue={getValues({ nest: true }).answers?.[answer.artificialId]?.isCorrect || false}
            />
          )}
          <Typography component="span" bold>
            {strings.admin.questionDetail.correctAnswer}
          </Typography>
        </div>
        <DeleteIcon onClick={() => remove(answer.artificialId)} />
      </div>
    </>
  );
};

export default Answer;
