import React from 'react';
import classnames from 'classnames';
import { SHOW_ANONYMOUS_RANKING } from '../../../../../constants';
import Typography from '../../../../../components/Typography/Typography';
import Avatar from '../../../../../components/Avatar/Avatar';
import useStyles from './RankingTableRow.styles';

const RankingTableRow = ({ rank, highlighted }) => {
  const classes = useStyles();

  return (
    <div className={classnames({ [classes.highlightShadow]: highlighted }, classes.hideOverflow)}>
      <div className={classes.tableRow}>
        <Typography
          component="div"
          bold
          className={classnames(
            { [classes.highlightFontSize]: highlighted },
            { [classes.highlightColor]: highlighted },
            classes.tableCell,
          )}
        >
          {rank.rank}
        </Typography>
        <div className={classes.tableCell}>
          <p className={classes.avatarWrapper}>
            <Avatar name={rank.avatar} size="M" />
          </p>
        </div>
        <Typography
          component="div"
          color="textPrimaryLight"
          uppercase
          className={classnames(
            classes.nameCell,
            classes.tableCell,
            { [classes.highlightColor]: highlighted },
            { [classes.highlightColor]: highlighted },
          )}
        >
          {SHOW_ANONYMOUS_RANKING ? (
            <>{rank.nickName}</>
          ) : (
            <>
              {rank.firstName}
              <br />
              {rank.lastName}
            </>
          )}
        </Typography>
        <Typography
          component="div"
          align="center"
          bold
          className={classnames(
            classes.tableCell,
            { [classes.highlightFontSize]: highlighted },
            { [classes.highlightColor]: highlighted },
          )}
        >
          {rank.score}
        </Typography>
      </div>
    </div>
  );
};

export default RankingTableRow;
