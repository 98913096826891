import React, { useEffect } from 'react';
import Typography from '../../../../Typography/Typography';
import strings, { languageChangeNotifier } from '../../../../../i18n/localization';
import useStyles from './LanguagePicker.styles';
import { AVAILABLE_LANGUAGES } from '../../../../../constants';
import { useLanguage, useUser } from '../../../../../context/UserContext/UserContext';

const LanguagePicker = ({ offlineOnly = false }) => {
  const classes = useStyles();

  const { user } = useUser();
  const currentLanguage = user?.language || strings.getLanguage();
  const { changeLanguage } = useLanguage();

  const handleClick = newLanguage => {
    strings.setLanguage(newLanguage);
    // only used due to localization library issue, see localization.js for more details
    languageChangeNotifier.trigger();
    if (!offlineOnly) {
      changeLanguage(newLanguage);
    }
  };

  useEffect(() => {
    if (currentLanguage) {
      strings.setLanguage(currentLanguage);
      // only used due to localization library issue, see localization.js for more details
      languageChangeNotifier.trigger();
    }
  }, [currentLanguage]);

  return (
    <div className={classes.languagePicker}>
      {AVAILABLE_LANGUAGES.map(language =>
        currentLanguage === language ? (
          <Typography key={language} extraBold uppercase className={classes.selectedLanguage}>
            {language}
          </Typography>
        ) : (
          <button
            type="button"
            key={language}
            onClick={() => handleClick(language)}
            onKeyDown={() => handleClick(language)}
            className={classes.unselectedLanguage}
          >
            <Typography uppercase>{language}</Typography>
          </button>
        ),
      )}
    </div>
  );
};

export default LanguagePicker;
