import React, { useEffect, useState } from 'react';
import FullScreenDialog from '../FullScreenDialog/FullScreenDialog';
import strings from '../../i18n/localization';
import useStyles from './AdminFeedbackDialog.styles';
import FeedbackItem from './FeedbackItem/FeedbackItem';
import {
  FeedbackListGuard,
  useFeedbackList,
  useFeedbackResolve,
} from '../../context/AdminFeedbackListContext/AdminFeedbackListContext';

const AdminFeedbackDialog = ({ isOpen }) => {
  const classes = useStyles();
  const { feedbackList } = useFeedbackList();
  const { pending, resolveFeedback } = useFeedbackResolve();

  const [activeUserDetailDialog, setActiveUserDetailDialog] = useState(null);
  const closeActiveUserDetailDialog = () => {
    setActiveUserDetailDialog(null);
  };

  // close nested overlay when dialog is closed
  useEffect(() => {
    if (!isOpen) {
      closeActiveUserDetailDialog();
    }
  }, [isOpen]);
  const handleFeedbackClick = feedbackId => {
    resolveFeedback(feedbackId);
  };

  return (
    <ul className={classes.feedbackList}>
      {feedbackList.map(feedback => {
        const isCurrentlyActiveUserDetailOverlayElement =
          activeUserDetailDialog !== null && activeUserDetailDialog.id === feedback.id;
        return (
          <FeedbackItem
            key={feedback.id}
            feedback={feedback}
            showTooltip={isCurrentlyActiveUserDetailOverlayElement}
            closeActiveUserDetailDialog={closeActiveUserDetailDialog}
            setActiveUserDetailDialog={setActiveUserDetailDialog}
            handleFeedbackClick={handleFeedbackClick}
            disabled={pending}
          />
        );
      })}
    </ul>
  );
};

const AdminFeedbackDialogFetcher = ({ isOpen, close, questionId }) => {
  const { loadFeedbackList } = useFeedbackList(questionId);
  useEffect(() => {
    if (isOpen) {
      loadFeedbackList();
    }
  }, [isOpen, loadFeedbackList]);
  return (
    <FullScreenDialog
      open={isOpen}
      onCloseButton={close}
      title={`#${questionId} - ${strings.admin.feedbackDialog.titleSuffix}`}
    >
      <FeedbackListGuard>
        <AdminFeedbackDialog isOpen={isOpen} />
      </FeedbackListGuard>
    </FullScreenDialog>
  );
};

export default AdminFeedbackDialogFetcher;
