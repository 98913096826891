import React, { useState } from 'react';
import ReviewLayoutDialog from '../ReviewLayoutDialog/ReviewLayoutDialog';
import Button from '../../../components/Button/Button';
import EyeIcon from '../EyeIcon/EyeIcon';
import usePopupControl, { popupKeys } from '../../../hooks/popup/usePopupControl';
import useStyles from './PreviewDialogButton.styles';
import QuestionContext, { FakeQuestionContext } from '../../../context/QuestionContext/QuestionContext';
import useAdminQuestionAsPreview from '../../../context/AdminQuestionContext/useAdminQuestionAsPreview/useAdminQuestionAsPreview';

const PreviewDialogButton = ({ language }) => {
  const classes = useStyles();
  const [questionPreviewState, setQuestionPreviewState] = useState(null);
  const { popupIsOpen, openPopup, closePopup } = usePopupControl(popupKeys.ADMIN_PREVIEW);
  const previewQuestionObject = useAdminQuestionAsPreview(language);
  const renderPreview = () => {
    setQuestionPreviewState(previewQuestionObject);
    openPopup();
  };

  const flipCard = () => {
    setQuestionPreviewState({
      ...questionPreviewState,
      state: {
        ...questionPreviewState.state,
        isFlipped: !questionPreviewState.state.isFlipped,
      },
    });
  };
  return (
    <>
      <QuestionContext>
        <FakeQuestionContext state={previewQuestionObject}>
          <ReviewLayoutDialog
            open={popupIsOpen}
            onCloseButton={closePopup}
            question={questionPreviewState}
            flipCard={flipCard}
          />
        </FakeQuestionContext>
      </QuestionContext>
      <Button onClick={renderPreview} color="inherit" className={classes.eyeIcon}>
        <EyeIcon />
      </Button>
    </>
  );
};

export default PreviewDialogButton;
