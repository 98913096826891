import React from 'react';
import { Dialog, IconButton, Slide } from '@material-ui/core';
import useStyles from './FullScreenDialog.styles';
import PageTitle from '../PageTitle/PageTitle';
import CloseIcon from './CloseIcon/CloseIcon';

const Transition = React.forwardRef((props, ref) => <Slide direction="down" ref={ref} {...props} />);

const FullScreenDialog = ({ open = true, onCloseButton, title, subTitle, children, primary, ...rest }) => {
  const classes = useStyles();

  const handleClose = () => {
    if (onCloseButton) {
      onCloseButton();
    }
  };

  return (
    <Dialog
      fullScreen
      open={open}
      TransitionComponent={Transition}
      className={classes.root}
      classes={{ paper: primary ? classes.primaryPaper : undefined }}
      {...rest}
    >
      <div className={classes.titlePadding}>
        <PageTitle title={title} subTitle={subTitle} />
        {onCloseButton ? (
          <IconButton onClick={handleClose} aria-label="close" data-cy="close" className={classes.closeIcon}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </div>
      {children}
    </Dialog>
  );
};

export default FullScreenDialog;
