import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  tableHead: {
    boxShadow: theme.customShadows.lightBottom,
    marginTop: theme.spacing(5),
  },
  headerPadding: {
    padding: theme.spacing(4, 5),
  },
  tableBody: {
    flexGrow: 1,
    overflowY: 'hidden',
  },
  tableRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 3),
  },
  filterBox: {
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(4, 7),
    [theme.breakpoints.down(theme.breakpoints.values.xs)]: {
      padding: theme.spacing(4, 5),
    },
  },
}));

export default useStyles;
