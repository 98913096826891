export const SHOW_BANNER = window.securedConfig?.SHOW_BANNER || false;
export const IS_DEMO = window.securedConfig?.IS_DEMO || false;
export const DISABLE_INVITATION = window.securedConfig?.DISABLE_INVITATION || false;
export const SHOW_ANONYMOUS_RANKING = window.securedConfig?.SHOW_ANONYMOUS_RANKING || false;
export const CONTACT_ADDRESS = window.securedConfig?.CONTACT_ADDRESS;
export const INSTANCE_TYPE = window.securedConfig?.INSTANCE_TYPE;
export const ENABLE_LOGOUT = window.securedConfig?.ENABLE_LOGOUT || false;
export const ENABLE_CYARP_SYNC = window.securedConfig?.CYARP_INVITE_API_URL || false;
export const AUTH_SETTINGS = {
  CLIENT_ID: window.securedConfig?.AUTH?.CLIENT_ID,
  SCOPE: window.securedConfig?.AUTH?.SCOPE,
  AUTHORITY: window.securedConfig?.AUTH?.AUTHORITY,
  IS_AAD: window.securedConfig?.AUTH?.IS_AAD,
};

const ROLES = {
  TENANT_ADMIN: 'TENANT_ADMIN',
  TENANT_MANAGER: 'TENANT_MANAGER',
  QUIZEE: 'QUIZEE',
  SECURED_ADMIN: 'SECURED_ADMIN',
};

const AVAILABLE_LANGUAGES = ['en', 'de', 'fr', 'it'];
const AVAILABLE_CATEGORIES = ['dat', 'web', 'mob', 'ema', 'pwd', 'inc'];
const RANDOM_CATEGORY = 'rnd';
const PLAY_SIX_IN_ROW = 'six';

export { ROLES, AVAILABLE_LANGUAGES, AVAILABLE_CATEGORIES, RANDOM_CATEGORY, PLAY_SIX_IN_ROW };
