import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  numberCircle: {
    backgroundColor: theme.palette.error.light,
    width: 18,
    height: 18,
    borderRadius: 9,
    textAlign: 'center',
    position: 'absolute',
    bottom: 2,
    right: -6,
  },
  number: {
    fontSize: 14,
  },
  iconContainer: {
    transform: 'scale(0.8)',
  },
}));

export default useStyles;
